import Vue from "vue";
import VueRouter from "vue-router";
import Home from "../components/Home.vue";
import Login from "../components/Login.vue";
import VueSimpleAlert from "vue-simple-alert";

Vue.use(VueRouter);

export default new VueRouter({
  mode: "history",
  routes: [
    {
      path: "/login",
      name: "Login",
      component: Login,
      meta: {
        auth: false,
        iscommontoshow: true,
        isnoadmintoshow: true,
      },
    },
    {
      path: "/forgotpassword",
      name: "ForgotPassword Tenant",
      meta: {
        auth: false,
        isnoadmintoshow: true,
      },
      component: () => import("../components/forgotpassword.vue"),
    },
    {
      path: "/forgotpassword_admin",
      name: "ForgotPassword",
      meta: {
        auth: false,
        isnoadmintoshow: true,
      },
      component: () => import("../components/forgotpassword_admin.vue"),
    },
    {
      path: "/forgotpassword_profile",
      name: "ForgotPasswordProfile",
      meta: {
        auth: true,
        isnoadmintoshow: true,
      },
      component: () => import("../components/forgotpassword.vue"),
    },
    {
      path: "/",
      name: "Home",
      component: Home,
      meta: {
        auth: true,
      },
      children: [
        {
          path: "/library",
          name: "Library",
          meta: {
            auth: true,
          },
          component: () => import("../views/library.vue"),
        },
        {
          path: "/template_process",
          name: "TemplateProcess",
          meta: {
            auth: true,
          },
          component: () =>
            import("../views/Template/Checklists/checklist_process.vue"),
        },
        {
          path: "/template_responsibility",
          name: "Responsibility",
          meta: {
            auth: true,
          },
          component: () =>
            import("../views/Template/Checklists/checklist_responsibility.vue"),
        },
        {
          path: "/template_casebased",
          name: "Case",
          meta: {
            auth: true,
          },
          component: () =>
            import("../views/Template/Checklists/checklist_casebased.vue"),
        },
        {
          path: "/task_template/:id/tasks",
          name: "task_view",
          meta: {
            auth: true,
          },
          component: () => import("../views/Template/Tasks/task.vue"),
        },
        {
          path: "/subtask_template/:id/subtasks",
          name: "subtask_view",
          meta: {
            auth: true,
          },
          component: () => import("../views/Template/Subtasks/subtasks.vue"),
        },
        {
          path: "/checklist_template/add",
          name: "checklist_template_add",
          meta: {
            auth: true,
          },
          component: () =>
            import("../views/Template/Checklists/checklist_add.vue"),
        },
        {
          path: "/task_template/add",
          name: "task_template_add",
          meta: {
            auth: true,
          },
          component: () =>
            import("../views/Template/Tasks/task_template_add.vue"),
        },
        {
          path: "/subtask_template/add",
          name: "subtask_template_add",
          meta: {
            auth: true,
          },
          component: () =>
            import("../views/Template/Subtasks/subtask_template_add.vue"),
        },
        {
          path: "/checklist_template/edit/:id",
          name: "checklist_template_edit",
          meta: {
            auth: true,
          },
          component: () =>
            import("../views/Template/Checklists/checklist_edit.vue"),
        },
        {
          path: "/task_template/edit/:taskid",
          name: "task_template_edit",
          meta: {
            auth: true,
          },
          component: () => import("../views/Template/Tasks/tasks_edit.vue"),
        },
        {
          path: "/subtask_template/edit/:subtaskid",
          name: "subtask_template_edit",
          meta: {
            auth: true,
          },
          component: () =>
            import("../views/Template/Subtasks/subtasks_edit.vue"),
        },
        {
          path: "/adoptchecklist/:id",
          name: "adopt_chk",
          meta: {
            auth: true,
          },
          component: () => import("../views/Template/adopt_view.vue"),
        },
        {
          path: "/team_list",
          name: "Team_list",
          meta: {
            auth: true,
            // iscommontoshow: true,
            isnoadmintoshow: true,
          },
          component: () => import("../views/team_list.vue"),
        },
        {
          path: "/quick_links",
          name: "ACT",
          meta: {
            auth: true,
            // iscommontoshow: true,
            isnoadmintoshow: true,
          },
          component: () => import("../views/quick_links.vue"),
        },
        {
          path: "/score_list",
          name: "Score_list",
          meta: {
            auth: true,
            // iscommontoshow: true,
            isnoadmintoshow: true,
          },
          component: () => import("../views/score_list.vue"),
        },
        // Workings
        {
          path: "/branch",
          name: "branch",
          meta: {
            auth: true,
            screen_code: "CREATE_MASTERS",
            page_code: "SCN_BRANCH",
            page_type: "landing",
          },
          component: () => import("../views/masters/Dashboard.vue"),
        },
        {
          path: "/objective",
          name: "Objective",
          meta: {
            auth: true,
            screen_code: "CREATE_MASTERS",
            //isnoadmintoshow: true,
            page_code: "SCN_OBJECTIVE",
            page_type: "landing",
          },
          component: () => import("../views/masters/objective.vue"),
        },
        {
          path: "/objective/add",
          name: "Objective_Add",
          meta: {
            auth: true,
            screen_code: "CREATE_MASTERS",
            page_code: "SCN_OBJECTIVE",
            page_type: "add_screen",
          },
          component: () => import("../views/masters/objective_add.vue"),
        },
        {
          path: "/objective/edit/:id",
          name: "objective_edit",
          meta: {
            auth: true,
            screen_code: "CREATE_MASTERS",
            page_code: "SCN_OBJECTIVE",
            page_type: "edit_screen",
          },
          component: () => import("../views/masters/objective_edit.vue"),
        },
        {
          path: "/strategy",
          name: "Strategy",
          meta: {
            auth: true,
            screen_code: "CREATE_MASTERS",
            //isnoadmintoshow: true,
            page_code: "SCN_MST_STRATEGY_GROUP",
            page_type: "landing",
          },
          component: () => import("../views/masters/strategy_group.vue"),
        },
        {
          path: "/strategy/add",
          name: "strategy",
          meta: {
            auth: true,
            screen_code: "CREATE_MASTERS",
            page_code: "SCN_MST_STRATEGY_GROUP",
            page_type: "add_screen",
          },
          component: () => import("../views/masters/strategy_add.vue"),
        },
        {
          path: "/strategy/edit/:id",
          name: "strategy_edit",
          meta: {
            auth: true,
            screen_code: "CREATE_MASTERS",
            page_code: "SCN_MST_STRATEGY_GROUP",
            page_type: "edit_screen",
          },
          component: () => import("../views/masters/strategy_edit.vue"),
        },
        {
          path: "/business_plan",
          name: "business_plan",
          meta: {
            auth: true,
            screen_code: "SCN_PLANNING",
            //isnoadmintoshow: true,
            page_code: "SCN_PLANNING",
            page_type: "landing",
          },
          component: () => import("../views/masters/buisness_plan.vue"),
        },
        {
          path: "/set_strategy",
          name: "set_strategy",
          meta: {
            auth: true,
            screen_code: "SCN_STRATEGY",
            //isnoadmintoshow: true,
            page_code: "SCN_STRATEGY",
            page_type: "landing",
          },
          component: () => import("../views/masters/set_strategy.vue"),
        },
        {
          path: "/business_report",
          name: "business_plan_report",
          meta: {
            auth: true,
            screen_code: "SCN_BUSINESS_REPORT",
            //isnoadmintoshow: true,
            page_code: "SCN_BUSINESS_REPORT",
            page_type: "landing",
          },
          component: () => import("../views/masters/business_plan_report.vue"),
        },
        {
          path: "/visittype",
          name: "Visittype",
          meta: {
            auth: true,
            screen_code: "SCN_VISIT_TYPE",
            page_code: "SCN_VISIT_TYPE",
            page_type: "landing",
          },
          component: () => import("../views/masters/visittype.vue"),
        },

        {
          path: "/PurposeOfMeeting",
          name: "PurposeOfMeeting",
          meta: {
            auth: true,
            screen_code: "SCN_VISIT_POM",
            page_code: "SCN_VISIT_POM",
            page_type: "landing",
          },
          component: () => import("../views/masters/PurposeOfMeeting.vue"),
        },

        {
          path: "/department",
          name: "Department",
          meta: {
            auth: true,
            screen_code: "CREATE_MASTERS",
            page_code: "SCN_DEPARTMENT",
            page_type: "landing",
          },
          component: () => import("../views/masters/Department.vue"),
        },
        {
          path: "/segment",
          name: "Segment",
          meta: {
            auth: true,
            screen_code: "CREATE_MASTERS",
            page_code: "SCN_DEPARTMENT",
            page_type: "landing",
          },
          component: () => import("../views/masters/Segment.vue"),
        },
        {
          path: "/subdepartment",
          name: "SubDepartment",
          meta: {
            auth: true,
            screen_code: "CREATE_MASTERS",
            page_code: "SCN_SUB_DEPARTMENT",
            page_type: "landing",
          },
          component: () => import("../views/masters/SubDepartment.vue"),
        },
        {
          path: "/addsubdepartment",
          name: "AddSubDepartment",
          meta: {
            auth: true,
            screen_code: "CREATE_MASTERS",
            page_code: "SCN_SUB_DEPARTMENT",
            page_type: "add_screen",
          },
          component: () => import("../views/masters/SubDepartment_add.vue"),
        },

        {
          path: "/subdepartment/edit/:id",
          name: "SubDepartment_edit",
          meta: {
            auth: true,
            screen_code: "CREATE_MASTERS",
            page_code: "SCN_SUB_DEPARTMENT",
            page_type: "edit_screen",
          },
          component: () => import("../views/masters/SubDepartment_edit.vue"),
        },

        {
          path: "/region",
          name: "Region",
          meta: {
            auth: true,
            screen_code: "CREATE_MASTERS",
            page_code: "SCN_REGION",
            page_type: "landing",
          },
          component: () => import("../views/masters/Region.vue"),
        },

        {
          path: "/region/add",
          name: "AddRegion",
          meta: {
            auth: true,
            screen_code: "CREATE_MASTERS",
            page_code: "SCN_REGION",
            page_type: "add_screen",
          },
          component: () => import("../views/masters/Region_add.vue"),
        },

        {
          path: "/region/edit/:id",
          name: "Region_edit",
          meta: {
            auth: true,
            screen_code: "CREATE_MASTERS",
            page_code: "SCN_REGION",
            page_type: "edit_screen",
          },
          component: () => import("../views/masters/Region_edit.vue"),
        },
        {
          path: "/channel",
          name: "channel",
          meta: {
            auth: true,
            screen_code: "CREATE_MASTERS",
            page_code: "SCN_CHANNEL",
            page_type: "edit_screen",
          },
          component: () => import("../views/masters/channel.vue"),
        },

        {
          path: "/channel/add",
          name: "channel_add",
          meta: {
            auth: true,
            screen_code: "CREATE_MASTERS",
            page_code: "SCN_CHANNEL",
            page_type: "add_screen",
          },
          component: () => import("../views/masters/channel_add.vue"),
        },
        {
          path: "/channel/edit/:id",
          name: "channel_edit",
          meta: {
            auth: true,
            screen_code: "CREATE_MASTERS",
            page_code: "SCN_CHANNEL",
            page_type: "edit_screen",
          },
          component: () => import("../views/masters/channel_edit.vue"),
        },
        {
          path: "/uom",
          name: "uom",
          meta: {
            auth: true,
            screen_code: "CREATE_MASTERS",
            page_code: "SCN_UOM",
            page_type: "landing",
          },
          component: () => import("../views/masters/uom.vue"),
        },

        {
          path: "/uom/add",
          name: "uom_add",
          meta: {
            auth: true,
            screen_code: "CREATE_MASTERS",
            page_code: "SCN_UOM",
            page_type: "add_screen",
          },
          component: () => import("../views/masters/uom_add.vue"),
        },
        {
          path: "/uom/edit/:id",
          name: "uom_edit",
          meta: {
            auth: true,
            screen_code: "CREATE_MASTERS",
            page_code: "SCN_UOM",
            page_type: "edit_screen",
          },
          component: () => import("../views/masters/uom_edit.vue"),
        },
        {
          path: "/function",
          name: "Function ",
          meta: {
            auth: true,
            screen_code: "CREATE_MASTERS",
            page_code: "SCN_DESIGNATION",
            page_type: "landing",
          },
          component: () => import("../views/masters/function.vue"),
        },
        {
          path: "/function/add",
          name: "Function Add",
          meta: {
            auth: true,
            screen_code: "CREATE_MASTERS",
            page_code: "SCN_DESIGNATION",
            page_type: "add_screen",
          },
          component: () => import("../views/masters/function_add.vue"),
        },
        {
          path: "/function/edit/:id",
          name: "Function Edit",
          meta: {
            auth: true,
            screen_code: "CREATE_MASTERS",
            page_code: "SCN_DESIGNATION",
            page_type: "edit_screen",
          },
          component: () => import("../views/masters/function_edit.vue"),
        },
        {
          path: "/skillsgroup",
          name: "skillsgroup ",
          meta: {
            auth: true,
            screen_code: "CREATE_MASTERS",
            page_code: "SCN_DESIGNATION",
            page_type: "landing",
          },
          component: () => import("../views/masters/skillsgroup.vue"),
        },
        {
          path: "/skillsgroup/add",
          name: "skillsgroup Add",
          meta: {
            auth: true,
            screen_code: "CREATE_MASTERS",
            page_code: "SCN_DESIGNATION",
            page_type: "add_screen",
          },
          component: () => import("../views/masters/skillsgroup_add.vue"),
        },
        {
          path: "/skillsgroup/edit/:id",
          name: "skillsgroup Edit",
          meta: {
            auth: true,
            screen_code: "CREATE_MASTERS",
            page_code: "SCN_DESIGNATION",
            page_type: "edit_screen",
          },
          component: () => import("../views/masters/skillsgroup_edit.vue"),
        },
        {
          path: "/competencies",
          name: "competencies ",
          meta: {
            auth: true,
            screen_code: "CREATE_MASTERS",
            page_code: "SCN_DESIGNATION",
            page_type: "landing",
          },
          component: () => import("../views/masters/competencies.vue"),
        },
        {
          path: "/competencies/add",
          name: "competencies Add",
          meta: {
            auth: true,
            screen_code: "CREATE_MASTERS",
            page_code: "SCN_DESIGNATION",
            page_type: "add_screen",
          },
          component: () => import("../views/masters/competencies__add.vue"),
        },
        {
          path: "/competencies/edit/:id",
          name: "competencies Edit",
          meta: {
            auth: true,
            screen_code: "CREATE_MASTERS",
            page_code: "SCN_DESIGNATION",
            page_type: "edit_screen",
          },
          component: () => import("../views/masters/competencies_edit.vue"),
        },
        {
          path: "/distinct_role_assignments",
          name: "Role Assignments Landing",
          meta: {
            auth: true,
            screen_code: "CREATE_MASTERS",
            page_code: "SCN_DESIGNATION",
            page_type: "landing",
          },
          component: () =>
            import("../views/masters/distinct_RoleAssignments.vue"),
        },
        {
          path: "/role_assignments",
          name: "Role Assignments",
          meta: {
            auth: true,
            screen_code: "CREATE_MASTERS",
            page_code: "SCN_DESIGNATION",
            page_type: "landing",
          },
          component: () => import("../views/masters/RoleAssignments.vue"),
        },

        {
          path: "/role_assignments/add",
          name: "Role Assignments_add",
          meta: {
            auth: true,
            screen_code: "CREATE_MASTERS",
            page_code: "SCN_DESIGNATION",
            page_type: "add_screen",
          },
          component: () => import("../views/masters/RoleAssignments_add.vue"),
        },
        {
          path: "/role_assignments/edit/:id",
          name: "Role Assignments_edit",
          meta: {
            auth: true,
            screen_code: "CREATE_MASTERS",
            page_code: "SCN_DESIGNATION",
            page_type: "edit_screen",
          },
          component: () => import("../views/masters/RoleAssignments_edit.vue"),
        },
        {
          path: "/skills",
          name: "Skills",
          meta: {
            auth: true,
            screen_code: "CREATE_MASTERS",
            page_code: "SCN_DESIGNATION",
            page_type: "landing",
          },
          component: () => import("../views/masters/Skills.vue"),
        },
        {
          path: "/skills_add",
          name: "Skills_add",
          meta: {
            auth: true,
            screen_code: "CREATE_MASTERS",
            page_code: "SCN_DESIGNATION",
            page_type: "add_screen",
          },
          component: () => import("../views/masters/skills_add.vue"),
        },
        {
          path: "/skills_edit/:id",
          name: "Skills edit",
          meta: {
            auth: true,
            screen_code: "CREATE_MASTERS",
            page_code: "SCN_DESIGNATION",
            page_type: "edit_screen",
          },
          component: () => import("../views/masters/skills_edit.vue"),
        },
        {
          path: "/competencies_add",
          name: "Competencies_add",
          meta: {
            auth: true,
            screen_code: "CREATE_MASTERS",
            page_code: "SCN_DESIGNATION",
            page_type: "add_screen",
          },
          component: () => import("../views/masters/competencies_add.vue"),
        },
        {
          path: "/Target_type",
          name: "Target_type",
          meta: {
            auth: true,
            screen_code: "CREATE_MASTERS",
            page_code: "SCN_TARGET_TYPE",
            page_type: "landing",
          },
          component: () => import("../views/masters/Target_type.vue"),
        },
        {
          path: "/Target_type/add",
          name: "Target_type_add",
          meta: {
            auth: true,
            screen_code: "CREATE_MASTERS",
            page_code: "SCN_TARGET_TYPE",
            page_type: "add_screen",
          },
          component: () => import("../views/masters/Target_type_add.vue"),
        },
        {
          path: "/Target_type/edit/:id",
          name: "Target_type_edit",
          meta: {
            auth: true,
            screen_code: "CREATE_MASTERS",
            page_code: "SCN_TARGET_TYPE",
            page_type: "edit_screen",
          },
          component: () => import("../views/masters/Target_type_edit.vue"),
        },
        {
          path: "/kpi_type",
          name: "kpi_type",
          meta: {
            auth: true,
            screen_code: "CREATE_MASTERS",
            page_code: "SCN_KPI_TYPE",
            page_type: "landing",
          },
          component: () => import("../views/masters/kpi_type.vue"),
        },

        {
          path: "/kpi_type/add",
          name: "kpi_type_add",
          meta: {
            auth: true,
            screen_code: "CREATE_MASTERS",
            page_code: "SCN_KPI_TYPE",
            page_type: "add_screen",
          },
          component: () => import("../views/masters/kpi_type_add.vue"),
        },
        {
          path: "/kpi_type/edit/:id",
          name: "kpi_type_edit",
          meta: {
            auth: true,
            screen_code: "CREATE_MASTERS",
            page_code: "SCN_KPI_TYPE",
            page_type: "edit_screen",
          },
          component: () => import("../views/masters/kpi_type_edit.vue"),
        },
        {
          path: "/Subdivision",
          name: "Subdivision",
          meta: {
            auth: true,
            screen_code: "CREATE_MASTERS",
            page_code: "SCN_SUB_DIVISION",
            page_type: "landing",
          },
          component: () => import("../views/masters/Subdivision.vue"),
        },
        {
          path: "/Branch",
          name: "branch",
          meta: {
            auth: true,
            screen_code: "CREATE_MASTERS",
            page_code: "SCN_SUB_DIVISION",
            page_type: "landing",
          },
          component: () => import("../views/masters/branch.vue"),
        },
        {
          path: "/Subdivision/add",
          name: "Subdivision_add",
          meta: {
            auth: true,
            screen_code: "CREATE_MASTERS",
            page_code: "SCN_SUB_DIVISION",
            page_type: "add_screen",
          },
          component: () => import("../views/masters/Subdivision_add.vue"),
        },
        {
          path: "/Subdivision/edit/:id",
          name: "Subdivision_edit",
          meta: {
            auth: true,
            screen_code: "CREATE_MASTERS",
            page_code: "SCN_SUB_DIVISION",
            page_type: "edit_screen",
          },
          component: () => import("../views/masters/Subdivision_edit.vue"),
        },
        {
          path: "/Division",
          name: "Division",
          meta: {
            auth: true,
            screen_code: "CREATE_MASTERS",
            page_code: "SCN_DIVISION",
            page_type: "landing",
          },
          component: () => import("../views/masters/Division.vue"),
        },

        {
          path: "/Division/add",
          name: "Division_add",
          meta: {
            auth: true,
            screen_code: "CREATE_MASTERS",
            page_code: "SCN_DIVISION",
            page_type: "add_screen",
          },
          component: () => import("../views/masters/Division_add.vue"),
        },
        {
          path: "/Division/edit/:id",
          name: "Division_edit",
          meta: {
            auth: true,
            screen_code: "CREATE_MASTERS",
            page_code: "SCN_DIVISION",
            page_type: "edit_screen",
          },
          component: () => import("../views/masters/Division_edit.vue"),
        },
        {
          path: "/subchannel",
          name: "subchannel",
          meta: {
            auth: true,
            screen_code: "CREATE_MASTERS",
            page_code: "SCN_SUB_CHANNEL",
            page_type: "landing",
          },
          component: () => import("../views/masters/subchannel.vue"),
        },
        {
          path: "/subchannel/add",
          name: "subchannel_add",
          meta: {
            auth: true,
            screen_code: "CREATE_MASTERS",
            page_code: "SCN_SUB_CHANNEL",
            page_type: "add_screen",
          },
          component: () => import("../views/masters/subchannel_add.vue"),
        },
        {
          path: "/subchannel/edit/:id",
          name: "subchannel_edit",
          meta: {
            auth: true,
            screen_code: "CREATE_MASTERS",
            page_code: "SCN_SUB_CHANNEL",
            page_type: "edit_screen",
          },
          component: () => import("../views/masters/subchannel_edit.vue"),
        },
        {
          path: "/category",
          name: "category",
          meta: {
            auth: true,
            screen_code: "CREATE_MASTERS",
            page_code: "SCN_CATEGORY",
            page_type: "landing",
          },
          component: () => import("../views/masters/category.vue"),
        },
        {
          path: "/category/add",
          name: "category_add",
          meta: {
            auth: true,
            screen_code: "CREATE_MASTERS",
            page_code: "SCN_CATEGORY",
            page_type: "add_screen",
          },
          component: () => import("../views/masters/category_add.vue"),
        },
        {
          path: "/category/edit/:id",
          name: "category_edit",
          meta: {
            auth: true,
            screen_code: "CREATE_MASTERS",
            page_code: "SCN_CATEGORY",
            page_type: "edit_screen",
          },
          component: () => import("../views/masters/category_edit.vue"),
        },

        {
          path: "/store",
          name: "store",
          meta: {
            auth: true,
            screen_code: "CREATE_MASTERS",
            page_code: "SCN_STORE",
            page_type: "landing",
          },
          component: () => import("../views/masters/store.vue"),
        },
        {
          path: "/store/add",
          name: "store_add",
          meta: {
            auth: true,
            screen_code: "CREATE_MASTERS",
            page_code: "SCN_STORE",
            page_type: "add_screen",
          },
          component: () => import("../views/masters/store_add.vue"),
        },
        {
          path: "/store/edit/:id",
          name: "store_edit",
          meta: {
            auth: true,
            screen_code: "CREATE_MASTERS",
            page_code: "SCN_STORE",
            page_type: "edit_screen",
          },
          component: () => import("../views/masters/store_edit.vue"),
        },

        {
          path: "/service",
          name: "Service",
          meta: {
            auth: true,
            screen_code: "CREATE_MASTERS",
            page_code: "SCN_SERVICE",
            page_type: "landing",
          },
          component: () => import("../views/masters/service.vue"),
        },

        {
          path: "/ticket",
          name: "Ticket",
          meta: {
            auth: true,
            screen_code: "CREATE_CHK",
            page_code: "SCN_TKT_TICKETS",
            page_type: "landing",
          },
          component: () => import("../views/tickets/ticketIndex/main.vue"),
        },
        {
          path: "/tickets_report",
          name: "TicketReport",
          meta: {
            auth: true,
            screen_code: "CREATE_CHK",
            page_code: "SCN_TKT_REPORTS",
            page_type: "landing",
          },
          component: () => import("../views/tickets/Reports/report_view.vue"),
        },
        {
          path: "/ticket/view/:id",
          name: "TicketView",
          meta: {
            auth: true,
            screen_code: "CREATE_CHK",
            page_code: "SCN_TKT_TICKETS",
            page_type: "landing",
          },
          component: () =>
            import("../views/tickets/ticketView/ticket_view.vue"),
        },

        {
          path: "/ticket/add",
          name: "TicketAdd",
          meta: {
            auth: true,
            screen_code: "CREATE_CHK",
            page_code: "SCN_TKT_TICKETS",
            page_type: "add_screen",
          },
          component: () => import("../views/tickets/ticket_add.vue"),
        },

        {
          path: "/ticket/forward",
          name: "Ticketforward",
          meta: {
            auth: true,
            screen_code: "CREATE_CHK",
            page_code: "SCN_TKT_TICKETS",
            page_type: "landing",
          },
          component: () => import("../views/tickets/ticket_forward.vue"),
        },

        {
          path: "/ticket/edit/:id",
          name: "TicketEdit",
          meta: {
            auth: true,
            screen_code: "CREATE_CHK",
            page_code: "SCN_TKT_TICKETS",
            page_type: "edit_screen",
          },
          component: () => import("../views/tickets/ticket_edit.vue"),
        },

        {
          path: "/service/add",
          name: "ServiceAdd",
          meta: {
            auth: true,
            screen_code: "CREATE_MASTERS",
            page_code: "SCN_SERVICE",
            page_type: "add_screen",
          },
          component: () => import("../views/masters/service_add.vue"),
        },

        {
          path: "/service/edit/:id",
          name: "ServiceEdit",
          meta: {
            auth: true,
            screen_code: "CREATE_MASTERS",
            page_code: "SCN_SERVICE",
            page_type: "edit_screen",
          },
          component: () => import("../views/masters/service_edit.vue"),
        },

        {
          path: "/serviceCategory",
          name: "ServiceCategory",
          meta: {
            auth: true,
            screen_code: "CREATE_MASTERS",
            page_code: "SCN_SERVICE_CATEGORY",
            page_type: "landing",
          },
          component: () => import("../views/masters/Service_category.vue"),
        },

        {
          path: "/serviceCategory/add",
          name: "ServiceCategoryAdd",
          meta: {
            auth: true,
            screen_code: "CREATE_MASTERS",
            page_code: "SCN_SERVICE_CATEGORY",
            page_type: "add_screen",
          },
          component: () => import("../views/masters/Service_category_add.vue"),
        },

        {
          path: "/serviceCategory/edit/:id",
          name: "ServiceCategoryEdit",
          meta: {
            auth: true,
            screen_code: "CREATE_MASTERS",
            page_code: "SCN_SERVICE_CATEGORY",
            page_type: "edit_screen",
          },
          component: () => import("../views/masters/Service_category_edit.vue"),
        },

        {
          path: "/designation",
          name: "Designation",
          meta: {
            auth: true,
            screen_code: "CREATE_MASTERS",
            page_code: "SCN_DESIGNATION",
            page_type: "landing",
          },
          component: () => import("../views/masters/Designation.vue"),
        },

        {
          path: "/designation_mstr/add",
          name: "Designation_add",
          meta: {
            auth: true,
            screen_code: "CREATE_MASTERS",
            page_code: "SCN_DESIGNATION",
            page_type: "add_screen",
          },
          component: () => import("../views/masters/Designation_add.vue"),
        },
        {
          path: "/role_assignments",
          name: "Role Assignments Screen",
          meta: {
            auth: true,
            screen_code: "CREATE_MASTERS",
            page_code: "SCN_DESIGNATION",
            page_type: "landing",
          },
          component: () => import("../views/masters/RoleAssignments.vue"),
        },

        {
          path: "/role_assignments/add",
          name: "Role Assignments Screen Add",
          meta: {
            auth: true,
            screen_code: "CREATE_MASTERS",
            page_code: "SCN_DESIGNATION",
            page_type: "add_screen",
          },
          component: () => import("../views/masters/RoleAssignments_add.vue"),
        },
        {
          path: "/role_assignments/edit/:id",
          name: "Role Assignments Edit",
          meta: {
            auth: true,
            screen_code: "CREATE_MASTERS",
            page_code: "SCN_DESIGNATION",
            page_type: "edit_screen",
          },
          component: () => import("../views/masters/RoleAssignments_edit.vue"),
        },
        {
          path: "/department_mstr/add",
          name: "Department_add",
          meta: {
            auth: true,
            screen_code: "CREATE_MASTERS",
            page_code: "SCN_DEPARTMENT",
            page_type: "add_screen",
          },
          component: () => import("../views/masters/Department_add.vue"),
        },
        {
          path: "/segment_mstr/add",
          name: "Segment_add",
          meta: {
            auth: true,
            screen_code: "CREATE_MASTERS",
            page_code: "SCN_DEPARTMENT",
            page_type: "add_screen",
          },
          component: () => import("../views/masters/Segment_add.vue"),
        },

        {
          path: "/branch_mstr/add",
          name: "Dashboard_add",
          meta: {
            auth: true,
            screen_code: "CREATE_MASTERS",
            page_code: "SCN_BRANCH",
            page_type: "add_screen",
          },
          component: () => import("../views/masters/Dashboard_add.vue"),
        },

        {
          path: "/branch_mstr/edit/:id",
          name: "Dashboard_edit",
          meta: {
            auth: true,
            screen_code: "CREATE_MASTERS",
            page_code: "SCN_BRANCH",
            page_type: "edit_screen",
          },
          component: () => import("../views/masters/Dashboard_edit.vue"),
        },
        {
          path: "/visittype/add",
          name: "Visittype_add",
          meta: {
            auth: true,
            screen_code: "SCN_VISIT_TYPE",
            page_code: "SCN_VISIT_TYPE",
            page_type: "add_screen",
          },
          component: () => import("../views/masters/visittype_add.vue"),
        },

        {
          path: "/visittype/edit/:id",
          name: "Visittype_edit",
          meta: {
            auth: true,
            screen_code: "SCN_VISIT_TYPE",
            page_code: "SCN_VISIT_TYPE",
            page_type: "edit_screen",
          },
          component: () => import("../views/masters/visittype_edit.vue"),
        },

        {
          path: "/PurposeOfMeeting/add",
          name: "PurposeOfMeeting_add",
          meta: {
            auth: true,
            screen_code: "CREATE_MASTERS",
            page_code: "SCN_BRANCH",
            page_type: "add_screen",
          },
          component: () => import("../views/masters/PurposeOfMeeting_add.vue"),
        },

        {
          path: "/PurposeOfMeeting/edit/:id",
          name: "PurposeOfMeeting_edit",
          meta: {
            auth: true,
            screen_code: "CREATE_MASTERS",
            page_code: "SCN_BRANCH",
            page_type: "edit_screen",
          },
          component: () => import("../views/masters/PurposeOfMeeting_edit.vue"),
        },

        {
          path: "/department_mstr/edit/:id",
          name: "Department_edit",
          meta: {
            auth: true,
            screen_code: "CREATE_MASTERS",
            page_code: "SCN_DEPARTMENT",
            page_type: "edit_screen",
          },
          component: () => import("../views/masters/Department_edit.vue"),
        },
        {
          path: "/segment_mstr/edit/:id",
          name: "Segment_edit",
          meta: {
            auth: true,
            screen_code: "CREATE_MASTERS",
            page_code: "SCN_DEPARTMENT",
            page_type: "edit_screen",
          },
          component: () => import("../views/masters/Segment_edit.vue"),
        },

        {
          path: "/designation_mstr/edit/:id",
          name: "Designation_edit",
          meta: {
            auth: true,
            screen_code: "CREATE_MASTERS",
            page_code: "SCN_DESIGNATION",
            page_type: "edit_screen",
          },
          component: () => import("../views/masters/Designation_edit.vue"),
        },
        {
          path: "/employee",
          name: "Employee",
          meta: {
            auth: true,
            screen_code: "CREATE_MASTERS",
            page_code: "SCN_EMPLOYEE",
            page_type: "landing",
          },
          component: () => import("../views/masters/Employee.vue"),
        },
        {
          path: "/employee_mstr/add",
          name: "Employee_add",
          meta: {
            auth: true,
            screen_code: "CREATE_MASTERS",
            page_code: "SCN_EMPLOYEE",
            page_type: "add_screen",
          },
          component: () => import("../views/masters/Employee_add.vue"),
        },
        {
          path: "/employee_mstr/edit/:id",
          name: "Employee_edit",
          meta: {
            auth: true,
            screen_code: "CREATE_MASTERS",
            page_code: "SCN_EMPLOYEE",
            page_type: "edit_screen",
          },
          component: () => import("../views/masters/Employee_edit.vue"),
        },
        {
          path: "/employee_mstr/view/:id",
          name: "Employee_view",
          meta: {
            auth: true,
            screen_code: "CREATE_MASTERS",
            page_code: "SCN_EMPLOYEE",
            page_type: "edit_screen",
          },
          component: () => import("../views/masters/Employee_view.vue"),
        },
        {
          path: "/walkins/reports",
          name: "Walkins_reports",
          meta: {
            auth: true,
            screen_code: "SCN_WLK_RPRT",
            page_code: "SCN_WLK_RPRT",
            page_type: "landing",
          },
          component: () => import("../views/walkins_reports.vue"),
        },
        {
          path: "/walkin/landing",
          name: "Walkin_landing",
          meta: {
            auth: true,
            screen_code: "SCN_WALKINS",
            page_code: "SCN_WALKINS",
            page_type: "landing",
          },
          component: () => import("../views/walkin_landing.vue"),
        },
        {
          path: "/walkin/feedback",
          name: "Walkins_feedback",
          meta: {
            auth: true,
            screen_code: "SCN_WLKN_FEEDBACK",
            page_code: "SCN_WLKN_FEEDBACK",
            page_type: "landing",
          },
          component: () => import("../views/walkins_feedback.vue"),
        },
        {
          path: "/feedback/question/add",
          name: "Walkins_feedback_add",
          meta: {
            auth: true,
            screen_code: "SCN_WLKN_FEEDBACK",
            page_code: "SCN_WLKN_FEEDBACK",
            page_type: "add_screen",
          },
          component: () => import("../views/wlk_feedback_ques_add.vue"),
        },
        {
          path: "/feedback/question/edit/:id",
          name: "Walkins_feedback_edit",
          meta: {
            auth: true,
            screen_code: "SCN_WLKN_FEEDBACK",
            page_code: "SCN_WLKN_FEEDBACK",
            page_type: "edit_screen",
          },
          component: () => import("../views/wlk_feedback_ques_edit.vue"),
        },
        {
          path: "/feedback/results/:id",
          name: "Feedback_Result",
          meta: {
            auth: true,
            screen_code: "SCN_WLKN_FEEDBACK",
            page_code: "SCN_WLKN_FEEDBACK",
            page_type: "landing",
          },
          component: () => import("../views/wlk_feedback_result.vue"),
        },
        {
          path: "/feedback/reports",
          name: "Walkins_feedback_reports",
          meta: {
            auth: true,
            screen_code: "SCN_WLKN_FEEDBACK_RPRT",
            page_code: "SCN_WLKN_FEEDBACK_RPRT",
            page_type: "landing",
          },
          component: () => import("../views/wlk_feedback_report.vue"),
        },
        // pavithran
        {
          path: "/visits/landing",
          name: "visits_landing",
          meta: {
            auth: true,
            screen_code: "SCN_VISITS",
            page_code: "SCN_VISITS",
            page_type: "landing",
          },
          component: () => import("../views/visits_landing.vue"),
        },
        //
        {
          path: "/visitReport",
          name: "visitreport",
          meta: {
            auth: true,
            screen_code: "SCN_VISIT_REPORTS",
            page_code: "SCN_VISIT_REPORTS",
            page_type: "landing",
          },
          component: () => import("../views/visit_report.vue"),
        },
        {
          path: "/attendance_landing",
          name: "attendance_landing",
          meta: {
            auth: true,
            screen_code: "SCN_VISITS",
            page_code: "SCN_VISITS",
            page_type: "landing",
          },
          component: () => import("../views/Attendance/attendance.vue"),
        },
        {
          path: "/visits/attendance_report",
          name: "visits_attendance_report",
          meta: {
            auth: true,
            screen_code: "SCN_ATTENDANCE_REPORTS",
            page_code: "SCN_ATTENDANCE_REPORTS",
            page_type: "landing",
          },
          component: () => import("../views/Attendance/chck_attendance_reports.vue"),
        },
        {
          path: "/plan",
          name: "plan",
          meta: {
            auth: true,
            screen_code: "SCN_VISITS",
            page_code: "SCN_VISITS",
            page_type: "add_screen",
          },
          component: () => import("../views/plan_visit.vue"),
        },
        {
          path: "/logvisit",
          name: "visit",
          meta: {
            auth: true,
            screen_code: "SCN_VISITS",
            page_code: "SCN_VISITS",
            page_type: "add_screen",
          },
          component: () => import("../views/log_visit.vue"),
        },
        {
          path: "/logvisit/add",
          name: "logvisit_add",
          meta: {
            auth: true,
            screen_code: "SCN_VISITS",
            page_code: "SCN_VISITS",
            page_type: "add_screen",
          },
          component: () => import("../views/logvisit_add.vue"),
        },
        {
          path: "/logvisit/edit/:id",
          name: "logvisit_edit",
          meta: {
            auth: true,
            screen_code: "SCN_VISITS",
            page_code: "SCN_VISITS",
            page_type: "edit_screen",
          },
          component: () => import("../views/logvisit_edit.vue"),
        },
        {
          path: "/crm/landing",
          name: "Crm",
          meta: {
            auth: true,
            screen_code: "CREATE_MASTERS",
            page_code: "SCN_EMPLOYEE",
            page_type: "landing",
          },
          component: () => import("../views/crm_landing.vue"),
        },
        {
          path: "crm_add",
          name: "crm_add",
          meta: {
            auth: true,
            screen_code: "CREATE_MASTERS",
            page_code: "SCN_EMPLOYEE",
            page_type: "add_screen",
          },
          component: () => import("../views/crm_add.vue"),
        },
        {
          path: "crm_edit/:id",
          name: "crm_edit",
          meta: {
            auth: true,
            screen_code: "CREATE_MASTERS",
            page_code: "SCN_EMPLOYEE",
            page_type: "edit_screen",
          },
          component: () => import("../views/crm_edit.vue"),
        },
        {
          path: "/lead_source/landing",
          name: "Lead Source",
          meta: {
            auth: true,
            screen_code: "CREATE_MASTERS",
            page_code: "SCN_EMPLOYEE",
            page_type: "landing",
          },
          component: () => import("../views/lead_source_landing.vue"),
        },
        {
          path: "/lead_source/add",
          name: "Lead Source add",
          meta: {
            auth: true,
            screen_code: "CREATE_MASTERS",
            page_code: "SCN_EMPLOYEE",
            page_type: "add_screen",
          },
          component: () => import("../views/lead_source_add.vue"),
        },
        {
          path: "/lead_source/edit/:id",
          name: "Lead Source edit",
          meta: {
            auth: true,
            screen_code: "CREATE_MASTERS",
            page_code: "SCN_EMPLOYEE",
            page_type: "edit_screen",
          },
          component: () => import("../views/lead_source_edit.vue"),
        },
        {
          path: "/wlk_campaign",
          name: "Campaign",
          meta: {
            auth: true,
            screen_code: "CREATE_MASTERS",
            page_code: "SCN_EMPLOYEE",
            page_type: "landing",
          },
          component: () => import("../views/wlk_campaign.vue"),
        },
        {
          path: "/campaign/add",
          name: "Campaign_add",
          meta: {
            auth: true,
            screen_code: "CREATE_MASTERS",
            page_code: "SCN_EMPLOYEE",
            page_type: "add_screen",
          },
          component: () => import("../views/campaign_add.vue"),
        },
        {
          path: "/occasion/landing",
          name: "Occasion",
          meta: {
            auth: true,
            screen_code: "CREATE_MASTERS",
            page_code: "SCN_EMPLOYEE",
            page_type: "landing",
          },
          component: () => import("../views/occasion.vue"),
        },
        {
          path: "/occasion/add",
          name: "occasion_add",
          meta: {
            auth: true,
            screen_code: "CREATE_MASTERS",
            page_code: "SCN_EMPLOYEE",
            page_type: "add_screen",
          },
          component: () => import("../views/occasion_add.vue"),
        },
        {
          path: "/occasion/edit/:id",
          name: "occasion_edit",
          meta: {
            auth: true,
            screen_code: "CREATE_MASTERS",
            page_code: "SCN_EMPLOYEE",
            page_type: "edit_screen",
          },
          component: () => import("../views/occasion_edit.vue"),
        },
        {
          path: "/campaign/edit/:id",
          name: "Campaign_edit",
          meta: {
            auth: true,
            screen_code: "CREATE_MASTERS",
            page_code: "SCN_EMPLOYEE",
            page_type: "add_screen",
          },
          component: () => import("../views/campaign_edit.vue"),
        },
        {
          path: "/employee_mstr/reporting",
          name: "Employee_Reporting",
          meta: {
            auth: true,
            screen_code: "CREATE_MASTERS",
            page_code: "SCN_EMPLOYEE",
            page_type: "edit_screen",
          },
          component: () => import("../views/masters/employee_reporting.vue"),
        },
        {
          path: "/scenarios",
          name: "Scenarios",
          meta: {
            auth: true,
            screen_code: "CREATE_MASTERS",
            page_code: "SCN_SCENARIOS",
            page_type: "landing",
          },
          component: () => import("../views/masters/scenarios.vue"),
        },
        {
          path: "/scenarios/add",
          name: "Scenarios_add",
          meta: {
            auth: true,
            screen_code: "CREATE_MASTERS",
            page_code: "SCN_SCENARIOS",
            page_type: "add_screen",
          },
          component: () => import("../views/masters/scenarios_add.vue"),
        },
        {
          path: "/scenarios/edit/:id",
          name: "scenarios_edit",
          meta: {
            auth: true,
            screen_code: "CREATE_MASTERS",
            page_code: "SCN_SCENARIOS",
            page_type: "edit_screen",
          },
          component: () => import("../views/masters/scenarios_edit.vue"),
        },
        {
          path: "/esclation",
          name: "Esclation",
          meta: {
            auth: true,
            screen_code: "CREATE_MASTERS",
            page_code: "SCN_MAIL_CONFIGURATION",
            page_type: "landing",
          },
          component: () => import("../views/masters/esclation.vue"),
        },
        {
          path: "/esclation/edit/:id",
          name: "Esclation_edit",
          meta: {
            auth: true,
            screen_code: "CREATE_MASTERS",
            page_code: "SCN_MAIL_CONFIGURATION",
            page_type: "edit_screen",
          },
          component: () => import("../views/masters/esclation_notify.vue"),
        },
        {
          path: "/users",
          name: "Users",
          meta: {
            auth: true,
            screen_code: "CREATE_USERS",
            page_code: "SCN_USERS",
            page_type: "landing",
          },
          component: () => import("../views/masters/Users.vue"),
        },
        {
          path: "/function",
          name: "Function",
          meta: {
            auth: true,
            screen_code: "CREATE_MASTERS",
            page_code: "SCN_SCENARIOS",
            page_type: "add_screen",
          },
          component: () => import("../views/masters/function.vue"),
        },
        {
          path: "/function/add",
          name: "Function_add",
          meta: {
            auth: true,
            screen_code: "CREATE_MASTERS",
            page_code: "SCN_SCENARIOS",
            page_type: "add_screen",
          },
          component: () => import("../views/masters/function_add.vue"),
        },
        {
          path: "/function/edit/:id",
          name: "Function_edit",
          meta: {
            auth: true,
            screen_code: "CREATE_MASTERS",
            page_code: "SCN_SCENARIOS",
            page_type: "edit_screen",
          },
          component: () => import("../views/masters/function_edit.vue"),
        },
        {
          path: "/incentive",
          name: "Incentive",
          meta: {
            auth: true,
            screen_code: "CREATE_MASTERS",
            page_code: "SCN_PAYROLL",
            page_type: "landing",
          },
          component: () => import("../views/masters/incentive.vue"),
        },
        {
          path: "/incentive/add",
          name: "AddIncentive",
          meta: {
            auth: true,
            screen_code: "CREATE_MASTERS",
            page_code: "SCN_PAYROLL",
            page_type: "add_screen",
          },
          component: () => import("../views/masters/incentive_add.vue"),
        },
        {
          path: "/incentive/edit/:id",
          name: "EditIncentive",
          meta: {
            auth: true,
            screen_code: "CREATE_MASTERS",
            page_code: "SCN_PAYROLL",
            page_type: "edit_screen",
          },
          component: () => import("../views/masters/incentive_edit.vue"),
        },
        {
          path: "/incentive/view/:id",
          name: "ViewIncentive",
          meta: {
            auth: true,
            screen_code: "CREATE_MASTERS",
            page_code: "SCN_PAYROLL",
            page_type: "landing",
          },
          component: () => import("../views/masters/incentive_view.vue"),
        },

        {
          path: "/users_mstr/add",
          name: "Users_add",
          meta: {
            auth: true,
            screen_code: "CREATE_USERS",
            page_code: "SCN_USERS",
            page_type: "add_screen",
          },
          component: () => import("../views/masters/Users_add.vue"),
        },
        {
          path: "/users_mstr/edit/:id",
          name: "Users_edit",
          meta: {
            auth: true,
            screen_code: "CREATE_USERS",
            page_code: "SCN_USERS",
            page_type: "edit_screen",
          },
          component: () => import("../views/masters/Users_edit.vue"),
        },
        {
          path: "/master_checklist",
          name: "MasterChecklist",
          meta: {
            auth: true,
          },
          component: () =>
            import("../views/master_checklist/master_checklist.vue"),
        },
        {
          path: "/master_checklist/add",
          name: "AddMasterChecklist",
          meta: {
            auth: true,
          },
          component: () =>
            import("../views/master_checklist/master_checklist_add.vue"),
        },
        {
          path: "/master_checklist/edit/:id",
          name: "EditMasterChecklist",
          meta: {
            auth: true,
          },
          component: () =>
            import("../views/master_checklist/master_checklist_edit.vue"),
        },

        {
          path: "/master_checklist_tasks/:id",
          name: "master_checklist_tasks",
          meta: {
            auth: true,
          },
          component: () => import("../views/master_checklist/tasks/tasks.vue"),
        },

        // Tasks
        {
          path: "/checklist_tasks/add/:id",
          name: "AddChecklistTasks",
          meta: {
            auth: true,
          },
          component: () =>
            import("../views/master_checklist/tasks/tasks_add.vue"),
        },
        {
          path: "/checklist_tasks/edit/:id/:mstr_chk_id",
          name: "EditChecklistTasks",
          meta: {
            auth: true,
          },
          component: () =>
            import("../views/master_checklist/tasks/tasks_edit.vue"),
        },

        // Subtasks

        {
          path: "/master_checklist_subtasks/:id",
          name: "master_checklist_subtasks",
          meta: {
            auth: true,
          },
          component: () =>
            import("../views/master_checklist/subtasks/subtasks.vue"),
        },
        {
          path: "/chcklist_subtasks/add/:id",
          name: "AddChcklistSubtasks",
          meta: {
            auth: true,
          },
          component: () =>
            import("../views/master_checklist/subtasks/subtasks_add.vue"),
        },
        {
          path: "/chcklist_subtasks/edit/:id/:task_id",
          name: "EditChcklistSubtasks",
          meta: {
            auth: true,
          },
          component: () =>
            import("../views/master_checklist/subtasks/subtasks_edit.vue"),
        },
        {
          path: "/client",
          name: "Client",
          meta: {
            auth: true,
            iscommontoshow: true,
          },
          component: () => import("../views/Client.vue"),
        },
        {
          path: "/Assets",
          name: "Assets",
          meta: {
            auth: true,
            iscommontoshow: true,
          },
          component: () => import("../views/masters/assets.vue"),
        },
        // {
        //     path: '/subscription',
        //     name: 'subscription',
        //     meta: {
        //         auth: true,
        //         iscommontoshow: true,
        //     },
        //     component: () =>
        //         import ('../views/subscription/subscription.vue')
        // },
        {
          path: "/lead_landing",
          name: "Lead",
          meta: {
            auth: true,
            iscommontoshow: true,
          },
          component: () => import("../views/lead_landing.vue"),
        },
        {
          path: "lead_add",
          name: "lead_add",
          meta: {
            auth: true,
            iscommontoshow: true,
          },
          component: () => import("../views/lead_add.vue"),
        },
        {
          path: "lead_edit/:id",
          name: "Lead_edit",
          meta: {
            auth: true,
            iscommontoshow: true,
          },
          component: () => import("../views/lead_edit.vue"),
        },
        {
          path: "/lead_source_landing",
          name: "Lead Source",
          meta: {
            auth: true,
            iscommontoshow: true,
          },
          component: () => import("../views/lead_source_landing.vue"),
        },
        {
          path: "/campaign_landing",
          name: "Campaign",
          meta: {
            auth: true,
            iscommontoshow: true,
          },
          component: () => import("../views/campaign_landing.vue"),
        },
        // {
        //     path: '/discount_mstr',
        //     name: 'discount_mstr',
        //     meta: {
        //         auth: true,
        //         iscommontoshow: true,
        //     },
        //     component: () =>
        //         import ('../views/subscription/discount_mstr.vue')
        // },
        {
          path: "/modules",
          name: "modules",
          meta: {
            auth: true,
            iscommontoshow: true,
          },
          component: () => import("../views/modules/modules.vue"),
        },
        {
          path: "/addmodules",
          name: "addmodules",
          meta: {
            auth: true,
            iscommontoshow: true,
          },
          component: () => import("../views/modules/addmodules.vue"),
        },

        {
          path: "/editmodules/:id",
          name: "editmodules",
          meta: {
            auth: true,
            iscommontoshow: true,
          },
          component: () => import("../views/modules/editmodules.vue"),
        },
        {
          path: "/addscreen",
          name: "addscreen",
          meta: {
            auth: true,
            iscommontoshow: true,
          },
          component: () => import("../views/modules/addscreen.vue"),
        },
        {
          path: "/editscreen/:id",
          name: "editscreen",
          meta: {
            auth: true,
            iscommontoshow: true,
          },
          component: () => import("../views/modules/editscreen.vue"),
        },
        {
          path: "/image",
          name: "Image",
          meta: {
            auth: true,
            iscommontoshow: true,
          },
          component: () => import("../views/Image.vue"),
        },

        {
          path: "/product",
          name: "Products",
          meta: {
            auth: true,
            iscommontoshow: true,
          },
          component: () => import("../views/Product.vue"),
        },
        {
          path: "/show_list",
          name: "Show List",
          meta: {
            auth: true,
            // iscommontoshow: true,
            isnoadmintoshow: true,
          },
          component: () => import("../views/show_list.vue"),
        },
        {
          path: "/welcome_page",
          name: "Welcome Page",
          meta: {
            auth: true,
            // iscommontoshow: true,
            isnoadmintoshow: true,
          },
          component: () => import("../views/FMS/welcome_page.vue"),
        },
        {
          path: "/tasks_detail",
          name: "tasks_detail",
          meta: {
            auth: true,
            // iscommontoshow: true,
            isnoadmintoshow: true,
          },
          component: () => import("../views/tasks_detail/checklist_detail.vue"),
        },
        {
          path: "/tasks_detail_mobile",
          name: "tasks_detail_mobile",
          meta: {
            auth: true,
            // iscommontoshow: true,
            isnoadmintoshow: true,
          },
          component: () =>
            import("../views/tasks_detail/checklist_detail_mobile.vue"),
        },

        {
          path: "/Dashboard_checklist",
          name: "Dashboard",
          meta: {
            auth: true,
            // iscommontoshow: true,
            isnoadmintoshow: true,
          },
          component: () => import("../views/Dashboard1.vue"),
        },
        {
          path: "/Industry",
          name: "Industry",
          meta: {
            auth: true,
            iscommontoshow: true,
          },
          component: () => import("../views/Industry.vue"),
        },

        {
          path: "/process",
          name: "Process",
          meta: {
            auth: true,
            iscommontoshow: true,
          },
          component: () => import("../views/Process.vue"),
        },
        {
          path: "/MainChecklist_new",
          name: "Check List",
          meta: {
            auth: true,
            screen_code: "CREATE_CHK",
            page_code: "SCN_CHCK_MY_CHECKLIST",
            page_type: "landing",
          },
          component: () =>
            import("../views/master_checklist/checklist/MainChecklist_new.vue"),
        },
        {
          path: "/MainChecklist",
          name: "Check List",
          meta: {
            auth: true,
            screen_code: "CREATE_CHK",
            page_code: "SCN_CHCK_MY_CHECKLIST",
            page_type: "landing",
          },
          component: () =>
            import("../views/master_checklist/checklist/MainChecklist.vue"),
        },
        {
          path: "/MyTasks",
          name: "My Check List",
          meta: {
            auth: true,
            screen_code: "CREATE_CHK",
            page_code: "SCN_CHCK_MY_CHECKLIST",
            page_type: "landing",
          },
          component: () => import("../views/show_list_bulk_update_Task.vue"),
        },
        {
          path: "/uichanges",
          name: "My Checklist - New UI",
          meta: {
            auth: true,
            screen_code: "CREATE_CHK",
            page_code: "SCN_CHCK_MY_CHECKLIST",
            page_type: "landing",
          },
          component: () =>
            import(
              "../views/master_checklist/checklist/MainChecklistuichanges.vue"
            ),
        },
        // checklist_Template_page
        {
          path: "/checklist_Template_page",
          name: "checklist_Template_page",
          meta: {
            auth: true,
            screen_code: "ADOPT_CHK",
            iscommontoshow: true,
            isnoadmintoshow: true,
          },
          component: () =>
            import(
              "../views/master_checklist/checklist_typ/checklist_Template_page.vue"
            ),
        },
        {
          path: "/Case_Based_Checklist",
          name: "Case Based Checklist",
          meta: {
            auth: true,
            screen_code: "CREATE_CHK",
            page_code: "SCN_CHCK_CASE_BASED",
            page_type: "landing",
          },
          component: () =>
            import(
              "../views/master_checklist/case_Based/Case_Based_Checklist.vue"
            ),
        },
        {
          path: "/showlist_all_report",
          name: "Reports",
          meta: {
            auth: true,
            screen_code: "RUN_REPORTS",
            page_code: "SCN_CHCK_REPORTS",
            page_type: "landing",
          },
          component: () =>
            import("../views/master_checklist/reports/showlist_all_report.vue"),
        },
        {
          path: "/dynamicFormDetails",
          name: "dynamicFormDetails",
          meta: {
            auth: true,
            screen_code: "RUN_REPORTS",
            page_code: "SCN_CHCK_REPORTS",
            page_type: "landing",
          },
          component: () =>
            import(
              "../views/master_checklist/checklist/dynamicFormDetails.vue"
            ),
        },
        //
        // budget

        {
          path: "/budget",
          name: "budget",
          meta: {
            auth: true,
            screen_code: "CREATE_MASTERS",
            page_code: "SCN_BUDGET",
            page_type: "landing",
            isnoadmintoshow: true,
          },
          component: () => import("../views/Budget/budget.vue"),
        },
        {
          path: "/budget/add",
          name: "budget_add",
          meta: {
            auth: true,
            screen_code: "CREATE_MASTERS",
            page_code: "SCN_BUDGET",
            page_type: "add_screen",
          },
          component: () => import("../views/Budget/budget_add.vue"),
        },
        {
          path: "/budget/edit/:id",
          name: "budget_edit",
          meta: {
            auth: true,
            screen_code: "CREATE_MASTERS",
            page_code: "SCN_BUDGET",
            page_type: "add_screen",
          },
          component: () => import("../views/Budget/budget_edit.vue"),
        },
        {
          path: "/budget_reports",
          name: "budget_reports",
          meta: {
            auth: true,
            screen_code: "CREATE_MASTERS",
            page_code: "SCN_BUDGET",
            page_type: "add_screen",
          },
          component: () => import("../views/Budget/budget_reports.vue"),
        },
        //
        {
          path: "/actual",
          name: "actual",
          meta: {
            auth: true,
            screen_code: "CREATE_MASTERS",
            page_code: "SCN_ACTUAL",
            page_type: "landing",
            isnoadmintoshow: true,
          },
          component: () => import("../views/actual/actual.vue"),
        },
        {
          path: "/actual/add",
          name: "actual_add",
          meta: {
            auth: true,
            screen_code: "CREATE_MASTERS",
            page_code: "SCN_ACTUAL",
            page_type: "add_screen",
          },
          component: () => import("../views/actual/actual_add.vue"),
        },
        {
          path: "/actual/edit/:id",
          name: "actual_edit",
          meta: {
            auth: true,
            screen_code: "CREATE_MASTERS",
            page_code: "SCN_ACTUAL",
            page_type: "add_screen",
          },
          component: () => import("../views/actual/actual_edit.vue"),
        },
        {
          path: "/actual_reports",
          name: "actual_reports",
          meta: {
            auth: true,
            screen_code: "CREATE_MASTERS",
            page_code: "SCN_ACTUAL",
            page_type: "add_screen",
          },
          component: () => import("../views/actual/actual_reports.vue"),
        },

        // Feartues
        {
          path: "/feature",
          name: "feature",
          meta: {
            auth: true,
            screen_code: "CREATE_MASTERS",
          },
          component: () => import("../views/feature/feature.vue"),
        },
        {
          path: "/feature/add",
          name: "feature_add",
          meta: {
            auth: true,
            screen_code: "CREATE_MASTERS",
          },
          component: () => import("../views/feature/feature_add.vue"),
        },

        {
          path: "/feature/edit/:id",
          name: "feature_edit",
          meta: {
            auth: true,
            screen_code: "CREATE_MASTERS",
          },
          component: () => import("../views/feature/feature_edit.vue"),
        },
        {
          path: "/role",
          name: "role",
          meta: {
            auth: true,
            screen_code: "CREATE_MASTERS",
            page_code: "SCN_ROLE",
            page_type: "landing",
            isnoadmintoshow: true,
          },
          component: () => import("../views/role/role.vue"),
        },
        {
          path: "/role/add",
          name: "role_add",
          meta: {
            auth: true,
            screen_code: "CREATE_MASTERS",
            page_code: "SCN_ROLE",
            page_type: "add_screen",
          },
          component: () => import("../views/role/role_add.vue"),
        },
        {
          path: "/role/edit/:id",
          name: "role_edit",
          meta: {
            auth: true,
            screen_code: "CREATE_MASTERS",
            page_code: "SCN_ROLE",
            page_type: "edit_screen",
          },
          component: () => import("../views/role/role_edit.vue"),
        },
        // holiday
        {
          path: "/holiday",
          name: "holiday",
          meta: {
            auth: true,
            screen_code: "CREATE_MASTERS",
            page_code: "SCN_HOLIDAY",
            page_type: "landing",
          },
          component: () => import("../views/holiday/holiday.vue"),
        },
        {
          path: "/holiday/add",
          name: "holiday_add",
          meta: {
            auth: true,
            screen_code: "CREATE_MASTERS",
            page_code: "SCN_HOLIDAY",
            page_type: "add_screen",
          },
          component: () => import("../views/holiday/holiday_add.vue"),
        },
        {
          path: "/holiday/edit/:id",
          name: "holiday_edit",
          meta: {
            auth: true,
            screen_code: "CREATE_MASTERS",
            page_code: "SCN_HOLIDAY",
            page_type: "edit_screen",
          },
          component: () => import("../views/holiday/holiday_edit.vue"),
        },
        {
          path: "/slab",
          name: "slab",
          meta: {
            auth: true,
            screen_code: "CREATE_MASTERS",
            page_code: "SCN_WEEKOFF",
            page_type: "landing",
          },
          component: () => import("../views/masters/slab.vue"),
        },
        {
          path: "/slab/add",
          name: "slabAdd",
          meta: {
            auth: true,
            screen_code: "CREATE_MASTERS",
            page_code: "SCN_WEEKOFF",
            page_type: "landing",
          },
          component: () => import("../views/masters/slab_add.vue"),
        },
        {
          path: "/designation_setting",
          name: "designation_setting",
          meta: {
            auth: true,
            screen_code: "SCN_WALKINS",
            page_code: "SCN_WALKINS",
            page_type: "add_screen",
          },
          component: () => import("../views/masters/walkin_setting.vue"),
        },
        {
          path: "/designation_setting_add",
          name: "designation_setting_add",
          meta: {
            auth: true,
            screen_code: "SCN_WALKINS",
            page_code: "SCN_WALKINS",
            page_type: "add_screen",
          },
          component: () => import("../views/masters/designation_setting.vue"),
        },
        {
          path: "/sales_landing",
          name: "sales_landing",
          meta: {
            auth: true,
            screen_code: "SCN_WALKINS",
            page_code: "SCN_WALKINS",
            page_type: "add_screen",
          },
          component: () => import("../views/masters/sales_landing.vue"),
        },
        {
          path: "/sales_setting",
          name: "sales_setting",
          meta: {
            auth: true,
            screen_code: "SCN_WALKINS",
            page_code: "SCN_WALKINS",
            page_type: "add_screen",
          },
          component: () => import("../views/masters/sales_setting.vue"),
        },
        {
          path: "/slab/edit/:id",
          name: "slabEdit",
          meta: {
            auth: true,
            screen_code: "CREATE_MASTERS",
            page_code: "SCN_WEEKOFF",
            page_type: "landing",
          },
          component: () => import("../views/masters/slab_edit.vue"),
        },
        {
          path: "/slab/ticket/edit/:id",
          name: "slabtktEdit",
          meta: {
            auth: true,
            screen_code: "CREATE_MASTERS",
            page_code: "SCN_WEEKOFF",
            page_type: "landing",
          },
          component: () => import("../views/masters/slabticket_edit.vue"),
        },
        {
          path: "/slab/ticket/add",
          name: "slabtktAdd",
          meta: {
            auth: true,
            screen_code: "CREATE_MASTERS",
            page_code: "SCN_WEEKOFF",
            page_type: "landing",
          },
          component: () => import("../views/masters/slabticket_add.vue"),
        },
        {
          path: "/SlaScore",
          name: "SlaScore",
          meta: {
            auth: true,
            screen_code: "CREATE_CHK",
            page_code: "SCN_TKT_TICKETS",
            page_type: "landing",
          },
          component: () => import("../views/tickets/Reports/Sla_score.vue"),
        },
        {
          path: "/weekoff",
          name: "WeekOff",
          meta: {
            auth: true,
            screen_code: "CREATE_MASTERS",
            page_code: "SCN_WEEKOFF",
            page_type: "landing",
          },
          component: () => import("../views/masters/weekoff.vue"),
        },
        {
          path: "/weekoff/add",
          name: "AddWeekOff",
          meta: {
            auth: true,
            screen_code: "CREATE_MASTERS",
            page_code: "SCN_WEEKOFF",
            page_type: "add_screen",
          },
          component: () => import("../views/masters/weekoff_add.vue"),
        },
        {
          path: "/weekoff/edit/:id",
          name: "EditWeekOff",
          meta: {
            auth: true,
            screen_code: "CREATE_MASTERS",
            page_code: "SCN_WEEKOFF",
            page_type: "edit_screen",
          },
          component: () => import("../views/masters/weekoff_edit.vue"),
        },

        {
          path: "/org_chart",
          name: "org_chart",
          meta: {
            auth: true,
            screen_code: "CREATE_MASTERS",
            page_code: "SCN_ORG_CHART",
            page_type: "landing",
          },
          component: () => import("../views/org_chart.vue"),
        },
        {
          path: "/org_chart_new",
          name: "org_chart_new",
          meta: {
            auth: true,
            screen_code: "CREATE_MASTERS",
            page_code: "SCN_ORG_CHART",
            page_type: "landing",
          },
          component: () => import("../views/org_chart_new.vue"),
        },
        {
          path: "/organization_chart",
          name: "organization_chart",
          meta: {
            auth: true,
            screen_code: "CREATE_MASTERS",
            page_code: "SCN_ORG_CHART",
            page_type: "landing",
          },
          component: () => import("../views/organization_chart.vue"),
        },
        {
          path: "/kpi_bots",
          name: "kpi_bots",
          meta: {
            auth: true,
            screen_code: "SCN_ANALYTICS",
            page_code: "SCN_ANALYTICS",
            page_type: "landing",
          },
          component: () => import("../views/kpi_bots.vue"),
        },
        {
          path: "/sales_dashboard",
          name: "sales_dashboard",
          meta: {
            auth: true,
            screen_code: "SCN_SALES_DASHBOARD",
            page_code: "SCN_SALES_DASHBOARD",
            page_type: "landing",
          },
          component: () => import("../views/sales_dashboard.vue"),
        },
        {
          path: "/meetings",
          name: "meetings",
          meta: {
            auth: true,
            screen_code: "CREATE_MASTERS",
            page_code: "SCN_ORG_CHART",
            page_type: "landing",
          },
          component: () => import("../views/meeting_statutory.vue"),
        },
        {
          path: "/kpi/mapping",
          name: "kpi_mapping",
          meta: {
            auth: true,
            screen_code: "CREATE_MASTERS",
            page_code: "SCN_ORG_CHART",
            page_type: "landing",
          },
          component: () => import("../views/kpiMapping/kpi_mapping.vue"),
        },
        {
          path: "/kpi/push",
          name: "kpi_push",
          meta: {
            auth: true,
            screen_code: "CREATE_MASTERS",
            page_code: "SCN_ORG_CHART",
            page_type: "landing",
          },
          component: () => import("../views/kpiMapping/kpi_push.vue"),
        },
        {
          path: "appraisal",
          name: "Appraisal",
          meta: {
            auth: true,
            screen_code: "CREATE_MASTERS",
            isnoadmintoshow: true,
          },
          component: () => import("../views/masters/Appraisal.vue"),
        },
        {
          path: "/appraisal_add",
          name: "appraisal",
          meta: {
            auth: true,
            screen_code: "CREATE_MASTERS",
            page_code: "SCN_APPRAISAL_SET_APPRAISAL_CYCLE",
            isnoadmintoshow: true,
          },
          component: () => import("../views/masters/AddAppraisal.vue"),
        },
        {
          path: "/My_profile",
          name: "My_profile",
          meta: {
            auth: true,
            screen_code: "CREATE_MASTERS",
            isnoadmintoshow: true,
          },
          component: () => import("../views/My_Profile.vue"),
        },
        {
          path: "/Calendar",
          name: "Calendar",
          meta: {
            auth: true,
            screen_code: "CREATE_MASTERS",
            isnoadmintoshow: true,
          },
          component: () => import("../views/Calendar.vue"),
        },

        {
          path: "/appraisal_launch",
          name: "appraisal_launch",
          meta: {
            auth: true,
            screen_code: "CREATE_MASTERS",
            page_code: "SCN_APPRAISAL_VIEW_APPRAISAL",
            isnoadmintoshow: true,
          },
          component: () => import("../views/masters/AppraisalLaunch.vue"),
        },
        {
          path: "/appraisal_launch/editappraisal",
          name: "appraisal_launch_editappraisal",
          meta: {
            auth: true,
            screen_code: "CREATE_MASTERS",
            isnoadmintoshow: true,
          },
          component: () => import("../views/masters/editappraisal.vue"),
        },
        {
          path: "/kpiroles",
          name: "kpiroles",
          meta: {
            auth: true,
            iscommontoshow: true,
          },
          component: () => import("../views/kpiroles.vue"),
        },
        {
          path: "/templatelibrary",
          name: "templatelibrary",
          meta: {
            auth: true,
            screen_code: "CREATE_MASTERS",
          },
          component: () =>
            import(
              "../views/master_checklist/checklist_typ/templatelibrary.vue"
            ),
        },
        {
          path: "/kpi_additions",
          name: "kpi_additions",
          meta: {
            auth: true,
            screen_code: "CREATE_MASTERS",
            page_code: "SCN_PMS_KPI_ADDITION",
            page_type: "landing",
          },
          component: () => import("../views/kpilist/kpi_additions.vue"),
        },
        {
          path: "/kpi_impact_landing",
          name: "kpi_impact",
          meta: {
            auth: true,
            screen_code: "CREATE_MASTERS",
            page_code: "SCN_KPI_IMPACT_SETUP",
            page_type: "landing",
          },
          component: () => import("../views/kpilist/kpi_impact_landing.vue"),
        },
        {
          path: "/kpi_impact_detailed_view",
          name: "kpi_impact_detailed_view",
          meta: {
            auth: true,
            screen_code: "CREATE_MASTERS",
            page_code: "SCN_KPI_IMPACT_SETUP",
            page_type: "landing",
          },
          component: () => import("../views/kpilist/kpi_impact_detailed_view.vue"),
        },
        {
          path: "/kpi_impact_chart_percentage_view",
          name: "kpi_impact_chart_percentage_view",
          meta: {
            auth: true,
            screen_code: "CREATE_MASTERS",
            page_code: "SCN_KPI_IMPACT_SETUP",
            page_type: "landing",
          },
          component: () => import("../views/kpilist/kpi_impact_chart_percentage_view.vue"),
        },
        {
          path: "/chart_of_accounts",
          name: "Chart of Accounts",
          meta: {
            auth: true,
            screen_code: "CREATE_MASTERS",
            page_code: "SCN_CHART_OF_ACCOUNTS",
            page_type: "landing",
          },
          component: () => import("../views/kpilist/chart_of_accounts.vue"),
        },
        {
          path: "/kpi_impact_add",
          name: "kpi_impact_add",
          meta: {
            auth: true,
            screen_code: "CREATE_MASTERS",
            page_code: "SCN_KPI_IMPACT_SETUP",
            page_type: "landing",
          },
          component: () => import("../views/kpilist/kpi_impact_add.vue"),
        },
        {
          path: "/kpi_impact_tree",
          name: "kpi_impact_tree",
          meta: {
            auth: true,
            screen_code: "CREATE_MASTERS",
            page_code: "SCN_KPI_IMPACT_SETUP",
            page_type: "landing",
          },
          component: () => import("../views/kpilist/kpi_impact_tree.vue"),
        },
        {
          path: "/kpi_impact_tree_new",
          name: "kpi_impact_tree_new",
          meta: {
            auth: true,
            screen_code: "CREATE_MASTERS",
            page_code: "SCN_KPI_IMPACT_SETUP",
            page_type: "landing",
          },
          component: () => import("../views/kpilist/kpi_impact_tree_new.vue"),
        },
        {
          path: "/kpi_impact_edit/:id",
          name: "kpi_impact_edit",
          meta: {
            auth: true,
            screen_code: "CREATE_MASTERS",
            page_code: "SCN_KPI_IMPACT_SETUP",
            page_type: "landing",
          },
          component: () => import("../views/kpilist/kpi_impact_edit.vue"),
        },
        {
          path: "/kpi_additions_edit/:id",
          name: "kpi_additions_edit",
          meta: {
            auth: true,
            screen_code: "CREATE_MASTERS",
            page_code: "SCN_PMS_KPI_ADDITION",
            page_type: "landing",
          },
          component: () =>
            import("../views/kpilist/kpi_addition_edit_tenant.vue"),
        },
        {
          path: "/kpi_addition_tenant",
          name: "kpi_addition_tenant",
          meta: {
            auth: true,
            screen_code: "CREATE_MASTERS",
            page_code: "SCN_PMS_KPI_ADDITION",
            page_type: "landing",
          },
          component: () => import("../views/kpilist/kpi_addition_tenent.vue"),
        },
        {
          path: "/kpi_settings",
          name: "kpi_settings",
          meta: {
            auth: true,
            screen_code: "CREATE_MASTERS",
            page_code: "SCN_KPI_SETTING",
            page_type: "add_screen",
          },
          component: () => import("../views/kpilist/kpi_settings_add.vue"),
        },
        {
          path: "/kpi_settings_landing",
          name: "kpi_settings_landing",
          meta: {
            auth: true,
            screen_code: "CREATE_MASTERS",
            page_code: "SCN_KPI_SETTING",
            page_type: "landing",
          },
          component: () => import("../views/kpilist/kpi_settings_landing.vue"),
        },
        {
          path: "/kpi_settings_edit/:id",
          name: "kpi_settings_edit",
          meta: {
            auth: true,
            screen_code: "CREATE_MASTERS",
            page_code: "SCN_KPI_SETTING",
            page_type: "edit_screen",
          },
          component: () =>
            import("../views/kpilist/kpi_settings_edit.vue"),
        },
        {
          path: "/employee_kpi",
          name: "employee_kpi",
          meta: {
            auth: true,
            screen_code: "CREATE_MASTERS",
            page_code: "SCN_PMS_EMPLOYEE_KPI",
            page_type: "landing",
          },
          component: () => import("../views/kpilist/employee_kpi.vue"),
        },
        // {
        //     path: '/assign_kpi',
        //     name: 'assign_kpi',
        //     meta: {
        //         auth: true,
        //         screen_code: 'CREATE_MASTERS'
        //     },
        //     component: () => import ('../views/kpilist/assign_kpi.vue')
        // },
        {
          path: "/set_weightage",
          name: "set_weightage",
          meta: {
            auth: true,
            screen_code: "CREATE_MASTERS",
            page_code: "SCN_PMS_SET_WEIGHTAGE",
            page_type: "landing",
          },
          component: () =>
            import("../views/kpilist/set_weightage/set_weightage.vue"),
        },
        {
          path: "/set_weightage_add",
          name: "set_weightage_add",
          meta: {
            auth: true,
            screen_code: "CREATE_MASTERS",
            page_code: "SCN_PMS_SET_WEIGHTAGE",
            page_type: "add_screen",
          },
          component: () =>
            import("../views/kpilist/set_weightage/set_weightage_add.vue"),
        },
        {
          path: "/profile",
          name: "profile",
          meta: {
            auth: true,
            isnoadmintoshow: true,
            screen_code: "CREATE_MASTERS",
            page_code: "SCN_PMS_EMPLOYEE_KPI",
            page_type: "landing",
          },
          component: () => import("../views/kpilist/appraisal/landing.vue"),
        },
        {
          path: "/tkt_service_category",
          name: "tkt_service_category",
          meta: {
            auth: true,
            iscommontoshow: true,
          },
          component: () => import("../views/masters/tkt_service_category.vue"),
        },
        {
          path: "/process_mstr",
          name: "process_mstr",
          meta: {
            auth: true,
            screen_code: "CREATE_MASTERS",
            page_code: "SCN_PROCESS",
            page_type: "add_screen",
          },
          component: () => import("../views/masters/process_mstr.vue"),
        },
        {
          path: "/set_target",
          name: "set_target",
          meta: {
            auth: true,
            screen_code: "CREATE_MASTERS",
            page_code: "SCN_PMS_SET_TARGET",
            page_type: "landing",
          },
          component: () =>
            import(
              "../views/kpilist/Targets_Actuals/TargetsOnly/set_target.vue"
            ),
        },
        {
          path: "/incentives",
          name: "Incentives",
          meta: {
            auth: true,
            screen_code: "CREATE_MASTERS",
            page_code: "SCN_INCENTIVE_POLICY",
            page_type: "landing",
          },
          component: () =>
            import("../views/kpilist/incentive/incentive_landing.vue"),
        },
        {
          path: "/set_incentive",
          name: "Set Incentives",
          meta: {
            auth: true,
            screen_code: "CREATE_MASTERS",
            page_code: "SCN_INCENTIVE_POLICY",
            page_type: "landing",
          },
          component: () =>
            import("../views/kpilist/incentive/set_incentive_main.vue"),
        },
        {
          path: "/set_frequency",
          name: "set_frequency",
          meta: {
            auth: true,
            screen_code: "CREATE_MASTERS",
            page_code: "SCN_INCENTIVE_SET_FREQUENCY",
            page_type: "landing",
          },
          component: () =>
            import("../views/kpilist/set_frequency/set_frequency_landing.vue"),
        },
        {
          path: "/set_frequency_main",
          name: "set_frequency_main",
          meta: {
            auth: true,
            screen_code: "CREATE_MASTERS",
            page_code: "SCN_INCENTIVE_SET_FREQUENCY",
            page_type: "landing",
          },
          component: () =>
            import("../views/kpilist/set_frequency/set_frequency_main.vue"),
        },
        {
          path: "/performance_rating",
          name: "performance_rating",
          meta: {
            auth: true,
            screen_code: "CREATE_MASTERS",
            page_code: "SCN_PERFORMANCE_RATING",
            page_type: "landing",
          },
          component: () => import("../views/masters/performance_rating.vue"),
        },
        {
          path: "/base_performance",
          name: "base_performance",
          meta: {
            auth: true,
            screen_code: "CREATE_MASTERS",
            page_code: "SCN_PERFORMANCE_RATING",
            page_type: "landing",
          },
          component: () => import("../views/masters/base_performance.vue"),
        },
        {
          path: "/base_performance/add",
          name: "base_performance_add",
          meta: {
            auth: true,
            screen_code: "CREATE_MASTERS",
            page_code: "SCN_PERFORMANCE_RATING",
            page_type: "landing",
          },
          component: () => import("../views/masters/base_performance_add.vue"),
        },
        {
          path: "/base_performance/edit/:id",
          name: "base_performance_edit",
          meta: {
            auth: true,
            screen_code: "CREATE_MASTERS",
            page_code: "SCN_PERFORMANCE_RATING",
            page_type: "landing",
          },
          component: () => import("../views/masters/base_performance_edit.vue"),
        },
        {
          path: "/module_rights/:id",
          name: "module_rights",
          meta: {
            auth: true,
            screen_code: "CREATE_MASTERS",
            page_code: "SCN_ROLE",
            page_type: "edit_screen",
            isnoadmintoshow: true,
          },
          component: () => import("../views/role/module_rights.vue"),
        },
        {
          path: "/screen_rights/:id",
          name: "screen_rights",
          meta: {
            auth: true,
            screen_code: "CREATE_MASTERS",
            page_code: "SCN_ROLE",
            page_type: "edit_screen",
            isnoadmintoshow: true,
          },
          component: () => import("../views/role/screen_rights.vue"),
        },
        {
          path: "/idp",
          name: "Idp",
          meta: {
            auth: true,
            screen_code: "CREATE_MASTERS",
            page_code: "SCN_SUB_CHANNEL",
            page_type: "landing",
          },
          component: () => import("../views/idp.vue"),
        },
        {
          path: "/tbl_kpi_additions",
          name: "tbl_kpi_additions",
          meta: {
            auth: true,
            screen_code: "CREATE_MASTERS",
            page_code: "SCN_SUB_CHANNEL",
            page_type: "landing",
          },
          component: () => import("../views/kpilist/kpi_additions.vue"),
        },
        {
          path: "/actual_updation",
          name: "actual_updation",
          meta: {
            auth: true,
            screen_code: "CREATE_MASTERS",
            page_code: "SCN_PMS_SET_TARGET",
            page_type: "landing",
          },
          component: () =>
            import("../views/kpilist/Targets_Actuals/actual_updation.vue"),
        },
        {
          path: "/admin_actual_updation",
          name: "admin_actual_updation",
          meta: {
            auth: true,
            screen_code: "CREATE_MASTERS",
            page_code: "SCN_PMS_SET_TARGET",
            page_type: "landing",
          },
          component: () =>
            import(
              "../views/kpilist/Targets_Actuals/admin_actual_updation.vue"
            ),
        },
        {
          path: "/actual_updation_main",
          name: "actual_updation_main",
          meta: {
            auth: true,
            screen_code: "CREATE_MASTERS",
            page_code: "SCN_PMS_SET_TARGET",
            page_type: "landing",
          },
          component: () =>
            import(
              "../views/kpilist/Targets_Actuals/ActualsOnly/actual_updation_main.vue"
            ),
        },
        {
          path: "/set_target_ViewLanding",
          name: "set_target_ViewLanding",
          meta: {
            auth: true,
            screen_code: "CREATE_MASTERS",
            page_code: "SCN_PMS_SET_TARGET",
            page_type: "landing",
          },
          component: () =>
            import(
              "../views/kpilist/Targets_Actuals/TargetsOnly/set_target_ViewLanding.vue"
            ),
        },
        {
          path: "/actual_ViewLanding",
          name: "actual_ViewLanding",
          meta: {
            auth: true,
            screen_code: "CREATE_MASTERS",
            page_code: "SCN_PMS_SET_TARGET",
            page_type: "landing",
          },
          component: () =>
            import(
              "../views/kpilist/Targets_Actuals/ActualsOnly/actual_ViewLanding.vue"
            ),
        },
        {
          path: "/kpi_role_ack_report",
          name: "Acknowledgement Report",
          meta: {
            auth: true,
            screen_code: "CREATE_MASTERS",
            page_code: "SCN_PMS_KPI_ROLES_REPORT",
            page_type: "landing",
        },
        component: () =>
          import(
            "../views/role/kpi_role_ack_report.vue"
          ),
        },
        {
          path: "/Appraisaltrend",
          name: "Appraisaltrend",
          meta: {
            auth: true,
            screen_code: "CREATE_MASTERS",
            page_code: "SCN_APPRISALTRENDREPORT",
            page_type: "landing",
          },
          component: () => import("../views/reports/Appraisal_Trend.vue"),
        },
        {
          path: "/appraisal_trend_chart",
          name: "appraisal_trend_chart",
          meta: {
            auth: true,
            // iscommontoshow: true,
            isnoadmintoshow: true,
          },
          component: () => import("../views/reports/appraisal_trend_chart.vue"),
        },
        {
          path: "/targetandactual",
          name: "targetandactual",
          meta: {
            auth: true,
            screen_code: "CREATE_MASTERS",
            page_code: "SCN_APPRISALTRENDREPORT",
            page_type: "landing",
          },
          component: () => import("../views/reports/targetandactual.vue"),
        },
        {
          path: "/reportees_rating_updates",
          name: "reportees_rating_updates",
          meta: {
            auth: true,
            screen_code: "CREATE_MASTERS",
            page_code: "SCN_PMS_SET_TARGET",
            page_type: "landing",
          },
          component: () =>
            import("../views/kpilist/appraisal/reportees_rating_updates.vue"),
        },
        {
          path: "/reportees_rating_updates/scorecard",
          name: "scorecardUpadateReportees",
          meta: {
            auth: true,
            screen_code: "CREATE_MASTERS",
            page_code: "SCN_PMS_SET_TARGET",
            page_type: "landing",
          },
          component: () =>
            import("../views/kpilist/appraisal/scorecardUpadateReportees.vue"),
        },
        {
          path: "/admin_landing",
          name: "admin_landing",
          meta: {
            auth: true,
            iscommontoshow: true,
          },
          component: () => import("../views/admin_landing.vue"),
        },
        {
          path: "/user_type",
          name: "user_type",
          meta: {
            auth: true,
            iscommontoshow: true,
          },
          component: () => import("../views/user_type.vue"),
        },
        {
          path: "/Common_user",
          name: "Common_user",
          meta: {
            auth: true,
            iscommontoshow: true,
          },
          component: () => import("../views/Common_user.vue"),
        },
        {
          path: "/Common_user_add/add",
          name: "Common_user_add",
          meta: {
            auth: true,
            iscommontoshow: true,
          },
          component: () => import("../views/Common_user_add.vue"),
        },
        {
          path: "/Common_user_edit/:id",
          name: "Common_user_edit",
          meta: {
            auth: true,
            iscommontoshow: true,
          },
          component: () => import("../views/Common_user_edit.vue"),
        },
        {
          path: "/common_rights",
          name: "common_rights",
          meta: {
            auth: true,
            iscommontoshow: true,
          },
          component: () => import("../views/common_rights.vue"),
        },
        {
          path: "/partner",
          name: "partner",
          meta: {
            auth: true,
            iscommontoshow: true,
          },
          component: () => import("../views/partner.vue"),
        },
        {
          path: "/dashboard_oro",
          name: "Dashboard oro",
          meta: {
            auth: true,
            iscommontoshow: true,
          },
          component: () => import("../dashboard_oro.vue"),
        },
        {
          path: "/oasisdashboard",
          name: "oasisdashboard",
          meta: {
            auth: true,
            iscommontoshow: true,
          },
          component: () => import("../oasisdashboard.vue"),
        },
        {
          path: "/dashboard_landing",
          name: "Dashboard ",
          meta: {
            auth: true,
            iscommontoshow: true,
            screen_code: "CREATE_MASTERS",
            page_code: "SCN_DASHBOARD",
            page_type: "landing",
          },
          component: () => import("../dashboard_landing.vue"),
        },
        {
          path: "/survey",
          name: "Survey",
          meta: {
            auth: true,
            screen_code: "CREATE_MASTERS",
            page_code: "SCN_BRANCH",
            page_type: "landing",
          },
          component: () => import("../views/masters/survey.vue"),
        },
        {
          path: "/survey_section/:id",
          name: "survey_section",
          meta: {
            auth: true,
            screen_code: "CREATE_MASTERS",
            page_code: "SCN_BRANCH",
            page_type: "landing",
          },
          component: () => import("../views/masters/survey_section.vue"),
        },
        {
          path: "/survey_reports",
          name: "Survey Reports",
          meta: {
            auth: true,
            screen_code: "CREATE_MASTERS",
            page_code: "SCN_BRANCH",
            page_type: "landing",
          },
          component: () => import("../views/masters/survey_reports.vue"),
        },
        {
          path:
            "/survey_answer_customer_result/:prefix/:survey_id/:entity/:email/:name",
          name: "survey_answer_customer_result",
          meta: {
            auth: false,
            screen_code: "TECTALIK_SURVEY",
            page_code: "SCN_SURVEY",
            page_type: "landing",
            isnoadmintoshow: false,
          },
          component: () =>
            import("../views/masters/survey_answer_customer_result.vue"),
        },
        {
          path: "/survey_results/:id/:type",
          name: "SurveyResult",
          meta: {
            auth: true,
            screen_code: "CREATE_MASTERS",
            page_code: "SCN_BRANCH",
            page_type: "landing",
          },
          component: () => import("../views/masters/survey_view_results.vue"),
        },
        {
          path: "/customer",
          name: "Customer",
          meta: {
            auth: true,
            screen_code: "SCN_VISIT_CUSTOMER",
            page_code: "SCN_VISIT_CUSTOMER",
            page_type: "landing",
          },
          component: () => import("../views/masters/customer/customer.vue"),
        },
        {
          path: "/customer/add/:value",
          name: "Customer Add",
          meta: {
            auth: true,
            screen_code: "SCN_VISIT_CUSTOMER",
            page_code: "SCN_VISIT_CUSTOMER",
            page_type: "landing",
          },
          component: () => import("../views/masters/customer/customer_add.vue"),
        },
        {
          path: "/customer/edit/:id",
          name: "Customer Edit",
          meta: {
            auth: true,
            screen_code: "SCN_VISIT_CUSTOMER",
            page_code: "SCN_VISIT_CUSTOMER",
            page_type: "landing",
          },
          component: () =>
            import("../views/masters/customer/customer_edit.vue"),
        },
        {
          path: "/customer/target",
          name: "Customer Target",
          meta: {
            auth: true,
            screen_code: "SCN_VISIT_CUSTOMER",
            page_code: "SCN_VISIT_CUSTOMER",
            page_type: "landing",
          },
          component: () =>
            import("../views/masters/customer/customer_target.vue"),
        },
        {
          path: "/customer/target/report",
          name: "Customer Report",
          meta: {
            auth: true,
            screen_code: "SCN_VISIT_CUSTOMER",
            page_code: "SCN_VISIT_CUSTOMER",
            page_type: "landing",
          },
          component: () =>
            import("../views/masters/customer/customer_target_report.vue"),
        },
        {
          path: "/survey/add",
          name: "survey_add",
          meta: {
            auth: true,
            screen_code: "CREATE_MASTERS",
            page_code: "SCN_SURVEY",
            page_type: "add_screen",
          },
          component: () => import("../views/masters/survey_add.vue"),
        },
        {
          path: "/survey_question/:id/:type",
          name: "survey_question",
          meta: {
            auth: true,
            screen_code: "CREATE_MASTERS",
            page_code: "SCN_BRANCH",
            page_type: "landing",
          },
          component: () => import("../views/masters/survey_question.vue"),
        },
        {
          path: "/survey_question/add/:id/:type",
          name: "survey_question_add",
          meta: {
            auth: true,
            screen_code: "CREATE_MASTERS",
            page_code: "SCN_BRANCH",
            page_type: "add_screen",
          },
          component: () => import("../views/masters/survey_question_add.vue"),
        },
        {
          path: "/survey_question/edit/:id/:type",
          name: "survey_question_edit",
          meta: {
            auth: true,
            screen_code: "CREATE_MASTERS",
            page_code: "SCN_BRANCH",
            page_type: "add_screen",
          },
          component: () => import("../views/masters/survey_question_edit.vue"),
        },
        {
          path: "/survey/edit/:id",
          name: "survey_edit",
          meta: {
            auth: true,
            screen_code: "CREATE_MASTERS",
            page_code: "SCN_BRANCH",
            page_type: "add_screen",
          },
          component: () => import("../views/masters/survey_edit.vue"),
        },
        {
          path: "/customers/:id/:type/:frequency",
          name: "Customers",
          meta: {
            auth: true,
            screen_code: "CREATE_MASTERS",
            page_code: "SCN_BRANCH",
            page_type: "add_screen",
          },
          component: () => import("../views/masters/customers.vue"),
        },

        {
          path: "/survey_customer_upload",
          name: "survey_customer_upload",
          meta: {
            auth: true,
            screen_code: "CREATE_MASTERS",
            page_code: "SCN_BRANCH",
            page_type: "landing",
          },
          component: () =>
            import("../views/masters/survey_customer_upload.vue"),
        },

        {
          path: "/survey_answer/add",
          name: "survey_answer_add",
          meta: {
            auth: true,
            screen_code: "CREATE_MASTERS",
            page_code: "SCN_BRANCH",
            page_type: "add_screen",
          },
          component: () => import("../views/masters/survey_answer_add.vue"),
        },

        {
          path: "/survey_answer_result/:id/",
          name: "survey_answer_result",
          meta: {
            auth: true,
            screen_code: "CREATE_MASTERS",
            page_code: "SCN_BRANCH",
            page_type: "landing",
          },
          component: () => import("../views/masters/survey_answer_result.vue"),
        },
        {
          path:
            "/survey_answer_employee_result/:userId/:surveyId/:entityId/:prefix/:email/:name",
          name: "survey_answer_employee_result",
          meta: {
            auth: false,
            screen_code: "TECTALIK_SURVEY",
            page_code: "SCN_SURVEY",
            page_type: "landing",
          },
          component: () =>
            import("../views/masters/survey_answer_result_employee.vue"),
        },
        {
          path: "/sur_employee/:id/:frequency",
          name: "sur_employee",
          meta: {
            auth: true,
            screen_code: "CREATE_MASTERS",
            page_code: "SCN_BRANCH",
            page_type: "add_screen",
          },
          component: () => import("../views/masters/sur_employee.vue"),
        },
        {
          path: "/appraisal_launch/period/add",
          name: "/appraisal_launch/period/add",
          meta: {
            auth: true,
            screen_code: "CREATE_MASTERS",
            page_code: "SCN_PMS_SET_TARGET",
            page_type: "landing",
          },
          component: () => import("../views/masters/appraisal_period_add.vue"),
        },
        // employee_scorecard_landing
        {
          path: "/employee_scorecard",
          name: "employee_scorecard_landing",
          meta: {
            auth: true,
            screen_code: "CREATE_MASTERS",
            page_code: "SCN_PMS_SET_TARGET",
            page_type: "landing",
          },
          component: () =>
            import(
              "../views/kpilist/employee_scorecard/employee_scorecard_landing.vue"
            ),
        },
        {
          path: "/non_appraisals_based_incentive_landing",
          name: "non_appraisals_based_incentive_landing",
          meta: {
            auth: true,
            screen_code: "CREATE_MASTERS",
            page_code: "SCN_PMS_SET_TARGET",
            page_type: "landing",
          },
          component: () =>
            import(
              "../views/kpilist/non_appraisals_based_incentive/non_appraisals_inc_landing.vue"
            ),
        },

        {
          path: "/admin_desig_landing",
          name: "admin_desig_landing",
          meta: {
            auth: true,
            iscommontoshow: true,
          },
          component: () =>
            import("../views/AdminMasters/Designation/desig_landing.vue"),
        },
        {
          path: "/admin_desig_landing_add",
          name: "admin_desig_landing_add",
          meta: {
            auth: true,
            iscommontoshow: true,
          },
          component: () =>
            import("../views/AdminMasters/Designation/desig_landing_add.vue"),
        },

        {
          path: "/admin_dept_landing",
          name: "admin_dept_landing",
          meta: {
            auth: true,
            iscommontoshow: true,
          },
          component: () =>
            import("../views/AdminMasters/Department/dept_landing.vue"),
        },
        {
          path: "/admin_dept_landing_add",
          name: "admin_dept_landing_add",
          meta: {
            auth: true,
            iscommontoshow: true,
          },
          component: () =>
            import("../views/AdminMasters/Department/dept_landing_add.vue"),
        },
        {
          path: "/admin_function_landing",
          name: "admin_function_landing",
          meta: {
            auth: true,
            iscommontoshow: true,
          },
          component: () =>
            import("../views/AdminMasters/Function/function_landing.vue"),
        },
        {
          path: "/admin_function_landing_add",
          name: "admin_function_landing_add",
          meta: {
            auth: true,
            iscommontoshow: true,
          },
          component: () =>
            import("../views/AdminMasters/Function/function_landing_add.vue"),
        },
        {
          path: "/admin_role_assignments_landing",
          name: "admin_role_assignments_landing",
          meta: {
            auth: true,
            iscommontoshow: true,
          },
          component: () =>
            import(
              "../views/AdminMasters/RoleAssignments/role_assignments_landing.vue"
            ),
        },
        {
          path: "/admin_role_assignments_group",
          name: "admin_role_assignments_group",
          meta: {
            auth: true,
            iscommontoshow: true,
          },
          component: () =>
            import(
              "../views/AdminMasters/RoleAssignments/admin_role_assignments_group.vue"
            ),
        },
        {
          path: "/admin_role_assignments_add",
          name: "admin_role_assignments_add",
          meta: {
            auth: true,
            iscommontoshow: true,
            isnoadmintoshow: true,
          },
          component: () =>
            import(
              "../views/AdminMasters/RoleAssignments/role_assignments_add.vue"
            ),
        },
        {
          path: "/admin_skills_add",
          name: "admin_skills_add",
          meta: {
            auth: true,
            iscommontoshow: true,
            isnoadmintoshow: true,
          },
          component: () =>
            import(
              "../views/AdminMasters/Skills/skills_add.vue"
            ),
        },
        {
          path: "/admin_skills_landing",
          name: "admin_rskills_landing",
          meta: {
            auth: true,
            iscommontoshow: true,
          },
          component: () =>
            import(
              "../views/AdminMasters/Skills/skills_landing.vue"
            ),
        },
        {
          path: "/admin_skills_group",
          name: "admin_skills_group",
          meta: {
            auth: true,
            iscommontoshow: true,
          },
          component: () =>
            import(
              "../views/AdminMasters/Skills/skills_group.vue"
            ),
        },
        {
          path: "/admin_skills_group_landing",
          name: "admin_skills_group_landing",
          meta: {
            auth: true,
            iscommontoshow: true,
          },
          component: () =>
            import("../views/AdminMasters/SkillsGroup/skills_group_landing.vue"),
        },
        {
          path: "/admin_skills_group_landing_add",
          name: "admin_skills_group_landing_add",
          meta: {
            auth: true,
            iscommontoshow: true,
          },
          component: () =>
            import("../views/AdminMasters/SkillsGroup/skills_group_landing_add.vue"),
        },
        {
          path: "/admin_competencies_landing",
          name: "admin_competencies_landing",
          meta: {
            auth: true,
            iscommontoshow: true,
          },
          component: () =>
            import("../views/AdminMasters/Competencies/competencies_landing.vue"),
        },
        {
          path: "/admin_competencies_landing_add",
          name: "admin_competencies_landing_add",
          meta: {
            auth: true,
            iscommontoshow: true,
          },
          component: () =>
            import("../views/AdminMasters/Competencies/competencies_landing_add.vue"),
        },
        {
          path: "/incentive_reports",
          name: "incentive_reports",
          meta: {
            auth: true,
            screen_code: "CREATE_MASTERS",
            page_code: "SCN_INCENTIVE_POLICY",
            page_type: "landing",
          },
          component: () =>
            import(
              "../views/kpilist/appraisal/incentive_reports_wo_payout.vue"
            ),
        },
        {
          path: "/incentive_report",
          name: "incentive_report",
          meta: {
            auth: true,
            screen_code: "CREATE_MASTERS",
            page_code: "SCN_INCENTIVE_POLICY",
            page_type: "landing",
          },
          component: () =>
            import("../views/kpilist/appraisal/incentive_report.vue"),
        },
        {
          path: "/calc_settings",
          name: "calc_settings",
          meta: {
            auth: true,
            screen_code: "SCN_SCORECARD_SETTING",
            page_code: "SCN_SCORECARD_SETTING",
            page_type: "landing",
          },
          component: () => import("../views/masters/calc_settings.vue"),
        },
        {
          path: "/rating_setting",
          name: "rating_setting",
          meta: {
            auth: true,
            screen_code: "CREATE_MASTERS",
            page_code: "SCN_INCENTIVE_POLICY",
            page_type: "landing",
            isnoadmintoshow: true,
          },
          component: () =>
            import("../views/masters/RatingSetting/rating_setting.vue"),
        },
        {
          path: "/amc_cancel_category_mstr",
          name: "amc_cancel_category_mstr",
          meta: {
            auth: true,
            iscommontoshow: true,
            isnoadmintoshow: true,
            screen_code: "SCN_FMS",
            page_code: "SCN_FMS",
            page_type: "landing",
          },
          component: () =>
            import("../views/FMS/FmsMaster/amc_cancel_category_mstr.vue"),
        },
        {
          path: "/amc_cancel_category_mstr/add",
          name: "amc_cancel_category_mstr_add",
          meta: {
            auth: true,
            iscommontoshow: true,
            isnoadmintoshow: true,
            screen_code: "SCN_FMS",
            page_code: "SCN_FMS",
            page_type: "add_screen",
          },
          component: () =>
            import("../views/FMS/FmsMaster/amc_cancel_category_mstradd.vue"),
        },

        {
          path: "/amc_cancel_category_mstr/edit/:id",
          name: "amc_cancel_category_mstr_edit",
          meta: {
            auth: true,
            iscommontoshow: true,
            isnoadmintoshow: true,
            screen_code: "SCN_FMS",
            page_code: "SCN_FMS",
            page_type: "edit_screen",
          },
          component: () =>
            import("../views/FMS/FmsMaster/amc_cancel_category_mstredit.vue"),
        },
        {
          path: "/amc_type_mstr",
          name: "amc_type_mstr",
          meta: {
            auth: true,
            iscommontoshow: true,
            isnoadmintoshow: true,
            screen_code: "SCN_FMS",
            page_code: "SCN_FMS",
            page_type: "landing",
          },
          component: () => import("../views/FMS/FmsMaster/amc_type_mstr.vue"),
        },
        {
          path: "/amc_type_mstr/add",
          name: "amc_type_mstr_add",
          meta: {
            auth: true,
            iscommontoshow: true,
            isnoadmintoshow: true,
            screen_code: "SCN_FMS",
            page_code: "SCN_FMS",
            page_type: "add_screen",
          },
          component: () =>
            import("../views/FMS/FmsMaster/amc_type_mstradd.vue"),
        },

        {
          path: "/amc_type_mstr/edit/:id",
          name: "amc_type_mstr_edit",
          meta: {
            auth: true,
            iscommontoshow: true,
            isnoadmintoshow: true,
            screen_code: "SCN_FMS",
            page_code: "SCN_FMS",
            page_type: "edit_screen",
          },
          component: () =>
            import("../views/FMS/FmsMaster/amc_type_mstredit.vue"),
        },
        {
          path: "/area_mstr",
          name: "area_mstr",
          meta: {
            auth: true,
            iscommontoshow: true,
            isnoadmintoshow: true,
            screen_code: "SCN_FMS",
            page_code: "SCN_FMS",
            page_type: "landing",
          },
          component: () => import("../views/FMS/FmsMaster/area_mstr.vue"),
        },
        {
          path: "/area_mstr/add",
          name: "area_mstr_add",
          meta: {
            auth: true,
            iscommontoshow: true,
            isnoadmintoshow: true,
            screen_code: "SCN_FMS",
            page_code: "SCN_FMS",
            page_type: "add_screen",
          },
          component: () => import("../views/FMS/FmsMaster/area_mstradd.vue"),
        },

        {
          path: "/area_mstr/edit/:id",
          name: "area_mstr_edit",
          meta: {
            auth: true,
            iscommontoshow: true,
            isnoadmintoshow: true,
            screen_code: "SCN_FMS",
            page_code: "SCN_FMS",
            page_type: "edit_screen",
          },
          component: () => import("../views/FMS/FmsMaster/area_mstredit.vue"),
        },
        {
          path: "/building_type_mstr",
          name: "building_type_mstr",
          meta: {
            auth: true,
            iscommontoshow: true,
            isnoadmintoshow: true,
            screen_code: "SCN_FMS",
            page_code: "SCN_FMS",
            page_type: "landing",
          },
          component: () =>
            import("../views/FMS/FmsMaster/building_type_mstr.vue"),
        },
        {
          path: "/building_type_mstr/add",
          name: "building_type_mstr_add",
          meta: {
            auth: true,
            iscommontoshow: true,
            isnoadmintoshow: true,
            screen_code: "SCN_FMS",
            page_code: "SCN_FMS",
            page_type: "add_screen",
          },
          component: () =>
            import("../views/FMS/FmsMaster/building_type_mstradd.vue"),
        },

        {
          path: "/building_type_mstr/edit/:id",
          name: "building_type_mstr_edit",
          meta: {
            auth: true,
            iscommontoshow: true,
            isnoadmintoshow: true,
            screen_code: "SCN_FMS",
            page_code: "SCN_FMS",
            page_type: "edit_screen",
          },
          component: () =>
            import("../views/FMS/FmsMaster/building_type_mstredit.vue"),
        },
        {
          path: "/caroprtr_make_mstr",
          name: "caroprtr_make_mstr",
          meta: {
            auth: true,
            iscommontoshow: true,
            isnoadmintoshow: true,
            screen_code: "SCN_FMS",
            page_code: "SCN_FMS",
            page_type: "landing",
          },
          component: () =>
            import("../views/FMS/FmsMaster/caroprtr_make_mstr.vue"),
        },
        {
          path: "/caroprtr_make_mstr/add",
          name: "caroprtr_make_mstr_add",
          meta: {
            auth: true,
            iscommontoshow: true,
            isnoadmintoshow: true,
            screen_code: "SCN_FMS",
            page_code: "SCN_FMS",
            page_type: "add_screen",
          },
          component: () =>
            import("../views/FMS/FmsMaster/caroprtr_make_mstradd.vue"),
        },

        {
          path: "/caroprtr_make_mstr/edit/:id",
          name: "caroprtr_make_mstr_edit",
          meta: {
            auth: true,
            iscommontoshow: true,
            isnoadmintoshow: true,
            screen_code: "SCN_FMS",
            page_code: "SCN_FMS",
            page_type: "edit_screen",
          },
          component: () =>
            import("../views/FMS/FmsMaster/caroprtr_make_mstredit.vue"),
        },
        {
          path: "/city_mstr",
          name: "city_mstr",
          meta: {
            auth: true,
            iscommontoshow: true,
            isnoadmintoshow: true,
            screen_code: "SCN_FMS",
            page_code: "SCN_FMS",
            page_type: "landing",
          },
          component: () => import("../views/FMS/FmsMaster/city_mstr.vue"),
        },
        {
          path: "/city_mstr/add",
          name: "city_mstr_add",
          meta: {
            auth: true,
            iscommontoshow: true,
            isnoadmintoshow: true,
            screen_code: "SCN_FMS",
            page_code: "SCN_FMS",
            page_type: "add_screen",
          },
          component: () => import("../views/FMS/FmsMaster/city_mstradd.vue"),
        },

        {
          path: "/city_mstr/edit/:id",
          name: "city_mstr_edit",
          meta: {
            auth: true,
            iscommontoshow: true,
            isnoadmintoshow: true,
            screen_code: "SCN_FMS",
            page_code: "SCN_FMS",
            page_type: "edit_screen",
          },
          component: () => import("../views/FMS/FmsMaster/city_mstredit.vue"),
        },
        {
          path: "/client_type_mstr",
          name: "client_type_mstr",
          meta: {
            auth: true,
            iscommontoshow: true,
            isnoadmintoshow: true,
            screen_code: "SCN_FMS",
            page_code: "SCN_FMS",
            page_type: "landing",
          },
          component: () =>
            import("../views/FMS/FmsMaster/client_type_mstr.vue"),
        },
        {
          path: "/client_type_mstr/add",
          name: "client_type_mstr_add",
          meta: {
            auth: true,
            iscommontoshow: true,
            isnoadmintoshow: true,
            screen_code: "SCN_FMS",
            page_code: "SCN_FMS",
            page_type: "add_screen",
          },
          component: () =>
            import("../views/FMS/FmsMaster/client_type_mstradd.vue"),
        },

        {
          path: "/client_type_mstr/edit/:id",
          name: "client_type_mstr_edit",
          meta: {
            auth: true,
            iscommontoshow: true,
            isnoadmintoshow: true,
            screen_code: "SCN_FMS",
            page_code: "SCN_FMS",
            page_type: "edit_screen",
          },
          component: () =>
            import("../views/FMS/FmsMaster/client_type_mstredit.vue"),
        },
        {
          path: "/client_subtype_mstr",
          name: "client_subtype_mstr",
          meta: {
            auth: true,
            iscommontoshow: true,
            isnoadmintoshow: true,
            screen_code: "SCN_FMS",
            page_code: "SCN_FMS",
            page_type: "landing",
          },
          component: () =>
            import("../views/FMS/FmsMaster/client_subtype_mstr.vue"),
        },
        {
          path: "/client_subtype_mstr/add",
          name: "client_subtype_mstr_add",
          meta: {
            auth: true,
            iscommontoshow: true,
            isnoadmintoshow: true,
            screen_code: "SCN_FMS",
            page_code: "SCN_FMS",
            page_type: "add_screen",
          },
          component: () =>
            import("../views/FMS/FmsMaster/client_subtype_mstradd.vue"),
        },

        {
          path: "/client_subtype_mstr/edit/:id",
          name: "client_subtype_mstr_edit",
          meta: {
            auth: true,
            iscommontoshow: true,
            isnoadmintoshow: true,
            screen_code: "SCN_FMS",
            page_code: "SCN_FMS",
            page_type: "edit_screen",
          },
          component: () =>
            import("../views/FMS/FmsMaster/client_subtype_mstredit.vue"),
        },

        {
          path: "/customer_grade_mstr",
          name: "customer_grade_mstr",
          meta: {
            auth: true,
            iscommontoshow: true,
            isnoadmintoshow: true,
            screen_code: "SCN_FMS",
            page_code: "SCN_FMS",
            page_type: "landing",
          },
          component: () => import("../views/FMS/FmsMaster/CustomerGrade.vue"),
        },
        {
          path: "/customer_grade_add",
          name: "customer_grade_mstr_add",
          meta: {
            auth: true,
            iscommontoshow: true,
            isnoadmintoshow: true,
            screen_code: "SCN_FMS",
            page_code: "SCN_FMS",
            page_type: "add_screen",
          },
          component: () =>
            import("../views/FMS/FmsMaster/CustomerGradeForm.vue"),
        },

        {
          path: "/customer_grade_mstr/edit/:id",
          name: "customer_grade_mstr_edit",
          meta: {
            auth: true,
            iscommontoshow: true,
            isnoadmintoshow: true,
            screen_code: "SCN_FMS",
            page_code: "SCN_FMS",
            page_type: "edit_screen",
          },
          component: () =>
            import("../views/FMS/FmsMaster/CustomerGradeForm.vue"),
        },
        {
          path: "/controller_mstr",
          name: "controller_mstr",
          meta: {
            auth: true,
            iscommontoshow: true,
            isnoadmintoshow: true,
            screen_code: "SCN_FMS",
            page_code: "SCN_FMS",
            page_type: "landing",
          },
          component: () => import("../views/FMS/FmsMaster/controller_mstr.vue"),
        },
        {
          path: "/controller_mstr/add",
          name: "controller_mstr_add",
          meta: {
            auth: true,
            iscommontoshow: true,
            isnoadmintoshow: true,
            screen_code: "SCN_FMS",
            page_code: "SCN_FMS",
            page_type: "add_screen",
          },
          component: () =>
            import("../views/FMS/FmsMaster/controller_mstradd.vue"),
        },

        {
          path: "/controller_mstr/edit/:id",
          name: "controller_mstr_edit",
          meta: {
            auth: true,
            iscommontoshow: true,
            isnoadmintoshow: true,
            screen_code: "SCN_FMS",
            page_code: "SCN_FMS",
            page_type: "edit_screen",
          },
          component: () =>
            import("../views/FMS/FmsMaster/controller_mstredit.vue"),
        },
        {
          path: "/customer_group_mstr",
          name: "customer_group_mstr",
          meta: {
            auth: true,
            iscommontoshow: true,
            isnoadmintoshow: true,
            screen_code: "SCN_FMS",
            page_code: "SCN_FMS",
            page_type: "landing",
          },
          component: () =>
            import("../views/FMS/FmsMaster/customer_group_mstr.vue"),
        },
        {
          path: "/customer_group_mstr/add",
          name: "customer_group_mstr_add",
          meta: {
            auth: true,
            iscommontoshow: true,
            isnoadmintoshow: true,
            screen_code: "SCN_FMS",
            page_code: "SCN_FMS",
            page_type: "add_screen",
          },
          component: () =>
            import("../views/FMS/FmsMaster/customer_group_mstradd.vue"),
        },

        {
          path: "/customer_group_mstr/edit/:id",
          name: "customer_group_mstr_edit",
          meta: {
            auth: true,
            iscommontoshow: true,
            isnoadmintoshow: true,
            screen_code: "SCN_FMS",
            page_code: "SCN_FMS",
            page_type: "edit_screen",
          },
          component: () =>
            import("../views/FMS/FmsMaster/customer_group_mstredit.vue"),
        },
        {
          path: "/expertise_level",
          name: "expertise_level",
          meta: {
            auth: true,
            iscommontoshow: true,
            isnoadmintoshow: true,
            screen_code: "SCN_FMS",
            page_code: "SCN_FMS",
            page_type: "landing",
          },
          component: () => import("../views/FMS/FmsMaster/expertise_level.vue"),
        },
        {
          path: "/expertise_level/add",
          name: "expertise_level_add",
          meta: {
            auth: true,
            iscommontoshow: true,
            isnoadmintoshow: true,
            screen_code: "SCN_FMS",
            page_code: "SCN_FMS",
            page_type: "add_screen",
          },
          component: () =>
            import("../views/FMS/FmsMaster/expertise_leveladd.vue"),
        },

        {
          path: "/expertise_level/edit/:id",
          name: "expertise_level_edit",
          meta: {
            auth: true,
            iscommontoshow: true,
            isnoadmintoshow: true,
            screen_code: "SCN_FMS",
            page_code: "SCN_FMS",
            page_type: "edit_screen",
          },
          component: () =>
            import("../views/FMS/FmsMaster/expertise_leveledit.vue"),
        },
        {
          path: "/landing_display_mstr",
          name: "landing_display_mstr",
          meta: {
            auth: true,
            iscommontoshow: true,
            isnoadmintoshow: true,
            screen_code: "SCN_FMS",
            page_code: "SCN_FMS",
            page_type: "landing",
          },
          component: () =>
            import("../views/FMS/FmsMaster/landing_display_mstr.vue"),
        },
        {
          path: "/landing_display_mstr/add",
          name: "landing_display_mstr_add",
          meta: {
            auth: true,
            iscommontoshow: true,
            isnoadmintoshow: true,
            screen_code: "SCN_FMS",
            page_code: "SCN_FMS",
            page_type: "add_screen",
          },
          component: () =>
            import("../views/FMS/FmsMaster/landing_display_mstradd.vue"),
        },

        {
          path: "/landing_display_mstr/edit/:id",
          name: "landing_display_mstr_edit",
          meta: {
            auth: true,
            iscommontoshow: true,
            isnoadmintoshow: true,
            screen_code: "SCN_FMS",
            page_code: "SCN_FMS",
            page_type: "edit_screen",
          },
          component: () =>
            import("../views/FMS/FmsMaster/landing_display_mstredit.vue"),
        },

        {
          path: "/lift_category_mstr",
          name: "lift_category_mstr",
          meta: {
            auth: true,
            iscommontoshow: true,
            isnoadmintoshow: true,
            screen_code: "SCN_FMS",
            page_code: "SCN_FMS",
            page_type: "landing",
          },
          component: () =>
            import("../views/FMS/FmsMaster/lift_category_mstr.vue"),
        },
        {
          path: "/lift_category_mstr/add",
          name: "lift_category_mstr_add",
          meta: {
            auth: true,
            iscommontoshow: true,
            isnoadmintoshow: true,
            screen_code: "SCN_FMS",
            page_code: "SCN_FMS",
            page_type: "add_screen",
          },
          component: () =>
            import("../views/FMS/FmsMaster/lift_category_mstradd.vue"),
        },

        {
          path: "/lift_category_mstr/edit/:id",
          name: "lift_category_mstr_edit",
          meta: {
            auth: true,
            iscommontoshow: true,
            isnoadmintoshow: true,
            screen_code: "SCN_FMS",
            page_code: "SCN_FMS",
            page_type: "edit_screen",
          },
          component: () =>
            import("../views/FMS/FmsMaster/lift_category_mstredit.vue"),
        },
        {
          path: "/lift_make_mstr",
          name: "lift_make_mstr",
          meta: {
            auth: true,
            iscommontoshow: true,
            isnoadmintoshow: true,
            screen_code: "SCN_FMS",
            page_code: "SCN_FMS",
            page_type: "landing",
          },
          component: () => import("../views/FMS/FmsMaster/lift_make_mstr.vue"),
        },
        {
          path: "/lift_make_mstr/add",
          name: "lift_make_mstr_add",
          meta: {
            auth: true,
            iscommontoshow: true,
            isnoadmintoshow: true,
            screen_code: "SCN_FMS",
            page_code: "SCN_FMS",
            page_type: "add_screen",
          },
          component: () =>
            import("../views/FMS/FmsMaster/lift_make_mstradd.vue"),
        },

        {
          path: "/lift_make_mstr/edit/:id",
          name: "lift_make_mstr_edit",
          meta: {
            auth: true,
            iscommontoshow: true,
            isnoadmintoshow: true,
            screen_code: "SCN_FMS",
            page_code: "SCN_FMS",
            page_type: "edit_screen",
          },
          component: () =>
            import("../views/FMS/FmsMaster/lift_make_mstredit.vue"),
        },
        {
          path: "/lift_type_mstr",
          name: "lift_type_mstr",
          meta: {
            auth: true,
            iscommontoshow: true,
            isnoadmintoshow: true,
            screen_code: "SCN_FMS",
            page_code: "SCN_FMS",
            page_type: "landing",
          },
          component: () => import("../views/FMS/FmsMaster/lift_type_mstr.vue"),
        },
        {
          path: "/lift_type_mstr/add",
          name: "lift_type_mstr_add",
          meta: {
            auth: true,
            iscommontoshow: true,
            isnoadmintoshow: true,
            screen_code: "SCN_FMS",
            page_code: "SCN_FMS",
            page_type: "add_screen",
          },
          component: () =>
            import("../views/FMS/FmsMaster/lift_type_mstradd.vue"),
        },

        {
          path: "/lift_type_mstr/edit/:id",
          name: "lift_type_mstr_edit",
          meta: {
            auth: true,
            iscommontoshow: true,
            isnoadmintoshow: true,
            screen_code: "SCN_FMS",
            page_code: "SCN_FMS",
            page_type: "edit_screen",
          },
          component: () =>
            import("../views/FMS/FmsMaster/lift_type_mstredit.vue"),
        },
        {
          path: "/location_mstr",
          name: "location_mstr",
          meta: {
            auth: true,
            iscommontoshow: true,
            isnoadmintoshow: true,
            screen_code: "SCN_FMS",
            page_code: "SCN_FMS",
            page_type: "landing",
          },
          component: () => import("../views/FMS/FmsMaster/location_mstr.vue"),
        },
        {
          path: "/location_mstr/add",
          name: "location_mstr_add",
          meta: {
            auth: true,
            iscommontoshow: true,
            isnoadmintoshow: true,
            screen_code: "SCN_FMS",
            page_code: "SCN_FMS",
            page_type: "add_screen",
          },
          component: () =>
            import("../views/FMS/FmsMaster/location_mstradd.vue"),
        },

        {
          path: "/location_mstr/edit/:id",
          name: "location_mstr_edit",
          meta: {
            auth: true,
            iscommontoshow: true,
            isnoadmintoshow: true,
            screen_code: "SCN_FMS",
            page_code: "SCN_FMS",
            page_type: "edit_screen",
          },
          component: () =>
            import("../views/FMS/FmsMaster/location_mstredit.vue"),
        },
        {
          path: "/receipt_mode_mstr",
          name: "receipt_mode_mstr",
          meta: {
            auth: true,
            iscommontoshow: true,
            isnoadmintoshow: true,
            screen_code: "SCN_FMS",
            page_code: "SCN_FMS",
            page_type: "landing",
          },
          component: () =>
            import("../views/FMS/FmsMaster/receipt_mode_mstr.vue"),
        },
        {
          path: "/receipt_mode_mstr/add",
          name: "receipt_mode_mstr_add",
          meta: {
            auth: true,
            iscommontoshow: true,
            isnoadmintoshow: true,
            screen_code: "SCN_FMS",
            page_code: "SCN_FMS",
            page_type: "add_screen",
          },
          component: () =>
            import("../views/FMS/FmsMaster/receipt_mode_mstradd.vue"),
        },

        {
          path: "/receipt_mode_mstr/edit/:id",
          name: "receipt_mode_mstr_edit",
          meta: {
            auth: true,
            iscommontoshow: true,
            isnoadmintoshow: true,
            screen_code: "SCN_FMS",
            page_code: "SCN_FMS",
            page_type: "edit_screen",
          },
          component: () =>
            import("../views/FMS/FmsMaster/receipt_mode_mstredit.vue"),
        },
        {
          path: "/refno_type_mstr",
          name: "refno_type_mstr",
          meta: {
            auth: true,
            iscommontoshow: true,
            isnoadmintoshow: true,
            screen_code: "SCN_FMS",
            page_code: "SCN_FMS",
            page_type: "landing",
          },
          component: () => import("../views/FMS/FmsMaster/refno_type_mstr.vue"),
        },
        {
          path: "/refno_type_mstr/add",
          name: "refno_type_mstr_add",
          meta: {
            auth: true,
            iscommontoshow: true,
            isnoadmintoshow: true,
            screen_code: "SCN_FMS",
            page_code: "SCN_FMS",
            page_type: "add_screen",
          },
          component: () =>
            import("../views/FMS/FmsMaster/refno_type_mstradd.vue"),
        },

        {
          path: "/refno_type_mstr/edit/:id",
          name: "refno_type_mstr_edit",
          meta: {
            auth: true,
            iscommontoshow: true,
            isnoadmintoshow: true,
            screen_code: "SCN_FMS",
            page_code: "SCN_FMS",
            page_type: "edit_screen",
          },
          component: () =>
            import("../views/FMS/FmsMaster/refno_type_mstredit.vue"),
        },
        {
          path: "/renewal_type_mstr",
          name: "renewal_type_mstr",
          meta: {
            auth: true,
            iscommontoshow: true,
            isnoadmintoshow: true,
            screen_code: "SCN_FMS",
            page_code: "SCN_FMS",
            page_type: "landing",
          },
          component: () =>
            import("../views/FMS/FmsMaster/renewal_type_mstr.vue"),
        },
        {
          path: "/renewal_type_mstr/add",
          name: "renewal_type_mstr_add",
          meta: {
            auth: true,
            iscommontoshow: true,
            isnoadmintoshow: true,
            screen_code: "SCN_FMS",
            page_code: "SCN_FMS",
            page_type: "add_screen",
          },
          component: () =>
            import("../views/FMS/FmsMaster/renewal_type_mstradd.vue"),
        },

        {
          path: "/renewal_type_mstr/edit/:id",
          name: "renewal_type_mstr_edit",
          meta: {
            auth: true,
            iscommontoshow: true,
            isnoadmintoshow: true,
            screen_code: "SCN_FMS",
            page_code: "SCN_FMS",
            page_type: "edit_screen",
          },
          component: () =>
            import("../views/FMS/FmsMaster/renewal_type_mstredit.vue"),
        },
        {
          path: "/route_mstr",
          name: "route_mstr",
          meta: {
            auth: true,
            iscommontoshow: true,
            isnoadmintoshow: true,
            screen_code: "SCN_FMS",
            page_code: "SCN_FMS",
            page_type: "landing",
          },
          component: () => import("../views/FMS/FmsMaster/route_mstr.vue"),
        },
        {
          path: "/route_mstr/add",
          name: "route_mstr_add",
          meta: {
            auth: true,
            iscommontoshow: true,
            isnoadmintoshow: true,
            screen_code: "SCN_FMS",
            page_code: "SCN_FMS",
            page_type: "add_screen",
          },
          component: () => import("../views/FMS/FmsMaster/route_mstradd.vue"),
        },

        {
          path: "/route_mstr/group",
          name: "route_mstr_group",
          meta: {
            auth: true,
            iscommontoshow: true,
            isnoadmintoshow: true,
            screen_code: "SCN_FMS",
            page_code: "SCN_FMS",
            page_type: "add_screen",
          },
          component: () => import("../views/FMS/FmsMaster/route_mstrgroup.vue"),
        },

        {
          path: "/route_mstr/edit/:id",
          name: "route_mstr_edit",
          meta: {
            auth: true,
            iscommontoshow: true,
            isnoadmintoshow: true,
            screen_code: "SCN_FMS",
            page_code: "SCN_FMS",
            page_type: "edit_screen",
          },
          component: () => import("../views/FMS/FmsMaster/route_mstredit.vue"),
        },
        {
          path: "/route_mstr/edit/group/:id",
          name: "route_mstr_edit_grp",
          meta: {
            auth: true,
            iscommontoshow: true,
            isnoadmintoshow: true,
            screen_code: "SCN_FMS",
            page_code: "SCN_FMS",
            page_type: "edit_screen",
          },
          component: () =>
            import("../views/FMS/FmsMaster/route_mstreditgrp.vue"),
        },
        {
          path: "/schedule_visit_mstr",
          name: "schedule_visit_mstr",
          meta: {
            auth: true,
            iscommontoshow: true,
            isnoadmintoshow: true,
            screen_code: "SCN_FMS",
            page_code: "SCN_FMS",
            page_type: "landing",
          },
          component: () =>
            import("../views/FMS/FmsMaster/schedule_visit_mstr.vue"),
        },
        {
          path: "/schedule_visit_mstr/add",
          name: "schedule_visit_mstr_add",
          meta: {
            auth: true,
            iscommontoshow: true,
            isnoadmintoshow: true,
            screen_code: "SCN_FMS",
            page_code: "SCN_FMS",
            page_type: "add_screen",
          },
          component: () =>
            import("../views/FMS/FmsMaster/schedule_visit_mstradd.vue"),
        },

        {
          path: "/schedule_visit_mstr/edit/:id",
          name: "schedule_visit_mstr_edit",
          meta: {
            auth: true,
            iscommontoshow: true,
            isnoadmintoshow: true,
            screen_code: "SCN_FMS",
            page_code: "SCN_FMS",
            page_type: "edit_screen",
          },
          component: () =>
            import("../views/FMS/FmsMaster/schedule_visit_mstredit.vue"),
        },
        {
          path: "/fmsservice",
          name: "fmsservice",
          meta: {
            auth: true,
            iscommontoshow: true,
            isnoadmintoshow: true,
            screen_code: "SCN_FMS",
            page_code: "SCN_FMS",
            page_type: "landing",
          },
          component: () => import("../views/FMS/FmsMaster/service.vue"),
        },
        {
          path: "/fmsservice/add",
          name: "fmsservice_add",
          meta: {
            auth: true,
            iscommontoshow: true,
            isnoadmintoshow: true,
            screen_code: "SCN_FMS",
            page_code: "SCN_FMS",
            page_type: "add_screen",
          },
          component: () => import("../views/FMS/FmsMaster/serviceadd.vue"),
        },

        {
          path: "/fmsservice/edit/:id",
          name: "fmsservice_edit",
          meta: {
            auth: true,
            iscommontoshow: true,
            isnoadmintoshow: true,
            screen_code: "SCN_FMS",
            page_code: "SCN_FMS",
            page_type: "edit_screen",
          },
          component: () => import("../views/FMS/FmsMaster/serviceedit.vue"),
        },
        {
          path: "/spare_group_master",
          name: "spare_group_master",
          meta: {
            auth: true,
            iscommontoshow: true,
            isnoadmintoshow: true,
            screen_code: "SCN_FMS",
            page_code: "SCN_FMS",
            page_type: "landing",
          },
          component: () =>
            import("../views/FMS/FmsMaster/spare_group_master.vue"),
        },
        {
          path: "/spare_group_master/add",
          name: "spare_group_master_add",
          meta: {
            auth: true,
            iscommontoshow: true,
            isnoadmintoshow: true,
            screen_code: "SCN_FMS",
            page_code: "SCN_FMS",
            page_type: "add_screen",
          },
          component: () =>
            import("../views/FMS/FmsMaster/spare_group_master_add.vue"),
        },

        {
          path: "/spare_group_master/edit/:id",
          name: "spare_group_master_edit",
          meta: {
            auth: true,
            iscommontoshow: true,
            isnoadmintoshow: true,
            screen_code: "SCN_FMS",
            page_code: "SCN_FMS",
            page_type: "edit_screen",
          },
          component: () =>
            import("../views/FMS/FmsMaster/spare_group_master_edit.vue"),
        },
        {
          path: "/spare_parts_master",
          name: "spare_parts_master",
          meta: {
            auth: true,
            iscommontoshow: true,
            isnoadmintoshow: true,
            screen_code: "SCN_FMS",
            page_code: "SCN_FMS",
            page_type: "landing",
          },
          component: () =>
            import("../views/FMS/FmsMaster/spare_parts_master.vue"),
        },
        {
          path: "/spare_parts_master/add",
          name: "spare_parts_master_add",
          meta: {
            auth: true,
            iscommontoshow: true,
            isnoadmintoshow: true,
            screen_code: "SCN_FMS",
            page_code: "SCN_FMS",
            page_type: "add_screen",
          },
          component: () =>
            import("../views/FMS/FmsMaster/spare_parts_master_add.vue"),
        },

        {
          path: "/spare_parts_master/edit/:id",
          name: "spare_parts_master_edit",
          meta: {
            auth: true,
            iscommontoshow: true,
            isnoadmintoshow: true,
            screen_code: "SCN_FMS",
            page_code: "SCN_FMS",
            page_type: "edit_screen",
          },
          component: () =>
            import("../views/FMS/FmsMaster/spare_parts_master_edit.vue"),
        },
        {
          path: "/state_mstr",
          name: "state_mstr",
          meta: {
            auth: true,
            iscommontoshow: true,
            isnoadmintoshow: true,
            screen_code: "SCN_FMS",
            page_code: "SCN_FMS",
            page_type: "landing",
          },
          component: () => import("../views/FMS/FmsMaster/state_mstr.vue"),
        },
        {
          path: "/state_mstr/add",
          name: "state_mstr_add",
          meta: {
            auth: true,
            iscommontoshow: true,
            isnoadmintoshow: true,
            screen_code: "SCN_FMS",
            page_code: "SCN_FMS",
            page_type: "add_screen",
          },
          component: () => import("../views/FMS/FmsMaster/state_mstradd.vue"),
        },

        {
          path: "/state_mstr/edit/:id",
          name: "state_mstr_edit",
          meta: {
            auth: true,
            iscommontoshow: true,
            isnoadmintoshow: true,
            screen_code: "SCN_FMS",
            page_code: "SCN_FMS",
            page_type: "edit_screen",
          },
          component: () => import("../views/FMS/FmsMaster/state_mstredit.vue"),
        },
        {
          path: "/tax_mstr",
          name: "tax_mstr",
          meta: {
            auth: true,
            iscommontoshow: true,
            isnoadmintoshow: true,
            screen_code: "SCN_FMS",
            page_code: "SCN_FMS",
            page_type: "landing",
          },
          component: () => import("../views/FMS/FmsMaster/tax_mstr.vue"),
        },
        {
          path: "/tax_mstr/add",
          name: "tax_mstr_add",
          meta: {
            auth: true,
            iscommontoshow: true,
            isnoadmintoshow: true,
            screen_code: "SCN_FMS",
            page_code: "SCN_FMS",
            page_type: "add_screen",
          },
          component: () => import("../views/FMS/FmsMaster/tax_mstradd.vue"),
        },

        {
          path: "/tax_mstr/edit/:id",
          name: "tax_mstr_edit",
          meta: {
            auth: true,
            iscommontoshow: true,
            isnoadmintoshow: true,
            screen_code: "SCN_FMS",
            page_code: "SCN_FMS",
            page_type: "edit_screen",
          },
          component: () => import("../views/FMS/FmsMaster/tax_mstredit.vue"),
        },
        {
          path: "/tax_category_mstr",
          name: "tax_category_mstr",
          meta: {
            auth: true,
            iscommontoshow: true,
            isnoadmintoshow: true,
            screen_code: "SCN_FMS",
            page_code: "SCN_FMS",
            page_type: "landing",
          },
          component: () =>
            import("../views/FMS/FmsMaster/tax_category_mstr.vue"),
        },
        {
          path: "/tax_category_mstr/add",
          name: "tax_category_mstr_add",
          meta: {
            auth: true,
            iscommontoshow: true,
            isnoadmintoshow: true,
            screen_code: "SCN_FMS",
            page_code: "SCN_FMS",
            page_type: "add_screen",
          },
          component: () =>
            import("../views/FMS/FmsMaster/tax_category_mstradd.vue"),
        },

        {
          path: "/tax_category_mstr/edit/:id",
          name: "tax_category_mstr_edit",
          meta: {
            auth: true,
            iscommontoshow: true,
            isnoadmintoshow: true,
            screen_code: "SCN_FMS",
            page_code: "SCN_FMS",
            page_type: "edit_screen",
          },
          component: () =>
            import("../views/FMS/FmsMaster/tax_category_mstredit.vue"),
        },
        {
          path: "/team",
          name: "team",
          meta: {
            auth: true,
            iscommontoshow: true,
            isnoadmintoshow: true,
            screen_code: "SCN_FMS",
            page_code: "SCN_FMS",
            page_type: "landing",
          },
          component: () => import("../views/FMS/FmsMaster/team.vue"),
        },
        {
          path: "/team/add",
          name: "team_add",
          meta: {
            auth: true,
            iscommontoshow: true,
            isnoadmintoshow: true,
            screen_code: "SCN_FMS",
            page_code: "SCN_FMS",
            page_type: "add_screen",
          },
          component: () => import("../views/FMS/FmsMaster/teamadd.vue"),
        },

        {
          path: "/team/edit/:id",
          name: "team_edit",
          meta: {
            auth: true,
            iscommontoshow: true,
            isnoadmintoshow: true,
            screen_code: "SCN_FMS",
            page_code: "SCN_FMS",
            page_type: "edit_screen",
          },
          component: () => import("../views/FMS/FmsMaster/teamedit.vue"),
        },
        {
          path: "/ticket_category_mstr",
          name: "ticket_category_mstr",
          meta: {
            auth: true,
            iscommontoshow: true,
            isnoadmintoshow: true,
            screen_code: "SCN_FMS",
            page_code: "SCN_FMS",
            page_type: "landing",
          },
          component: () =>
            import("../views/FMS/FmsMaster/ticket_category_mstr.vue"),
        },
        {
          path: "/ticket_category_mstr/add",
          name: "ticket_category_mstr_add",
          meta: {
            auth: true,
            iscommontoshow: true,
            isnoadmintoshow: true,
            screen_code: "SCN_FMS",
            page_code: "SCN_FMS",
            page_type: "add_screen",
          },
          component: () =>
            import("../views/FMS/FmsMaster/ticket_category_mstradd.vue"),
        },

        {
          path: "/ticket_category_mstr/edit/:id",
          name: "ticket_category_mstr_edit",
          meta: {
            auth: true,
            iscommontoshow: true,
            isnoadmintoshow: true,
            screen_code: "SCN_FMS",
            page_code: "SCN_FMS",
            page_type: "edit_screen",
          },
          component: () =>
            import("../views/FMS/FmsMaster/ticket_category_mstredit.vue"),
        },
        {
          path: "/ticket_subcategory_mstr",
          name: "ticket_subcategory_mstr",
          meta: {
            auth: true,
            iscommontoshow: true,
            isnoadmintoshow: true,
            screen_code: "SCN_FMS",
            page_code: "SCN_FMS",
            page_type: "landing",
          },
          component: () =>
            import("../views/FMS/FmsMaster/ticket_subcategory_mstr.vue"),
        },
        {
          path: "/ticket_subcategory_mstr/add",
          name: "ticket_subcategory_mstr_add",
          meta: {
            auth: true,
            iscommontoshow: true,
            isnoadmintoshow: true,
            screen_code: "SCN_FMS",
            page_code: "SCN_FMS",
            page_type: "add_screen",
          },
          component: () =>
            import("../views/FMS/FmsMaster/ticket_subcategory_mstradd.vue"),
        },

        {
          path: "/ticket_subcategory_mstr/edit/:id",
          name: "ticket_subcategory_mstr_edit",
          meta: {
            auth: true,
            iscommontoshow: true,
            isnoadmintoshow: true,
            screen_code: "SCN_FMS",
            page_code: "SCN_FMS",
            page_type: "edit_screen",
          },
          component: () =>
            import("../views/FMS/FmsMaster/ticket_subcategory_mstredit.vue"),
        },
        {
          path: "/pause_category",
          name: "pause_category",
          meta: {
            auth: true,
            iscommontoshow: true,
            isnoadmintoshow: true,
            screen_code: "SCN_FMS",
            page_code: "SCN_FMS",
            page_type: "landing",
          },
          component: () => import("../views/FMS/FmsMaster/pause_category.vue"),
        },
        {
          path: "/customer_comment",
          name: "customer_comment",
          meta: {
            auth: true,
            iscommontoshow: true,
            isnoadmintoshow: true,
            screen_code: "SCN_FMS",
            page_code: "SCN_FMS",
            page_type: "landing",
          },
          component: () => import("../views/FMS/FmsMaster/customer_comment.vue"),
        },
        {
          path: "/pause_category/add",
          name: "pause_category_add",
          meta: {
            auth: true,
            iscommontoshow: true,
            isnoadmintoshow: true,
            screen_code: "SCN_FMS",
            page_code: "SCN_FMS",
            page_type: "add_screen",
          },
          component: () =>
            import("../views/FMS/FmsMaster/pause_category_add.vue"),
        },
        {
          path: "/pause_category/edit/:id",
          name: "pause_category_edit",
          meta: {
            auth: true,
            iscommontoshow: true,
            isnoadmintoshow: true,
            screen_code: "SCN_FMS",
            page_code: "SCN_FMS",
            page_type: "edit_screen",
          },
          component: () =>
            import("../views/FMS/FmsMaster/pause_category_edit.vue"),
        },
        {
          path: "/business_centre",
          name: "business_centre",
          meta: {
            auth: true,
            iscommontoshow: true,
            isnoadmintoshow: true,
            screen_code: "SCN_FMS",
            page_code: "SCN_FMS",
            page_type: "landing",
          },
          component: () => import("../views/FMS/FmsMaster/business_centre.vue"),
        },
        {
          path: "/business_centre/add_edit",
          name: "business_centre_add_edit",
          meta: {
            auth: true,
            iscommontoshow: true,
            isnoadmintoshow: true,
            screen_code: "SCN_FMS",
            page_code: "SCN_FMS",
            page_type: "add_screen",
          },
          component: () =>
            import("../views/FMS/FmsMaster/business_centre_add_edit.vue"),
        },
        {
          path: "/currency_setting",
          name: "currency_setting",
          meta: {
            auth: true,
            iscommontoshow: true,
            isnoadmintoshow: true,
            screen_code: "SCN_FMS",
            page_code: "SCN_FMS",
            page_type: "landing",
          },
          component: () =>
            import("../views/FMS/FmsMaster/currency_setting.vue"),
        },
        {
          path: "/warehouse_mstr",
          name: "warehouse_mstr",
          meta: {
            auth: true,
            iscommontoshow: true,
            isnoadmintoshow: true,
            screen_code: "SCN_FMS",
            page_code: "SCN_FMS",
            page_type: "landing",
          },
          component: () =>
            import("../views/FMS/FmsMaster/warehouse_mstr_landing.vue"),
        },
        {
          path: "/warehouse_mstr/site_mstr/add_edit",
          name: "warehouse_mstr_add_edit",
          meta: {
            auth: true,
            iscommontoshow: true,
            isnoadmintoshow: true,
            screen_code: "SCN_FMS",
            page_code: "SCN_FMS",
            page_type: "add_screen",
          },
          component: () =>
            import("../views/FMS/FmsMaster/site_mstr_add_edit.vue"),
        },
        {
          path: "/warehouse_mstr/storage_location/add_edit",
          name: "storage_location_mstr_add_edit",
          meta: {
            auth: true,
            iscommontoshow: true,
            isnoadmintoshow: true,
            screen_code: "SCN_FMS",
            page_code: "SCN_FMS",
            page_type: "add_screen",
          },
          component: () =>
            import("../views/FMS/FmsMaster/storage_location_mstr_add_edit.vue"),
        },
        {
          path: "/warehouse_mstr/stock_point/add_edit",
          name: "stock_point_mstr_add_edit",
          meta: {
            auth: true,
            iscommontoshow: true,
            isnoadmintoshow: true,
            screen_code: "SCN_FMS",
            page_code: "SCN_FMS",
            page_type: "add_screen",
          },
          component: () =>
            import("../views/FMS/FmsMaster/stock_point_mstr_add_edit.vue"),
        },
        {
          path: "/warehouse_mstr/storage_section/add_edit",
          name: "storage_section_mstr_add_edit",
          meta: {
            auth: true,
            iscommontoshow: true,
            isnoadmintoshow: true,
            screen_code: "SCN_FMS",
            page_code: "SCN_FMS",
            page_type: "add_screen",
          },
          component: () =>
            import("../views/FMS/FmsMaster/storage_section_mstr_add_edit.vue"),
        },
        {
          path: "/warehouse_mstr/bin_group/add_edit",
          name: "bin_group_mstr_add_edit",
          meta: {
            auth: true,
            iscommontoshow: true,
            isnoadmintoshow: true,
            screen_code: "SCN_FMS",
            page_code: "SCN_FMS",
            page_type: "add_screen",
          },
          component: () =>
            import("../views/FMS/FmsMaster/bin_group_mstr_add_edit.vue"),
        },
        {
          path: "/warehouse_mstr/bin_mstr/add_edit",
          name: "bin_mstr_add_edit",
          meta: {
            auth: true,
            iscommontoshow: true,
            isnoadmintoshow: true,
            screen_code: "SCN_FMS",
            page_code: "SCN_FMS",
            page_type: "add_screen",
          },
          component: () =>
            import("../views/FMS/FmsMaster/bin_mstr_add_edit.vue"),
        },
        {
          path: "/division_landing",
          name: "division_landing",
          meta: {
            auth: true,
            iscommontoshow: true,
            isnoadmintoshow: true,
            screen_code: "SCN_FMS",
            page_code: "SCN_FMS",
            page_type: "landing",
          },
          component: () =>
            import("../views/FMS/FmsMaster/division_landing.vue"),
        },
        {
          path: "/division_landing/add_edit",
          name: "division_add_edit",
          meta: {
            auth: true,
            iscommontoshow: true,
            isnoadmintoshow: true,
            screen_code: "SCN_FMS",
            page_code: "SCN_FMS",
            page_type: "add_screen",
          },
          component: () =>
            import("../views/FMS/FmsMaster/division_add_edit.vue"),
        },
        {
          path: "/section_landing",
          name: "section_landing",
          meta: {
            auth: true,
            iscommontoshow: true,
            isnoadmintoshow: true,
            screen_code: "SCN_FMS",
            page_code: "SCN_FMS",
            page_type: "landing",
          },
          component: () => import("../views/FMS/FmsMaster/section_landing.vue"),
        },
        {
          path: "/section_landing/add_edit",
          name: "section_add_edit",
          meta: {
            auth: true,
            iscommontoshow: true,
            isnoadmintoshow: true,
            screen_code: "SCN_FMS",
            page_code: "SCN_FMS",
            page_type: "add_screen",
          },
          component: () =>
            import("../views/FMS/FmsMaster/section_add_edit.vue"),
        },
        {
          path: "/item_category_landing",
          name: "item_category_landing",
          meta: {
            auth: true,
            iscommontoshow: true,
            isnoadmintoshow: true,
            screen_code: "SCN_FMS",
            page_code: "SCN_FMS",
            page_type: "landing",
          },
          component: () =>
            import("../views/FMS/FmsMaster/item_category_landing.vue"),
        },
        {
          path: "/item_category_landing/add_edit",
          name: "item_category_add_edit",
          meta: {
            auth: true,
            iscommontoshow: true,
            isnoadmintoshow: true,
            screen_code: "SCN_FMS",
            page_code: "SCN_FMS",
            page_type: "add_screen",
          },
          component: () =>
            import("../views/FMS/FmsMaster/item_category_add_edit.vue"),
        },
        {
          path: "/item_class_landing",
          name: "item_class_landing",
          meta: {
            auth: true,
            iscommontoshow: true,
            isnoadmintoshow: true,
            screen_code: "SCN_FMS",
            page_code: "SCN_FMS",
            page_type: "landing",
          },
          component: () =>
            import("../views/FMS/FmsMaster/item_class_landing.vue"),
        },
        {
          path: "/item_class_landing/add_edit",
          name: "item_class_add_edit",
          meta: {
            auth: true,
            iscommontoshow: true,
            isnoadmintoshow: true,
            screen_code: "SCN_FMS",
            page_code: "SCN_FMS",
            page_type: "add_screen",
          },
          component: () =>
            import("../views/FMS/FmsMaster/item_class_add_edit.vue"),
        },
        {
          path: "/hs_code_landing",
          name: "hs_code_landing",
          meta: {
            auth: true,
            iscommontoshow: true,
            isnoadmintoshow: true,
            screen_code: "SCN_FMS",
            page_code: "SCN_FMS",
            page_type: "landing",
          },
          component: () => import("../views/FMS/FmsMaster/hs_code_landing.vue"),
        },
        {
          path: "/item_attribute_type",
          name: "item_attribute_type",
          meta: {
            auth: true,
            iscommontoshow: true,
            isnoadmintoshow: true,
            screen_code: "SCN_FMS",
            page_code: "SCN_FMS",
            page_type: "landing",
          },
          component: () =>
            import("../views/FMS/FmsMaster/item_attribute_type.vue"),
        },
        {
          path: "/item_attribute_type/add_edit",
          name: "item_attribute_type_add_edit",
          meta: {
            auth: true,
            iscommontoshow: true,
            isnoadmintoshow: true,
            screen_code: "SCN_FMS",
            page_code: "SCN_FMS",
            page_type: "add_screen",
          },
          component: () =>
            import("../views/FMS/FmsMaster/item_attribute_type_add_edit.vue"),
        },
        {
          path: "/item_attribute_lookup",
          name: "item_attribute_lookup",
          meta: {
            auth: true,
            iscommontoshow: true,
            isnoadmintoshow: true,
            screen_code: "SCN_FMS",
            page_code: "SCN_FMS",
            page_type: "landing",
          },
          component: () =>
            import("../views/FMS/FmsMaster/item_attribute_lookup.vue"),
        },
        {
          path: "/item_attribute_lookup/add_edit",
          name: "item_attribute_lookup_add_edit",
          meta: {
            auth: true,
            iscommontoshow: true,
            isnoadmintoshow: true,
            screen_code: "SCN_FMS",
            page_code: "SCN_FMS",
            page_type: "add_screen",
          },
          component: () =>
            import("../views/FMS/FmsMaster/item_attribute_lookup_add_edit.vue"),
        },
        {
          path: "/hs_code_landing/add_edit",
          name: "hs_code_add_edit",
          meta: {
            auth: true,
            iscommontoshow: true,
            isnoadmintoshow: true,
            screen_code: "SCN_FMS",
            page_code: "SCN_FMS",
            page_type: "add_screen",
          },
          component: () =>
            import("../views/FMS/FmsMaster/hs_code_add_edit.vue"),
        },
        {
          path: "/tax",
          name: "tax",
          meta: {
            auth: true,
            iscommontoshow: true,
            isnoadmintoshow: true,
            screen_code: "SCN_FMS",
            page_code: "SCN_FMS",
            page_type: "landing",
          },
          component: () => import("../views/FMS/FmsMaster/tax.vue"),
        },
        {
          path: "/tax/add",
          name: "tax_add",
          meta: {
            auth: true,
            iscommontoshow: true,
            isnoadmintoshow: true,
            screen_code: "SCN_FMS",
            page_code: "SCN_FMS",
            page_type: "add_screen",
          },
          component: () => import("../views/FMS/FmsMaster/tax_add.vue"),
        },
        {
          path: "/tax/edit/:id",
          name: "tax_edit",
          meta: {
            auth: true,
            iscommontoshow: true,
            isnoadmintoshow: true,
            screen_code: "SCN_FMS",
            page_code: "SCN_FMS",
            page_type: "edit_screen",
          },
          component: () => import("../views/FMS/FmsMaster/tax_edit.vue"),
        },
        {
          path: "/tax_rate",
          name: "tax_rate",
          meta: {
            auth: true,
            iscommontoshow: true,
            isnoadmintoshow: true,
            screen_code: "SCN_FMS",
            page_code: "SCN_FMS",
            page_type: "landing",
          },
          component: () =>
            import("../views/FMS/FmsMaster/TaxRate/tax_rate.vue"),
        },
        {
          path: "/tax_rate/add",
          name: "tax_rate",
          meta: {
            auth: true,
            iscommontoshow: true,
            isnoadmintoshow: true,
            screen_code: "SCN_FMS",
            page_code: "SCN_FMS",
            page_type: "add_screen",
          },
          component: () =>
            import("../views/FMS/FmsMaster/TaxRate/tax_rate_add.vue"),
        },
        {
          path: "/tax_rate/edit/:id",
          name: "tax_rate",
          meta: {
            auth: true,
            iscommontoshow: true,
            isnoadmintoshow: true,
            screen_code: "SCN_FMS",
            page_code: "SCN_FMS",
            page_type: "edit_screen",
          },
          component: () =>
            import("../views/FMS/FmsMaster/TaxRate/tax_rate_edit.vue"),
        },
        {
          path: "/tax_rate/view/:id",
          name: "tax_rate",
          meta: {
            auth: true,
            iscommontoshow: true,
            isnoadmintoshow: true,
            screen_code: "SCN_FMS",
            page_code: "SCN_FMS",
            page_type: "view_screen",
          },
          component: () =>
            import("../views/FMS/FmsMaster/TaxRate/tax_rate_view.vue"),
        },
        {
          path: "/mr_landing",
          name: "mr_landing",
          meta: {
            auth: true,
            iscommontoshow: true,
            isnoadmintoshow: true,
            screen_code: "SCN_FMS",
            page_code: "SCN_FMS",
            page_type: "landing",
          },
          component: () => import("../views/FMS/MR/mr_landing/mr_landing.vue"),
        },
        {
          path: "/mr_add",
          name: "mr_add",
          meta: {
            auth: true,
            iscommontoshow: true,
            isnoadmintoshow: true,
            screen_code: "SCN_FMS",
            page_code: "SCN_FMS",
            page_type: "add",
          },
          component: () => import("../views/FMS/MR/mr_add.vue"),
        },
        {
          path: "/mr_edit",
          name: "mr_edit",
          meta: {
            auth: true,
            iscommontoshow: true,
            isnoadmintoshow: true,
            screen_code: "SCN_FMS",
            page_code: "SCN_FMS",
            page_type: "edit",
          },
          component: () => import("../views/FMS/MR/mr_edit.vue"),
        },
        {
          path: "/mr_landing/view",
          name: "mr_view",
          meta: {
            auth: true,
            iscommontoshow: true,
            isnoadmintoshow: true,
            screen_code: "SCN_FMS",
            page_code: "SCN_FMS",
            page_type: "view",
          },
          component: () => import("../views/FMS/MR/mr_view.vue"),
        },
        {
          path: "/mr_approval/:id",
          name: "mr_approval",
          meta: {
            auth: true,
            iscommontoshow: true,
            isnoadmintoshow: true,
            screen_code: "SCN_FMS",
            page_code: "SCN_FMS",
            page_type: "add",
          },
          component: () => import("../views/FMS/MR/mr_approval.vue"),
        },
        {
          path: "/mr_approval_tech/:id",
          name: "mr_approval_tech",
          meta: {
            auth: true,
            iscommontoshow: true,
            isnoadmintoshow: true,
            screen_code: "SCN_FMS",
            page_code: "SCN_FMS",
            page_type: "add",
          },
          component: () => import("../views/FMS/MR/mr_approval_tech.vue"),
        },
        {
          path: "/mr_indent_landing",
          name: "mr_indent_landing",
          meta: {
            auth: true,
            iscommontoshow: true,
            isnoadmintoshow: true,
            screen_code: "SCN_FMS",
            page_code: "SCN_FMS_MR_INDENT",
            page_type: "add",
          },
          component: () => import("../views/FMS/MR/mr_indent_landing.vue"),
        },
        {
          path: "/mr_indent_add/:id/:addflag",
          name: "mr_indent_add",
          meta: {
            auth: true,
            iscommontoshow: true,
            isnoadmintoshow: true,
            screen_code: "SCN_FMS_MR_INDENT",
            page_code: "SCN_FMS",
            page_type: "add",
          },
          component: () => import("../views/FMS/MR/mr_indent_add.vue"),
        },
        {
          path: "/mr_indent_add_new",
          name: "mr_indent_add_new",
          meta: {
            auth: true,
            iscommontoshow: true,
            isnoadmintoshow: true,
            screen_code: "SCN_FMS_MR_INDENT",
            page_code: "SCN_FMS",
            page_type: "add_screen",
          },
          component: () => import("../views/FMS/MR/mr_indent_add_new.vue"),
        },
        {
          path: "/mr_indent_view/:id",
          name: "mr_indent_view",
          meta: {
            auth: true,
            iscommontoshow: true,
            isnoadmintoshow: true,
            screen_code: "SCN_FMS_MR_INDENT",
            page_code: "SCN_FMS",
            page_type: "view",
          },
          component: () => import("../views/FMS/MR/mr_indent_view.vue"),
        },
        {
          path: "/transfer_landing",
          name: "transfer_landing",
          meta: {
            auth: true,
            iscommontoshow: true,
            isnoadmintoshow: true,
            screen_code: "SCN_FMS_TRANSFER",
            page_code: "SCN_FMS",
            page_type: "add",
          },
          component: () => import("../views/FMS/MR/transfer_landing.vue"),
        },
        {
          path: "/transfer_add/:id",
          name: "transfer_add",
          meta: {
            auth: true,
            iscommontoshow: true,
            isnoadmintoshow: true,
            screen_code: "SCN_FMS_TRANSFER",
            page_code: "SCN_FMS",
            page_type: "add",
          },
          component: () => import("../views/FMS/MR/transfer_add.vue"),
        },
        {
          path: "/mr_transfer_view/:id",
          name: "mr_transfer_view",
          meta: {
            auth: true,
            iscommontoshow: true,
            isnoadmintoshow: true,
            screen_code: "SCN_FMS_TRANSFER",
            page_code: "SCN_FMS",
            page_type: "view",
          },
          component: () => import("../views/FMS/MR/mr_transfer_view.vue"),
        },
        {
          path: "/mr_transfer_edit/:id",
          name: "mr_transfer_edit",
          meta: {
            auth: true,
            iscommontoshow: true,
            isnoadmintoshow: true,
            screen_code: "SCN_FMS_TRANSFER",
            page_code: "SCN_FMS",
            page_type: "edit",
          },
          component: () => import("../views/FMS/MR/mr_transfer_edit.vue"),
        },
        {
          path: "/mr_transfer_approval/:id",
          name: "mr_transfer_approval",
          meta: {
            auth: true,
            iscommontoshow: true,
            isnoadmintoshow: true,
            screen_code: "SCN_FMS_TRANSFER",
            page_code: "SCN_FMS",
            page_type: "edit",
          },
          component: () => import("../views/FMS/MR/mr_transfer_approval.vue"),
        },
        {
          path: "/grn_landing",
          name: "grn_landing",
          meta: {
            auth: true,
            iscommontoshow: true,
            isnoadmintoshow: true,
            screen_code: "SCN_FMS_MR_GRN",
            page_code: "SCN_FMS",
            page_type: "landing",
          },
          component: () => import("../views/FMS/MR/grn_landing.vue"),
        },
        {
          path: "/grn_add",
          name: "grn_add",
          meta: {
            auth: true,
            iscommontoshow: true,
            isnoadmintoshow: true,
            screen_code: "SCN_FMS_MR_GRN",
            page_code: "SCN_FMS",
            page_type: "add",
          },
          component: () => import("../views/FMS/MR/mr_grn_add.vue"),
        },
        {
          path: "/grn_view/:id",
          name: "grn_view",
          meta: {
            auth: true,
            iscommontoshow: true,
            isnoadmintoshow: true,
            screen_code: "SCN_FMS_MR_GRN",
            page_code: "SCN_FMS",
            page_type: "add",
          },
          component: () => import("../views/FMS/MR/grn_view.vue"),
        },
        {
          path: "/mr_receipt_status_landing",
          name: "mr_receipt_status_landing",
          meta: {
            auth: true,
            iscommontoshow: true,
            isnoadmintoshow: true,
            screen_code: "SCN_FMS_MR_RECEIPT_STATUS",
            page_code: "SCN_FMS",
            page_type: "landing",
          },
          component: () => import("../views/FMS/MR/mr_receipt_status.vue"),
        },
        {
          path: "/mr_receipt_landing",
          name: "mr_receipt_landing",
          meta: {
            auth: true,
            iscommontoshow: true,
            isnoadmintoshow: true,
            screen_code: "SCN_FMS_MR_RECEIPT_STATUS",
            page_code: "SCN_FMS",
            page_type: "landing",
          },
          component: () => import("../views/FMS/MR/mr_receipt/mr_receipt_landing/mr_receipt_landing.vue"),
        },
        
        {
          path: "/mr_receipt_landing_add",
          name: "mr_receipt_landing_add",
          meta: {
            auth: true,
            iscommontoshow: true,
            isnoadmintoshow: true,
            screen_code: "SCN_FMS_MR_RECEIPT_STATUS",
            page_code: "SCN_FMS",
            page_type: "add_screen",
          },
          component: () => import("../views/FMS/MR/mr_receipt/mr_receipt_add.vue"),
        },
        {
          path: "/mr_stock_report",
          name: "mr_stock_report",
          meta: {
            auth: true,
            iscommontoshow: true,
            isnoadmintoshow: true,
            screen_code: "SCN_FMS_MR_STOCK_REPORT",
            page_code: "SCN_FMS",
            page_type: "landing",
          },
          component: () => import("../views/FMS/MR/stock_report.vue"),
        },
        {
          path: "/tis_landing",
          name: "tis_landing",
          meta: {
            auth: true,
            iscommontoshow: true,
            isnoadmintoshow: true,
            screen_code: "SCN_FMS_TIS",
            page_code: "SCN_FMS",
            page_type: "add",
          },
          component: () => import("../views/FMS/TIS/tis_landing.vue"),
        },
        {
          path: "/tis_inspection_edit/:id",
          name: "tis_inspection_edit",
          meta: {
            auth: true,
            iscommontoshow: true,
            isnoadmintoshow: true,
            screen_code: "SCN_FMS_TIS",
            page_code: "SCN_FMS",
            page_type: "edit",
          },
          component: () => import("../views/FMS/TIS/tis_inspection_edit.vue"),
        },
        {
          path: "/tis_inspection_view/:id",
          name: "tis_inspection_view",
          meta: {
            auth: true,
            iscommontoshow: true,
            isnoadmintoshow: true,
            screen_code: "SCN_FMS_TIS",
            page_code: "SCN_FMS",
            page_type: "edit",
          },
          component: () => import("../views/FMS/TIS/tis_inspection_view.vue"),
        },
        {
          path: "/tis_add",
          name: "tis_add",
          meta: {
            auth: true,
            iscommontoshow: true,
            isnoadmintoshow: true,
            screen_code: "SCN_FMS_TIS",
            page_code: "SCN_FMS",
            page_type: "add",
          },
          component: () => import("../views/FMS/TIS/tis_add.vue"),
        },
        {
          path: "/tis_add_new",
          name: "tis_add_new",
          meta: {
            auth: true,
            iscommontoshow: true,
            isnoadmintoshow: true,
            screen_code: "SCN_FMS_TIS",
            page_code: "SCN_FMS",
            page_type: "add",
          },
          component: () => import("../views/FMS/TIS/tis_add_new.vue"),
        },
        {
          path: "/tis_inspection/:id",
          name: "tis_inspection",
          meta: {
            auth: true,
            iscommontoshow: true,
            isnoadmintoshow: true,
            screen_code: "SCN_FMS_TIS",
            page_code: "SCN_FMS",
            page_type: "add",
          },
          component: () => import("../views/FMS/TIS/tis_inspection.vue"),
        },
        {
          path: "/tis_survey_group",
          name: "tis_survey_group",
          meta: {
            auth: true,
            iscommontoshow: true,
            isnoadmintoshow: true,
            screen_code: "SCN_FMS_TIS_SURVEY_GROUP",
            page_code: "SCN_FMS",
            page_type: "landing",
          },
          component: () => import("../views/FMS/TIS/TIS_Survey/tis_survey_group.vue"),
        },
        {
          path: "/tis_survey_group_add",
          name: "tis_survey_group_add",
          meta: {
            auth: true,
            iscommontoshow: true,
            isnoadmintoshow: true,
            screen_code: "SCN_FMS_TIS_SURVEY_GROUP",
            page_code: "SCN_FMS",
            page_type: "add",
          },
          component: () => import("../views/FMS/TIS/TIS_Survey/tis_survey_group_add.vue"),
        },
        {
          path: "/tis_survey_group_edit/:id",
          name: "tis_survey_group_edit",
          meta: {
            auth: true,
            iscommontoshow: true,
            isnoadmintoshow: true,
            screen_code: "SCN_FMS_TIS_SURVEY_GROUP",
            page_code: "SCN_FMS",
            page_type: "edit",
          },
          component: () => import("../views/FMS/TIS/TIS_Survey/tis_survey_group_edit.vue"),
        },
        {
          path: "/tis_survey_field",
          name: "tis_survey_field",
          meta: {
            auth: true,
            iscommontoshow: true,
            isnoadmintoshow: true,
            screen_code: "SCN_FMS_TIS_SURVEY_FIELD",
            page_code: "SCN_FMS",
            page_type: "landing",
          },
          component: () => import("../views/FMS/TIS/TIS_Survey/tis_survey_field.vue"),
        },
        {
          path: "/tis_survey_field_add",
          name: "tis_survey_field_add",
          meta: {
            auth: true,
            iscommontoshow: true,
            isnoadmintoshow: true,
            screen_code: "SCN_FMS_TIS_SURVEY_FIELD",
            page_code: "SCN_FMS",
            page_type: "add",
          },
          component: () => import("../views/FMS/TIS/TIS_Survey/tis_survey_field_add.vue"),
        },
        {
          path: "/tis_survey_field_edit/:id",
          name: "tis_survey_field_edit",
          meta: {
            auth: true,
            iscommontoshow: true,
            isnoadmintoshow: true,
            screen_code: "SCN_FMS_TIS_SURVEY_FIELD",
            page_code: "SCN_FMS",
            page_type: "edit",
          },
          component: () => import("../views/FMS/TIS/TIS_Survey/tis_survey_field_edit.vue"),
        },
        {
          path: "/tis_survey_type",
          name: "tis_survey_type",
          meta: {
            auth: true,
            iscommontoshow: true,
            isnoadmintoshow: true,
            screen_code: "SCN_FMS_TIS_SURVEY_TYPE",
            page_code: "SCN_FMS",
            page_type: "landing",
          },
          component: () => import("../views/FMS/TIS/TIS_Survey/tis_survey_type.vue"),
        },
        {
          path: "/tis_survey_type_add",
          name: "tis_survey_type_add",
          meta: {
            auth: true,
            iscommontoshow: true,
            isnoadmintoshow: true,
            screen_code: "SCN_FMS_TIS_SURVEY_TYPE",
            page_code: "SCN_FMS",
            page_typvalue: "add",
          },
          component: () => import("../views/FMS/TIS/TIS_Survey/tis_survey_type_add.vue"),
        },
        {
          path: "/tis_survey_type_edit/:id",
          name: "tis_survey_type_edit",
          meta: {
            auth: true,
            iscommontoshow: true,
            isnoadmintoshow: true,
            screen_code: "SCN_FMS_TIS_SURVEY_TYPE",
            page_code: "SCN_FMS",
            page_type: "edit",
          },
          component: () => import("../views/FMS/TIS/TIS_Survey/tis_survey_type_edit.vue"),
        },

        {
          path: "/tis_survey_value",
          name: "tis_survey_value",
          meta: {
            auth: true,
            iscommontoshow: true,
            isnoadmintoshow: true,
            screen_code: "SCN_FMS_TIS_SURVEY_VALUE",
            page_code: "SCN_FMS",
            page_value: "landing",
          },
          component: () => import("../views/FMS/TIS/TIS_Survey/tis_survey_value.vue"),
        },
        {
          path: "/tis_survey_value_add",
          name: "tis_survey_value_add",
          meta: {
            auth: true,
            iscommontoshow: true,
            isnoadmintoshow: true,
            screen_code: "SCN_FMS_TIS_SURVEY_VALUE",
            page_code: "SCN_FMS",
            page_type: "add",
          },
          component: () => import("../views/FMS/TIS/TIS_Survey/tis_survey_value_add.vue"),
        },
        {
          path: "/tis_survey_value_edit/:id",
          name: "tis_survey_value_edit",
          meta: {
            auth: true,
            iscommontoshow: true,
            isnoadmintoshow: true,
            screen_code: "SCN_FMS_TIS_SURVEY_VALUE",
            page_code: "SCN_FMS",
            page_type: "edit",
          },
          component: () => import("../views/FMS/TIS/TIS_Survey/tis_survey_value_edit.vue"),
        },
        {
          path: "/mr_stock_ledger",
          name: "mr_stock_ledger",
          meta: {
            auth: true,
            iscommontoshow: true,
            isnoadmintoshow: true,
            screen_code: "SCN_FMS_MR_STOCK_LEDGER",
            page_code: "SCN_FMS",
            page_type: "landing",
          },
          component: () => import("../views/FMS/MR/mr_stock_ledger/stock_ledger.vue"),
        },
        {
          path: "/mr_stock_ledger_view",
          name: "mr_stock_ledger_view",
          meta: {
            auth: true,
            iscommontoshow: true,
            isnoadmintoshow: true,
            screen_code: "SCN_FMS_MR_STOCK_LEDGER",
            page_code: "SCN_FMS",
            page_type: "view",
          },
          component: () => import("../views/FMS/MR/mr_stock_ledger/stock_ledger_view.vue"),
        },
        {
          path: "/item_mstr",
          name: "item_mstr",
          meta: {
            auth: true,
            iscommontoshow: true,
            isnoadmintoshow: true,
            screen_code: "SCN_FMS",
            page_code: "SCN_FMS",
            page_type: "landing",
          },
          component: () => import("../views/FMS/FmsMaster/item_mstr.vue"),
        },
        {
          path: "/item_mstr/add",
          name: "item_mstr_add",
          meta: {
            auth: true,
            iscommontoshow: true,
            isnoadmintoshow: true,
            screen_code: "SCN_FMS",
            page_code: "SCN_FMS",
            page_type: "add_screen",
          },
          component: () => import("../views/FMS/FmsMaster/item_mstr_add.vue"),
        },
        {
          path: "/item_mstr/edit/:id",
          name: "item_mstr_edit",
          meta: {
            auth: true,
            iscommontoshow: true,
            isnoadmintoshow: true,
            screen_code: "SCN_FMS",
            page_code: "SCN_FMS",
            page_type: "edit_screen",
          },
          component: () => import("../views/FMS/FmsMaster/item_mstr_edit.vue"),
        },
        {
          path: "/item_mstr/view/:id",
          name: "item_mstr_view",
          meta: {
            auth: true,
            iscommontoshow: true,
            isnoadmintoshow: true,
            screen_code: "SCN_FMS",
            page_code: "SCN_FMS",
            page_type: "landing",
          },
          component: () => import("../views/FMS/FmsMaster/item_mstr_view.vue"),
        },
        {
          path: "/reason_for_requirement",
          name: "reason_for_requirement",
          meta: {
            auth: true,
            iscommontoshow: true,
            isnoadmintoshow: true,
            screen_code: "SCN_FMS",
            page_code: "SCN_FMS",
            page_type: "landing",
          },
          component: () => import("../views/FMS/FmsMaster/reason_for_requirement.vue"),
        },
        {
          path: "/reason_for_requirement/add",
          name: "reason_for_requirement_add",
          meta: {
            auth: true,
            iscommontoshow: true,
            isnoadmintoshow: true,
            screen_code: "SCN_FMS",
            page_code: "SCN_FMS",
            page_type: "add_screen",
          },
          component: () => import("../views/FMS/FmsMaster/reason_for_requirement_add.vue"),
        },
        {
          path: "/reason_for_requirement/edit/:id",
          name: "reason_for_requirement_edit",
          meta: {
            auth: true,
            iscommontoshow: true,
            isnoadmintoshow: true,
            screen_code: "SCN_FMS",
            page_code: "SCN_FMS",
            page_type: "edit_screen",
          },
          component: () => import("../views/FMS/FmsMaster/reason_for_requirement_edit.vue"),
        },
        {
          path: "/price_mstr",
          name: "price_mstr",
          meta: {
            auth: true,
            iscommontoshow: true,
            isnoadmintoshow: true,
            screen_code: "SCN_FMS",
            page_code: "SCN_FMS",
            page_type: "landing",
          },
          component: () => import("../views/FMS/FmsMaster/price_mstr.vue"),
        },
        {
          path: "/price_mstr/add",
          name: "price_mstr_add",
          meta: {
            auth: true,
            iscommontoshow: true,
            isnoadmintoshow: true,
            screen_code: "SCN_FMS",
            page_code: "SCN_FMS",
            page_type: "add_screen",
          },
          component: () => import("../views/FMS/FmsMaster/price_mstr_add.vue"),
        },
        {
          path: "/price_mstr/edit/:item_mstr_id",
          name: "price_mstr_edit",
          meta: {
            auth: true,
            iscommontoshow: true,
            isnoadmintoshow: true,
            screen_code: "SCN_FMS",
            page_code: "SCN_FMS",
            page_type: "edit_screen",
          },
          component: () => import("../views/FMS/FmsMaster/price_mstr_edit.vue"),
        },
        {
          path: "/price_mstr/view/:item_mstr_id/:branch_id",
          name: "price_mstr_view",
          meta: {
            auth: true,
            iscommontoshow: true,
            isnoadmintoshow: true,
            screen_code: "SCN_FMS",
            page_code: "SCN_FMS",
            page_type: "edit_screen",
          },
          component: () => import("../views/FMS/FmsMaster/price_mstr_view.vue"),
        },
        {
          path: "/vendor_mstr",
          name: "vendor_mstr",
          meta: {
            auth: true,
            iscommontoshow: true,
            isnoadmintoshow: true,
            screen_code: "SCN_FMS",
            page_code: "SCN_FMS",
            page_type: "landing",
          },
          component: () => import("../views/FMS/FmsMaster/vendor_mstr.vue"),
        },
        {
          path: "/vendor_mstr_add",
          name: "vendor_mstr_add",
          meta: {
            auth: true,
            iscommontoshow: true,
            isnoadmintoshow: true,
            screen_code: "SCN_FMS",
            page_code: "SCN_FMS",
            page_type: "add_screen",
          },
          component: () => import("../views/FMS/FmsMaster/vendor_mstr_add.vue"),
        },
        {
          path: "/vendor_mstr_edit/:id",
          name: "vendor_mstr_edit",
          meta: {
            auth: true,
            iscommontoshow: true,
            isnoadmintoshow: true,
            screen_code: "SCN_FMS",
            page_code: "SCN_FMS",
            page_type: "edit_screen",
          },
          component: () => import("../views/FMS/FmsMaster/vendor_mstr_edit.vue"),
        },
        {
          path: "/mr_type_landing",
          name: "mr_type",
          meta: {
            auth: true,
            iscommontoshow: true,
            isnoadmintoshow: true,
            screen_code: "SCN_FMS",
            page_code: "SCN_FMS",
            page_type: "landing",
          },
          component: () => import("../views/FMS/FmsMaster/mr_type_landing.vue"),
        },
        {
          path: "/mr_type_add",
          name: "mr_type_add",
          meta: {
            auth: true,
            iscommontoshow: true,
            isnoadmintoshow: true,
            screen_code: "SCN_FMS",
            page_code: "SCN_FMS",
            page_type: "add_screen",
          },
          component: () => import("../views/FMS/FmsMaster/mr_type_add.vue"),
        },
        {
          path: "/mr_type_edit/:id",
          name: "mr_type_edit",
          meta: {
            auth: true,
            iscommontoshow: true,
            isnoadmintoshow: true,
            screen_code: "SCN_FMS",
            page_code: "SCN_FMS",
            page_type: "edit_screen",
          },
          component: () => import("../views/FMS/FmsMaster/mr_type_edit.vue"),
        },
        {
          path: "/root_cause_mstr",
          name: "root_cause_mstr",
          meta: {
            auth: true,
            iscommontoshow: true,
            isnoadmintoshow: true,
            screen_code: "SCN_FMS",
            page_code: "SCN_FMS",
            page_type: "landing",
          },
          component: () => import("../views/FMS/FmsMaster/root_cause_mstr.vue"),
        },
        {
          path: "/root_cause_mstr_add",
          name: "root_cause_mstr_add",
          meta: {
            auth: true,
            iscommontoshow: true,
            isnoadmintoshow: true,
            screen_code: "SCN_FMS",
            page_code: "SCN_FMS",
            page_type: "add_screen",
          },
          component: () => import("../views/FMS/FmsMaster/root_cause_mstr_add.vue"),
        },
        {
          path: "/root_cause_mstr_edit/:id",
          name: "root_cause_mstr_edit",
          meta: {
            auth: true,
            iscommontoshow: true,
            isnoadmintoshow: true,
            screen_code: "SCN_FMS",
            page_code: "SCN_FMS",
            page_type: "edit_screen",
          },
          component: () => import("../views/FMS/FmsMaster/root_cause_mstr_edit.vue"),
        },
        {
          path: "/mode_of_transport_mstr",
          name: "mode_of_transport_mstr",
          meta: {
            auth: true,
            iscommontoshow: true,
            isnoadmintoshow: true,
            screen_code: "SCN_FMS",
            page_code: "SCN_FMS",
            page_type: "landing",
          },
          component: () => import("../views/FMS/FmsMaster/mode_of_transport.vue"),
        },
        {
          path: "/mode_of_transport_mstr_add",
          name: "mode_of_transport_mstr_add",
          meta: {
            auth: true,
            iscommontoshow: true,
            isnoadmintoshow: true,
            screen_code: "SCN_FMS",
            page_code: "SCN_FMS",
            page_type: "add_screen",
          },
          component: () => import("../views/FMS/FmsMaster/mode_of_transport_add.vue"),
        },
        {
          path: "/mode_of_transport_mstr_edit/:id",
          name: "mode_of_transport_mstr_edit",
          meta: {
            auth: true,
            iscommontoshow: true,
            isnoadmintoshow: true,
            screen_code: "SCN_FMS",
            page_code: "SCN_FMS",
            page_type: "edit_screen",
          },
          component: () => import("../views/FMS/FmsMaster/mode_of_transport_edit.vue"),
        },
        {
          path: "/action_taken_mstr",
          name: "action_taken_mstr",
          meta: {
            auth: true,
            iscommontoshow: true,
            isnoadmintoshow: true,
            screen_code: "SCN_FMS",
            page_code: "SCN_FMS",
            page_type: "landing",
          },
          component: () => import("../views/FMS/FmsMaster/action_taken_mstr.vue"),
        },
        {
          path: "/action_taken_mstr_add",
          name: "action_taken_mstr_add",
          meta: {
            auth: true,
            iscommontoshow: true,
            isnoadmintoshow: true,
            screen_code: "SCN_FMS",
            page_code: "SCN_FMS",
            page_type: "add_screen",
          },
          component: () => import("../views/FMS/FmsMaster/action_taken_add.vue"),
        },
        {
          path: "/action_taken_mstr_edit/:id",
          name: "action_taken_mstr_edit",
          meta: {
            auth: true,
            iscommontoshow: true,
            isnoadmintoshow: true,
            screen_code: "SCN_FMS",
            page_code: "SCN_FMS",
            page_type: "edit_screen",
          },
          component: () => import("../views/FMS/FmsMaster/action_taken_edit.vue"),
        },
        {
          path: "/item_mstr_excel_upload",
          name: "item_mstr_excel_upload",
          meta: {
            auth: true,
            iscommontoshow: true,
            isnoadmintoshow: true,
            screen_code: "SCN_FMS",
            page_code: "SCN_FMS",
            page_type: "add_screen",
          },
          component: () =>
            import("../views/FMS/FmsMaster/item_mstr_excel_upload.vue"),
        },
        {
          path: "/shelf_life_landing",
          name: "shelf_life_landing",
          meta: {
            auth: true,
            iscommontoshow: true,
            isnoadmintoshow: true,
            screen_code: "SCN_FMS",
            page_code: "SCN_FMS",
            page_type: "landing",
          },
          component: () =>
            import("../views/FMS/FmsMaster/shelf_life_landing.vue"),
        },
        {
          path: "/shelf_life_landing/add_edit",
          name: "shelf_life_add_edit",
          meta: {
            auth: true,
            iscommontoshow: true,
            isnoadmintoshow: true,
            screen_code: "SCN_FMS",
            page_code: "SCN_FMS",
            page_type: "add_screen",
          },
          component: () =>
            import("../views/FMS/FmsMaster/shelf_life_add_edit.vue"),
        },
        {
          path: "/item_type_landing",
          name: "item_type_landing",
          meta: {
            auth: true,
            iscommontoshow: true,
            isnoadmintoshow: true,
            screen_code: "SCN_FMS",
            page_code: "SCN_FMS",
            page_type: "landing",
          },
          component: () =>
            import("../views/FMS/FmsMaster/item_type_landing.vue"),
        },
        {
          path: "/item_type_landing/add_edit",
          name: "item_type_add_edit",
          meta: {
            auth: true,
            iscommontoshow: true,
            isnoadmintoshow: true,
            screen_code: "SCN_FMS",
            page_code: "SCN_FMS",
            page_type: "add_screen",
          },
          component: () =>
            import("../views/FMS/FmsMaster/item_type_add_edit.vue"),
        },
        {
          path: "/visit_schedule_mstr",
          name: "visit_schedule_mstr",
          meta: {
            auth: true,
            iscommontoshow: true,
            isnoadmintoshow: true,
            screen_code: "SCN_FMS",
            page_code: "SCN_FMS",
            page_type: "landing",
          },
          component: () =>
            import("../views/FMS/FmsMaster/visit_schedule_mstr.vue"),
        },
        {
          path: "/visit_schedule_mstr/add",
          name: "visit_schedule_mstr_add",
          meta: {
            auth: true,
            iscommontoshow: true,
            isnoadmintoshow: true,
            screen_code: "SCN_FMS",
            page_code: "SCN_FMS",
            page_type: "add_screen",
          },
          component: () =>
            import("../views/FMS/FmsMaster/visit_schedule_mstradd.vue"),
        },

        {
          path: "/visit_schedule_mstr/edit/:id",
          name: "visit_schedule_mstr_edit",
          meta: {
            auth: true,
            iscommontoshow: true,
            isnoadmintoshow: true,
            screen_code: "SCN_FMS",
            page_code: "SCN_FMS",
            page_type: "edit_screen",
          },
          component: () =>
            import("../views/FMS/FmsMaster/visit_schedule_mstredit.vue"),
        },
        {
          path: "/policy_type_mstr",
          name: "policy_type_mstr",
          meta: {
            auth: true,
            iscommontoshow: true,
            isnoadmintoshow: true,
            screen_code: "SCN_FMS",
            page_code: "SCN_FMS",
            page_type: "landing",
          },
          component: () =>
            import("../views/FMS/FmsMaster/policy_type_mstr.vue"),
        },
        {
          path: "/policy_type_mstr/add",
          name: "policy_type_mstradd",
          meta: {
            auth: true,
            iscommontoshow: true,
            isnoadmintoshow: true,
            screen_code: "SCN_FMS",
            page_code: "SCN_FMS",
            page_type: "landing",
          },
          component: () =>
            import("../views/FMS/FmsMaster/policy_type_mstradd.vue"),
        },
        {
          path: "/policy_type_mstr/edit/:id",
          name: "policy_type_mstredit",
          meta: {
            auth: true,
            iscommontoshow: true,
            isnoadmintoshow: true,
            screen_code: "SCN_FMS",
            page_code: "SCN_FMS",
            page_type: "landing",
          },
          component: () =>
            import("../views/FMS/FmsMaster/policy_type_mstredit.vue"),
        },
        {
          path: "/TicketDefinition",
          name: "TicketDefinition",
          meta: {
            auth: true,
            iscommontoshow: true,
            isnoadmintoshow: true,
            screen_code: "SCN_FMS",
            page_code: "SCN_FMS",
            page_type: "landing",
          },
          component: () =>
            import("../views/FMS/ticket_definition/ticket_definition.vue"),
        },
        {
          path: "/TicketDefinition/add",
          name: "TicketDefinitionAdd",
          meta: {
            auth: true,
            iscommontoshow: true,
            isnoadmintoshow: true,
            screen_code: "SCN_FMS",
            page_code: "SCN_FMS",
            page_type: "landing",
          },
          component: () =>
            import("../views/FMS/ticket_definition/ticket_definition_add.vue"),
        },
        {
          path: "/TicketDefinition/edit/:id",
          name: "TicketDefinitionEdit",
          meta: {
            auth: true,
            iscommontoshow: true,
            isnoadmintoshow: true,
            screen_code: "SCN_FMS",
            page_code: "SCN_FMS",
            page_type: "landing",
          },
          component: () =>
            import("../views/FMS/ticket_definition/ticket_definition_edit.vue"),
        },
        {
          path: "/policy_definition",
          name: "policy_definition",
          meta: {
            auth: true,
            iscommontoshow: true,
            isnoadmintoshow: true,
            screen_code: "SCN_FMS",
            page_code: "SCN_FMS",
            page_type: "landing",
          },
          component: () => import("../views/FMS/policy_definition_new.vue"),
        },
        {
          path: "/policy_definition/add",
          name: "policy_definition_add",
          meta: {
            auth: true,
            iscommontoshow: true,
            isnoadmintoshow: true,
            screen_code: "SCN_FMS",
            page_code: "SCN_FMS",
            page_type: "add_screen",
          },
          component: () => import("../views/FMS/policy_definition_add.vue"),
        },

        {
          path: "/policy_definition/edit/:id",
          name: "policy_definition_edit",
          meta: {
            auth: true,
            iscommontoshow: true,
            isnoadmintoshow: true,
            screen_code: "SCN_FMS",
            page_code: "SCN_FMS",
            page_type: "edit_screen",
          },
          component: () => import("../views/FMS/policy_definition_edit.vue"),
        },
        {
          path: "/attribute_lookup",
          name: "attribute_lookup",
          meta: {
            auth: true,
            iscommontoshow: true,
            isnoadmintoshow: true,
            screen_code: "SCN_FMS",
            page_code: "SCN_FMS",
            page_type: "landing",
          },
          component: () =>
            import("../views/FMS/jobhistory/attribute_lookup.vue"),
        },
        {
          path: "/attribute_lookup_add",
          name: "attribute_lookup_add",
          meta: {
            auth: true,
            iscommontoshow: true,
            isnoadmintoshow: true,
            screen_code: "SCN_FMS",
            page_code: "SCN_FMS",
            page_type: "add_screen",
          },
          component: () =>
            import("../views/FMS/jobhistory/attribute_lookup_add.vue"),
        },

        {
          path: "/attribute_lookup_edit/:id",
          name: "attribute_lookup_edit",
          meta: {
            auth: true,
            iscommontoshow: true,
            isnoadmintoshow: true,
            screen_code: "SCN_FMS",
            page_code: "SCN_FMS",
            page_type: "edit_screen",
          },
          component: () =>
            import("../views/FMS/jobhistory/attribute_lookup_edit.vue"),
        },
        {
          path: "/attribute_lookup_type",
          name: "attribute_lookup_type",
          meta: {
            auth: true,
            iscommontoshow: true,
            isnoadmintoshow: true,
            screen_code: "SCN_FMS",
            page_code: "SCN_FMS",
            page_type: "landing",
          },
          component: () =>
            import("../views/FMS/jobhistory/attribute_lookup_type.vue"),
        },
        {
          path: "/attribute_lookup_type_add",
          name: "attribute_lookup_type_add",
          meta: {
            auth: true,
            iscommontoshow: true,
            isnoadmintoshow: true,
            screen_code: "SCN_FMS",
            page_code: "SCN_FMS",
            page_type: "add_screen",
          },
          component: () =>
            import("../views/FMS/jobhistory/attribute_lookup_type_add.vue"),
        },

        {
          path: "/attribute_lookup_type_edit/:id",
          name: "attribute_lookup_type_edit",
          meta: {
            auth: true,
            iscommontoshow: true,
            isnoadmintoshow: true,
            screen_code: "SCN_FMS",
            page_code: "SCN_FMS",
            page_type: "edit_screen",
          },
          component: () =>
            import("../views/FMS/jobhistory/attribute_lookup_type_edit.vue"),
        },
        {
          path: "/attribute_lookup_group",
          name: "attribute_lookup_group",
          meta: {
            auth: true,
            iscommontoshow: true,
            isnoadmintoshow: true,
            screen_code: "SCN_FMS",
            page_code: "SCN_FMS",
            page_type: "landing",
          },
          component: () =>
            import("../views/FMS/jobhistory/attribute_lookup_group.vue"),
        },
        {
          path: "/attribute_lookup_group/add",
          name: "attribute_lookup_group_add",
          meta: {
            auth: true,
            iscommontoshow: true,
            isnoadmintoshow: true,
            screen_code: "SCN_FMS",
            page_code: "SCN_FMS",
            page_type: "add_screen",
          },
          component: () =>
            import("../views/FMS/jobhistory/attribute_lookup_group_add.vue"),
        },

        {
          path: "/attribute_lookup_group_edit/:id",
          name: "attribute_lookup_group_edit",
          meta: {
            auth: true,
            iscommontoshow: true,
            isnoadmintoshow: true,
            screen_code: "SCN_FMS",
            page_code: "SCN_FMS",
            page_type: "edit_screen",
          },
          component: () =>
            import("../views/FMS/jobhistory/attribute_lookup_group_edit.vue"),
        },
        {
          path: "/building",
          name: "building",
          meta: {
            auth: true,
            iscommontoshow: true,
            isnoadmintoshow: true,
            screen_code: "SCN_FMS",
            page_code: "SCN_FMS",
            page_type: "landing",
          },
          component: () => import("../views/FMS/building/building.vue"),
        },
        {
          path: "/building/add",
          name: "buildingAdd",
          meta: {
            auth: true,
            iscommontoshow: true,
            isnoadmintoshow: true,
            screen_code: "SCN_FMS",
            page_code: "SCN_FMS",
            page_type: "add_screen",
          },
          component: () => import("../views/FMS/building/building_add.vue"),
        },
        {
          path: "/building/edit/:id",
          name: "buildingEdit",
          meta: {
            auth: true,
            iscommontoshow: true,
            isnoadmintoshow: true,
            screen_code: "SCN_FMS",
            page_code: "SCN_FMS",
            page_type: "add_screen",
          },
          component: () => import("../views/FMS/building/building_edit.vue"),
        },
        {
          path: "/building/view/:id",
          name: "buildingView",
          meta: {
            auth: true,
            iscommontoshow: true,
            isnoadmintoshow: true,
            screen_code: "SCN_FMS",
            page_code: "SCN_FMS",
            page_type: "add_screen",
          },
          component: () => import("../views/FMS/building/building_view.vue"),
        },
        {
          path: "/customer_dtl",
          name: "customer",
          meta: {
            auth: true,
            iscommontoshow: true,
            isnoadmintoshow: true,
            screen_code: "SCN_FMS",
            page_code: "SCN_FMS",
            page_type: "landing",
          },
          component: () => import("../views/FMS/customer/customer.vue"),
        },
        {
          path: "/customer_dtl/add",
          name: "customer_add",
          meta: {
            auth: true,
            iscommontoshow: true,
            isnoadmintoshow: true,
            screen_code: "SCN_FMS",
            page_code: "SCN_FMS",
            page_type: "add_screen",
          },
          component: () => import("../views/FMS/customer/customer_add.vue"),
        },
        {
          path: "/customer_dtl/edit/:id",
          name: "customer_edit",
          meta: {
            auth: true,
            iscommontoshow: true,
            isnoadmintoshow: true,
            screen_code: "SCN_FMS",
            page_code: "SCN_FMS",
            page_type: "edit_screen",
          },
          component: () => import("../views/FMS/customer/customer_edit.vue"),
        },
        {
          path: "/customer_dtl/view/:id",
          name: "customer_view",
          meta: {
            auth: true,
            iscommontoshow: true,
            isnoadmintoshow: true,
            screen_code: "SCN_FMS",
            page_code: "SCN_FMS",
            page_type: "view_screen",
          },
          component: () =>
            // import ('../views/FMS/customer/customer_view_new.vue')
            import("../views/FMS/customer/customer_view.vue"),
        },

        {
          path: "/lift",
          name: "lift",
          meta: {
            auth: true,
            iscommontoshow: true,
            isnoadmintoshow: true,
            screen_code: "SCN_FMS",
            page_code: "SCN_FMS",
            page_type: "landing",
          },
          component: () => import("../views/FMS/lift/lift_landing/lift.vue"),
        },
        {
          path: "/lift_add",
          name: "lift_add",
          meta: {
            auth: true,
            iscommontoshow: true,
            isnoadmintoshow: true,
            screen_code: "SCN_FMS",
            page_code: "SCN_FMS",
            page_type: "add_screen",
          },
          component: () => import("../views/FMS/lift/lift_add.vue"),
        },

        {
          path: "/lift_edit/:id",
          name: "lift_edit",
          meta: {
            auth: true,
            iscommontoshow: true,
            isnoadmintoshow: true,
            screen_code: "SCN_FMS",
            page_code: "SCN_FMS",
            page_type: "edit_screen",
          },
          component: () => import("../views/FMS/lift/lift_edit.vue"),
        },
        {
          path: "/lift_copy/:id",
          name: "lift_copy",
          meta: {
            auth: true,
            iscommontoshow: true,
            isnoadmintoshow: true,
            screen_code: "SCN_FMS",
            page_code: "SCN_FMS",
            page_type: "edit_screen",
          },
          component: () => import("../views/FMS/lift/lift_edit.vue"),
        },
        {
          path: "/lift_view/:id",
          name: "lift_view",
          meta: {
            auth: true,
            iscommontoshow: true,
            isnoadmintoshow: true,
            screen_code: "SCN_FMS",
            page_code: "SCN_FMS",
            page_type: "view_screen",
          },
          component: () => import("../views/FMS/lift/lift_view.vue"),
        },

        {
          path: "/bulk_lift_transfer",
          name: "bulk_lift_transfer",
          meta: {
            auth: true,
            iscommontoshow: true,
            isnoadmintoshow: true,
            screen_code: "SCN_FMS",
            page_code: "SCN_FMS",
            page_type: "transfer_screen",
          },
          component: () => import("../views/FMS/lift/bulk_lift_transfer.vue"),
        },
        {
          path: "/lift_transfer/:id",
          name: "lift_transfer",
          meta: {
            auth: true,
            iscommontoshow: true,
            isnoadmintoshow: true,
            screen_code: "SCN_FMS",
            page_code: "SCN_FMS",
            page_type: "transfer_screen",
          },
          component: () => import("../views/FMS/lift/lift_transfer.vue"),
        },
        {
          path: "/callFunctionType",
          name: "callFunctionType",
          meta: {
            auth: true,
            iscommontoshow: true,
            isnoadmintoshow: true,
            screen_code: "SCN_FMS",
            page_code: "SCN_FMS",
            page_type: "landing",
          },
          component: () =>
            import("../views/FMS/FmsMaster/call_function_type.vue"),
        },
        {
          path: "/callFunctionType/add",
          name: "callFunctionType_add",
          meta: {
            auth: true,
            iscommontoshow: true,
            isnoadmintoshow: true,
            screen_code: "SCN_FMS",
            page_code: "SCN_FMS",
            page_type: "add_screen",
          },
          component: () =>
            import("../views/FMS/FmsMaster/call_function_type_add.vue"),
        },

        {
          path: "/callFunctionType/edit/:id",
          name: "callFunctionType_edit",
          meta: {
            auth: true,
            iscommontoshow: true,
            isnoadmintoshow: true,
            screen_code: "SCN_FMS",
            page_code: "SCN_FMS",
            page_type: "edit_screen",
          },
          component: () =>
            import("../views/FMS/FmsMaster/call_function_type_edit.vue"),
        },
        {
          path: "/drive_type_mstr",
          name: "drive_type_mstr",
          meta: {
            auth: true,
            iscommontoshow: true,
            isnoadmintoshow: true,
            screen_code: "SCN_FMS",
            page_code: "SCN_FMS",
            page_type: "landing",
          },
          component: () => import("../views/FMS/FmsMaster/drive_type_mstr.vue"),
        },
        {
          path: "/drive_type_mstr/add",
          name: "drive_type_mstr_add",
          meta: {
            auth: true,
            iscommontoshow: true,
            isnoadmintoshow: true,
            screen_code: "SCN_FMS",
            page_code: "SCN_FMS",
            page_type: "add_screen",
          },
          component: () =>
            import("../views/FMS/FmsMaster/drive_type_mstradd.vue"),
        },

        {
          path: "/drive_type_mstr/edit/:id",
          name: "drive_type_mstr_edit",
          meta: {
            auth: true,
            iscommontoshow: true,
            isnoadmintoshow: true,
            screen_code: "SCN_FMS",
            page_code: "SCN_FMS",
            page_type: "edit_screen",
          },
          component: () =>
            import("../views/FMS/FmsMaster/drive_type_mstredit.vue"),
        },
        {
          path: "/highway_landing_type_mstr",
          name: "highway_landing_type_mstr",
          meta: {
            auth: true,
            iscommontoshow: true,
            isnoadmintoshow: true,
            screen_code: "SCN_FMS",
            page_code: "SCN_FMS",
            page_type: "landing",
          },
          component: () =>
            import("../views/FMS/FmsMaster/highway_landing_type_mstr.vue"),
        },
        {
          path: "/highway_landing_type_mstr_add",
          name: "highway_landing_type_mstr_add",
          meta: {
            auth: true,
            iscommontoshow: true,
            isnoadmintoshow: true,
            screen_code: "SCN_FMS",
            page_code: "SCN_FMS",
            page_type: "add_screen",
          },
          component: () =>
            import("../views/FMS/FmsMaster/highway_landing_type_mstr_add.vue"),
        },
        {
          path: "/highway_landing_type_mstr_edit/:id",
          name: "highway_landing_type_mstr_edit",
          meta: {
            auth: true,
            iscommontoshow: true,
            isnoadmintoshow: true,
            screen_code: "SCN_FMS",
            page_code: "SCN_FMS",
            page_type: "edit_screen",
          },
          component: () =>
            import("../views/FMS/FmsMaster/highway_landing_type_mstr_edit.vue"),
        },
        {
          path: "/deduction",
          name: "deduction",
          meta: {
            auth: true,
            iscommontoshow: true,
            isnoadmintoshow: true,
            screen_code: "SCN_FMS",
            page_code: "SCN_FMS",
            page_type: "landing",
          },
          component: () => import("../views/FMS/FmsMaster/deduction.vue"),
        },
        {
          path: "/deduction_add",
          name: "deduction_add",
          meta: {
            auth: true,
            iscommontoshow: true,
            isnoadmintoshow: true,
            screen_code: "SCN_FMS",
            page_code: "SCN_FMS",
            page_type: "add_screen",
          },
          component: () => import("../views/FMS/FmsMaster/deduction_add.vue"),
        },
        {
          path: "/deduction_edit/:id",
          name: "deduction_edit",
          meta: {
            auth: true,
            iscommontoshow: true,
            isnoadmintoshow: true,
            screen_code: "SCN_FMS",
            page_code: "SCN_FMS",
            page_type: "edit_screen",
          },
          component: () => import("../views/FMS/FmsMaster/deduction_edit.vue"),
        },
        {
          path: "/bank_mstr",
          name: "bank_mstr",
          meta: {
            auth: true,
            iscommontoshow: true,
            isnoadmintoshow: true,
            screen_code: "SCN_FMS",
            page_code: "SCN_FMS",
            page_type: "landing",
          },
          component: () => import("../views/FMS/FmsMaster/bank_mstr.vue"),
        },
        {
          path: "/bank_mstradd",
          name: "bank_mstradd",
          meta: {
            auth: true,
            iscommontoshow: true,
            isnoadmintoshow: true,
            screen_code: "SCN_FMS",
            page_code: "SCN_FMS",
            page_type: "add_screen",
          },
          component: () => import("../views/FMS/FmsMaster/bank_mstradd.vue"),
        },
        {
          path: "/bank_mstredit/:id",
          name: "bank_mstredit",
          meta: {
            auth: true,
            iscommontoshow: true,
            isnoadmintoshow: true,
            screen_code: "SCN_FMS",
            page_code: "SCN_FMS",
            page_type: "edit_screen",
          },
          component: () => import("../views/FMS/FmsMaster/bank_mstredit.vue"),
        },
        {
          path: "/machineRoomPosition",
          name: "machineRoomPosition",
          meta: {
            auth: true,
            iscommontoshow: true,
            isnoadmintoshow: true,
            screen_code: "SCN_FMS",
            page_code: "SCN_FMS",
            page_type: "landing",
          },
          component: () =>
            import("../views/FMS/FmsMaster/machine_room_position.vue"),
        },
        {
          path: "/machineRoomPosition/add",
          name: "machineRoomPosition_add",
          meta: {
            auth: true,
            iscommontoshow: true,
            isnoadmintoshow: true,
            screen_code: "SCN_FMS",
            page_code: "SCN_FMS",
            page_type: "add_screen",
          },
          component: () =>
            import("../views/FMS/FmsMaster/machine_room_position_add.vue"),
        },

        {
          path: "/machineRoomPosition/edit/:id",
          name: "machineRoomPosition_edit",
          meta: {
            auth: true,
            iscommontoshow: true,
            isnoadmintoshow: true,
            screen_code: "SCN_FMS",
            page_code: "SCN_FMS",
            page_type: "edit_screen",
          },
          component: () =>
            import("../views/FMS/FmsMaster/machine_room_position_edit.vue"),
        },
        {
          path: "/payment_type_mstr",
          name: "payment_type_mstr",
          meta: {
            auth: true,
            iscommontoshow: true,
            isnoadmintoshow: true,
            screen_code: "SCN_FMS",
            page_code: "SCN_FMS",
            page_type: "landing",
          },
          component: () =>
            import("../views/FMS/FmsMaster/payment_type_mstr.vue"),
        },
        {
          path: "/payment_type_mstr/add",
          name: "payment_type_mstr_add",
          meta: {
            auth: true,
            iscommontoshow: true,
            isnoadmintoshow: true,
            screen_code: "SCN_FMS",
            page_code: "SCN_FMS",
            page_type: "add_screen",
          },
          component: () =>
            import("../views/FMS/FmsMaster/payment_type_mstradd.vue"),
        },

        {
          path: "/payment_type_mstr/edit/:id",
          name: "payment_type_mstr_edit",
          meta: {
            auth: true,
            iscommontoshow: true,
            isnoadmintoshow: true,
            screen_code: "SCN_FMS",
            page_code: "SCN_FMS",
            page_type: "edit_screen",
          },
          component: () =>
            import("../views/FMS/FmsMaster/payment_type_mstredit.vue"),
        },
        {
          path: "/payment_term_mstr",
          name: "payment_term_mstr",
          meta: {
            auth: true,
            iscommontoshow: true,
            isnoadmintoshow: true,
            screen_code: "SCN_FMS",
            page_code: "SCN_FMS",
            page_type: "landing",
          },
          component: () =>
            import("../views/FMS/FmsMaster/payment_term_mstr.vue"),
        },
        {
          path: "/payment_term_mstr/add",
          name: "payment_term_mstr_add",
          meta: {
            auth: true,
            iscommontoshow: true,
            isnoadmintoshow: true,
            screen_code: "SCN_FMS",
            page_code: "SCN_FMS",
            page_type: "add_screen",
          },
          component: () =>
            import("../views/FMS/FmsMaster/payment_term_mstradd.vue"),
        },

        {
          path: "/payment_term_mstr/edit/:id",
          name: "payment_term_mstr_edit",
          meta: {
            auth: true,
            iscommontoshow: true,
            isnoadmintoshow: true,
            screen_code: "SCN_FMS",
            page_code: "SCN_FMS",
            page_type: "edit_screen",
          },
          component: () =>
            import("../views/FMS/FmsMaster/payment_term_mstredit.vue"),
        },
        {
          path: "/motor_make_mstr",
          name: "motor_make_mstr",
          meta: {
            auth: true,
            iscommontoshow: true,
            isnoadmintoshow: true,
            screen_code: "SCN_FMS",
            page_code: "SCN_FMS",
            page_type: "landing",
          },
          component: () => import("../views/FMS/FmsMaster/motor_make_mstr.vue"),
        },
        {
          path: "/motor_make_mstr/add",
          name: "motor_make_mstr_add",
          meta: {
            auth: true,
            iscommontoshow: true,
            isnoadmintoshow: true,
            screen_code: "SCN_FMS",
            page_code: "SCN_FMS",
            page_type: "add_screen",
          },
          component: () =>
            import("../views/FMS/FmsMaster/motor_make_mstradd.vue"),
        },

        {
          path: "/motor_make_mstr/edit/:id",
          name: "motor_make_mstr_edit",
          meta: {
            auth: true,
            iscommontoshow: true,
            isnoadmintoshow: true,
            screen_code: "SCN_FMS",
            page_code: "SCN_FMS",
            page_type: "edit_screen",
          },
          component: () =>
            import("../views/FMS/FmsMaster/motor_make_mstredit.vue"),
        },
        {
          path: "/menu_access_mstr",
          name: "menu_access_mstr",
          meta: {
            auth: true,
            iscommontoshow: true,
            isnoadmintoshow: true,
            screen_code: "SCN_FMS",
            page_code: "SCN_FMS",
            page_type: "landing",
          },
          component: () =>
            import("../views/FMS/FmsMaster/menu_access_mstr.vue"),
        },
        {
          path: "/menu_access_mstr/add",
          name: "menu_access_mstr_add",
          meta: {
            auth: true,
            iscommontoshow: true,
            isnoadmintoshow: true,
            screen_code: "SCN_FMS",
            page_code: "SCN_FMS",
            page_type: "add_screen",
          },
          component: () =>
            import("../views/FMS/FmsMaster/menu_access_mstradd.vue"),
        },

        {
          path: "/menu_access_mstr/edit/:id",
          name: "menu_access_mstr_edit",
          meta: {
            auth: true,
            iscommontoshow: true,
            isnoadmintoshow: true,
            screen_code: "SCN_FMS",
            page_code: "SCN_FMS",
            page_type: "edit_screen",
          },
          component: () =>
            import("../views/FMS/FmsMaster/menu_access_mstredit.vue"),
        },
        {
          path: "/material_mstr",
          name: "material_mstr",
          meta: {
            auth: true,
            iscommontoshow: true,
            isnoadmintoshow: true,
            screen_code: "SCN_FMS",
            page_code: "SCN_FMS",
            page_type: "landing",
          },
          component: () => import("../views/FMS/FmsMaster/material_mstr.vue"),
        },
        {
          path: "/material_mstr/add",
          name: "material_mstr_add",
          meta: {
            auth: true,
            iscommontoshow: true,
            isnoadmintoshow: true,
            screen_code: "SCN_FMS",
            page_code: "SCN_FMS",
            page_type: "add_screen",
          },
          component: () =>
            import("../views/FMS/FmsMaster/material_mstradd.vue"),
        },

        {
          path: "/material_mstr/edit/:id",
          name: "material_mstr_edit",
          meta: {
            auth: true,
            iscommontoshow: true,
            isnoadmintoshow: true,
            screen_code: "SCN_FMS",
            page_code: "SCN_FMS",
            page_type: "edit_screen",
          },
          component: () =>
            import("../views/FMS/FmsMaster/material_mstredit.vue"),
        },

        {
          path: "/maindrive_make_mstr",
          name: "maindrive_make_mstr",
          meta: {
            auth: true,
            iscommontoshow: true,
            isnoadmintoshow: true,
            screen_code: "SCN_FMS",
            page_code: "SCN_FMS",
            page_type: "landing",
          },
          component: () =>
            import("../views/FMS/FmsMaster/maindrive_make_mstr.vue"),
        },
        {
          path: "/maindrive_make_mstr/add",
          name: "maindrive_make_mstr_add",
          meta: {
            auth: true,
            iscommontoshow: true,
            isnoadmintoshow: true,
            screen_code: "SCN_FMS",
            page_code: "SCN_FMS",
            page_type: "add_screen",
          },
          component: () =>
            import("../views/FMS/FmsMaster/maindrive_make_mstradd.vue"),
        },

        {
          path: "/maindrive_make_mstr/edit/:id",
          name: "maindrive_make_mstr_edit",
          meta: {
            auth: true,
            iscommontoshow: true,
            isnoadmintoshow: true,
            screen_code: "SCN_FMS",
            page_code: "SCN_FMS",
            page_type: "edit_screen",
          },
          component: () =>
            import("../views/FMS/FmsMaster/maindrive_make_mstredit.vue"),
        },
        {
          path: "/machgear_make_mstr",
          name: "machgear_make_mstr",
          meta: {
            auth: true,
            iscommontoshow: true,
            isnoadmintoshow: true,
            screen_code: "SCN_FMS",
            page_code: "SCN_FMS",
            page_type: "landing",
          },
          component: () =>
            import("../views/FMS/FmsMaster/machgear_make_mstr.vue"),
        },
        {
          path: "/machgear_make_mstr/add",
          name: "machgear_make_mstr_add",
          meta: {
            auth: true,
            iscommontoshow: true,
            isnoadmintoshow: true,
            screen_code: "SCN_FMS",
            page_code: "SCN_FMS",
            page_type: "add_screen",
          },
          component: () =>
            import("../views/FMS/FmsMaster/machgear_make_mstradd.vue"),
        },

        {
          path: "/machgear_make_mstr/edit/:id",
          name: "machgear_make_mstr_edit",
          meta: {
            auth: true,
            iscommontoshow: true,
            isnoadmintoshow: true,
            screen_code: "SCN_FMS",
            page_code: "SCN_FMS",
            page_type: "edit_screen",
          },
          component: () =>
            import("../views/FMS/FmsMaster/machgear_make_mstredit.vue"),
        },
        {
          path: "/inverter_make_mstr",
          name: "inverter_make_mstr",
          meta: {
            auth: true,
            iscommontoshow: true,
            isnoadmintoshow: true,
            screen_code: "SCN_FMS",
            page_code: "SCN_FMS",
            page_type: "landing",
          },
          component: () =>
            import("../views/FMS/FmsMaster/inverter_make_mstr.vue"),
        },
        {
          path: "/inverter_make_mstr/add",
          name: "inverter_make_mstr_add",
          meta: {
            auth: true,
            iscommontoshow: true,
            isnoadmintoshow: true,
            screen_code: "SCN_FMS",
            page_code: "SCN_FMS",
            page_type: "add_screen",
          },
          component: () =>
            import("../views/FMS/FmsMaster/inverter_make_mstradd.vue"),
        },

        {
          path: "/inverter_make_mstr/edit/:id",
          name: "inverter_make_mstr_edit",
          meta: {
            auth: true,
            iscommontoshow: true,
            isnoadmintoshow: true,
            screen_code: "SCN_FMS",
            page_code: "SCN_FMS",
            page_type: "edit_screen",
          },
          component: () =>
            import("../views/FMS/FmsMaster/inverter_make_mstredit.vue"),
        },
        {
          path: "/instrument_type_mstr",
          name: "instrument_type_mstr",
          meta: {
            auth: true,
            iscommontoshow: true,
            isnoadmintoshow: true,
            screen_code: "SCN_FMS",
            page_code: "SCN_FMS",
            page_type: "landing",
          },
          component: () =>
            import("../views/FMS/FmsMaster/instrument_type_mstr.vue"),
        },
        {
          path: "/instrument_type_mstr/add",
          name: "instrument_type_mstr_add",
          meta: {
            auth: true,
            iscommontoshow: true,
            isnoadmintoshow: true,
            screen_code: "SCN_FMS",
            page_code: "SCN_FMS",
            page_type: "add_screen",
          },
          component: () =>
            import("../views/FMS/FmsMaster/instrument_type_mstradd.vue"),
        },

        {
          path: "/instrument_type_mstr/edit/:id",
          name: "instrument_type_mstr_edit",
          meta: {
            auth: true,
            iscommontoshow: true,
            isnoadmintoshow: true,
            screen_code: "SCN_FMS",
            page_code: "SCN_FMS",
            page_type: "edit_screen",
          },
          component: () =>
            import("../views/FMS/FmsMaster/instrument_type_mstredit.vue"),
        },
        {
          path: "/esclation_time_mstr",
          name: "esclation_time_mstr",
          meta: {
            auth: true,
            iscommontoshow: true,
            isnoadmintoshow: true,
            screen_code: "SCN_FMS",
            page_code: "SCN_FMS",
            page_type: "landing",
          },
          component: () =>
            import("../views/FMS/FmsMaster/esclation_time_mstr.vue"),
        },
        {
          path: "/esclation_time_mstr/add",
          name: "esclation_time_mstr_add",
          meta: {
            auth: true,
            iscommontoshow: true,
            isnoadmintoshow: true,
            screen_code: "SCN_FMS",
            page_code: "SCN_FMS",
            page_type: "add_screen",
          },
          component: () =>
            import("../views/FMS/FmsMaster/esclation_time_mstradd.vue"),
        },

        {
          path: "/esclation_time_mstr/edit/:id",
          name: "esclation_time_mstr_edit",
          meta: {
            auth: true,
            iscommontoshow: true,
            isnoadmintoshow: true,
            screen_code: "SCN_FMS",
            page_code: "SCN_FMS",
            page_type: "edit_screen",
          },
          component: () =>
            import("../views/FMS/FmsMaster/esclation_time_mstredit.vue"),
        },
        {
          path: "/esclation_percentage_mstr",
          name: "esclation_percentage_mstr",
          meta: {
            auth: true,
            iscommontoshow: true,
            isnoadmintoshow: true,
            screen_code: "SCN_FMS",
            page_code: "SCN_FMS",
            page_type: "landing",
          },
          component: () =>
            import("../views/FMS/FmsMaster/esclation_percentage_mstr.vue"),
        },
        {
          path: "/esclation_percentage_mstr/add",
          name: "esclation_percentage_mstr_add",
          meta: {
            auth: true,
            iscommontoshow: true,
            isnoadmintoshow: true,
            screen_code: "SCN_FMS",
            page_code: "SCN_FMS",
            page_type: "add_screen",
          },
          component: () =>
            import("../views/FMS/FmsMaster/esclation_percentage_mstradd.vue"),
        },

        {
          path: "/esclation_percentage_mstr/edit/:id",
          name: "esclation_percentage_mstr_edit",
          meta: {
            auth: true,
            iscommontoshow: true,
            isnoadmintoshow: true,
            screen_code: "SCN_FMS",
            page_code: "SCN_FMS",
            page_type: "edit_screen",
          },
          component: () =>
            import("../views/FMS/FmsMaster/esclation_percentage_mstredit.vue"),
        },
        {
          path: "/encoder_model_mstr",
          name: "encoder_model_mstr",
          meta: {
            auth: true,
            iscommontoshow: true,
            isnoadmintoshow: true,
            screen_code: "SCN_FMS",
            page_code: "SCN_FMS",
            page_type: "landing",
          },
          component: () =>
            import("../views/FMS/FmsMaster/encoder_model_mstr.vue"),
        },
        {
          path: "/encoder_model_mstr/add",
          name: "encoder_model_mstr_add",
          meta: {
            auth: true,
            iscommontoshow: true,
            isnoadmintoshow: true,
            screen_code: "SCN_FMS",
            page_code: "SCN_FMS",
            page_type: "add_screen",
          },
          component: () =>
            import("../views/FMS/FmsMaster/encoder_model_mstradd.vue"),
        },

        {
          path: "/encoder_model_mstr/edit/:id",
          name: "encoder_model_mstr_edit",
          meta: {
            auth: true,
            iscommontoshow: true,
            isnoadmintoshow: true,
            screen_code: "SCN_FMS",
            page_code: "SCN_FMS",
            page_type: "edit_screen",
          },
          component: () =>
            import("../views/FMS/FmsMaster/encoder_model_mstredit.vue"),
        },
        {
          path: "/encoder_make_mstr",
          name: "encoder_make_mstr",
          meta: {
            auth: true,
            iscommontoshow: true,
            isnoadmintoshow: true,
            screen_code: "SCN_FMS",
            page_code: "SCN_FMS",
            page_type: "landing",
          },
          component: () =>
            import("../views/FMS/FmsMaster/encoder_make_mstr.vue"),
        },
        {
          path: "/encoder_make_mstr/add",
          name: "encoder_make_mstr_add",
          meta: {
            auth: true,
            iscommontoshow: true,
            isnoadmintoshow: true,
            screen_code: "SCN_FMS",
            page_code: "SCN_FMS",
            page_type: "add_screen",
          },
          component: () =>
            import("../views/FMS/FmsMaster/encoder_make_mstradd.vue"),
        },

        {
          path: "/encoder_make_mstr/edit/:id",
          name: "encoder_make_mstr_edit",
          meta: {
            auth: true,
            iscommontoshow: true,
            isnoadmintoshow: true,
            screen_code: "SCN_FMS",
            page_code: "SCN_FMS",
            page_type: "edit_screen",
          },
          component: () =>
            import("../views/FMS/FmsMaster/encoder_make_mstredit.vue"),
        },
        {
          path: "/doordrive_make_mstr",
          name: "doordrive_make_mstr",
          meta: {
            auth: true,
            iscommontoshow: true,
            isnoadmintoshow: true,
            screen_code: "SCN_FMS",
            page_code: "SCN_FMS",
            page_type: "landing",
          },
          component: () =>
            import("../views/FMS/FmsMaster/doordrive_make_mstr.vue"),
        },
        {
          path: "/doordrive_make_mstr/add",
          name: "doordrive_make_mstr_add",
          meta: {
            auth: true,
            iscommontoshow: true,
            isnoadmintoshow: true,
            screen_code: "SCN_FMS",
            page_code: "SCN_FMS",
            page_type: "add_screen",
          },
          component: () =>
            import("../views/FMS/FmsMaster/doordrive_make_mstradd.vue"),
        },

        {
          path: "/doordrive_make_mstr/edit/:id",
          name: "doordrive_make_mstr_edit",
          meta: {
            auth: true,
            iscommontoshow: true,
            isnoadmintoshow: true,
            screen_code: "SCN_FMS",
            page_code: "SCN_FMS",
            page_type: "edit_screen",
          },
          component: () =>
            import("../views/FMS/FmsMaster/doordrive_make_mstredit.vue"),
        },
        {
          path: "/employee_field_setting",
          name: "Filed Settings",
          meta: {
            auth: true,
            screen_code: "CREATE_MASTERS",
            page_code: "SCN_PERFORMANCE_RATING",
            page_type: "landing",
          },
          component: () => import("../views/masters/employee_filed_rights.vue"),
        },
        {
          path: "/fms/tickets",
          name: "fms_tickets",
          meta: {
            auth: true,
            screen_code: "CREATE_MASTERS",
            page_code: "SCN_FMS_TICKETS",
            page_type: "landing",
          },
          component: () => import("../views/FMS/fms_ticket/fms_tickets.vue"),
        },
        {
          path: "/fms/edit_ticket/:id",
          name: "edit_ticket",
          meta: {
            auth: true,
            screen_code: "CREATE_MASTERS",
            page_code: "SCN_FMS_TICKETS",
            page_type: "landing",
          },
          component: () => import("../views/FMS/fms_ticket/edit_ticket.vue"),
        },
        {
          path: "/fms/view_assign_tkt_new",
          name: "view_assign_tkt_new",
          meta: {
            auth: true,
            screen_code: "CREATE_MASTERS",
            page_code: "SCN_FMS_TICKETS",
            page_type: "landing",
          },
          component: () =>
            import("../views/FMS/fms_ticket/view_assign_tkt_new.vue"),
        },
        {
          path: "/raise_tickets",
          name: "Raise_Tickets",
          meta: {
            auth: true,
            screen_code: "CREATE_MASTERS",
            page_code: "SCN_FMS_TICKETS",
            page_type: "landing",
          },
          component: () => import("../views/FMS/fms_ticket/raise_tickets.vue"),
        },
        {
          path: "/scheduled_tickets",
          name: "scheduled_tickets",
          meta: {
            auth: true,
            screen_code: "CREATE_MASTERS",
            page_code: "SCN_FMS_SCHEDULED_TICKETS",
            page_type: "landing",
          },
          component: () => import("../views/FMS/fms_ticket/scheduled_tickets.vue"),
        },
        {
          path: "/scheduled_tickets_count",
          name: "scheduled_tickets_count",
          meta: {
            auth: true,
            screen_code: "CREATE_MASTERS",
            page_code: "SCN_FMS_SCHEDULED_TICKETS",
            page_type: "landing",
          },
          component: () => import("../views/FMS/fms_ticket/scheduled_tickets_dashboard.vue"),
        },
        {
          path: "/deposits",
          name: "Deposits",
          meta: {
            auth: true,
            screen_code: "SCN_FMS",
            page_code: "SCN_FMS_CONTRACT",
            page_type: "landing",
          },
          component: () => import("../views/FMS/deposits/deposits.vue"),
        },
        {
          path: "/deposits_add",
          name: "Deposits Add",
          meta: {
            auth: true,
            screen_code: "SCN_FMS",
            page_code: "SCN_FMS_CONTRACT",
            page_type: "add_screen",
          },
          component: () => import("../views/FMS/deposits/deposits_add.vue"),
        },
        {
          path: "/deposits_edit",
          name: "Deposits Edit",
          meta: {
            auth: true,
            screen_code: "SCN_FMS",
            page_code: "SCN_FMS_CONTRACT",
            page_type: "edit_screen",
          },
          component: () => import("../views/FMS/deposits/deposits_edit.vue"),
        },
        {
          path: "/deposits_view/:id",
          name: "Deposits View",
          meta: {
            auth: true,
            screen_code: "SCN_FMS",
            page_code: "SCN_FMS_CONTRACT",
            page_type: "landing",
          },
          component: () => import("../views/FMS/deposits/deposits_view.vue"),
        },

        {
          path: "/MrQuotation",
          name: "MrQuotationLanding",
          meta: {
            auth: true,
            iscommontoshow: true,
            isnoadmintoshow: true,
            screen_code: "SCN_FMS",
            page_code: "SCN_FMS",
            page_type: "landing",
          },
          component: () => import("../views/FMS/material_request/mr_quotation.vue"),
        },
        {
          path: "/MrQuotationPdf/:id",
          name: "MrQuotationPdf",
          meta: {
            auth: true,
            iscommontoshow: true,
            isnoadmintoshow: true,
            screen_code: "SCN_FMS",
            page_code: "SCN_FMS",
            page_type: "landing",
          },
          component: () => import("../views/FMS/material_request/mr_quotation_pdf.vue"),
        },
        {
          path: "/MrQuotationCardPackPdf/:id",
          name: "MrQuotationCardPackPdf",
          meta: {
            auth: true,
            iscommontoshow: true,
            isnoadmintoshow: true,
            screen_code: "SCN_FMS",
            page_code: "SCN_FMS",
            page_type: "landing",
          },
          component: () => import("../views/FMS/material_request/mr_quotation_pdf_package_cardview.vue"),
        },
        {
          path: "/MrContract",
          name: "MrContractLanding",
          meta: {
            auth: true,
            iscommontoshow: true,
            isnoadmintoshow: true,
            screen_code: "SCN_FMS",
            page_code: "SCN_FMS",
            page_type: "landing",
          },
          component: () => import("../views/FMS/MR/mr_contract/mr_contract_landing/mr_contract_landing.vue"),
        },
        {
          path: "/mr_contract_view/:id",
          name: "mr_contract_view",
          meta: {
            auth: true,
            iscommontoshow: true,
            isnoadmintoshow: true,
            screen_code: "SCN_FMS",
            page_code: "SCN_FMS",
            page_type: "landing",
          },
          component: () => import("../views/FMS/MR/mr_contract/mr_contract_view.vue"),
        },
        {
          path: "/mr_contract_edit/:id",
          name: "mr_contract_edit",
          meta: {
            auth: true,
            iscommontoshow: true,
            isnoadmintoshow: true,
            screen_code: "SCN_FMS",
            page_code: "SCN_FMS",
            page_type: "landing",
          },
          component: () => import("../views/FMS/MR/mr_contract/mr_contract_edit.vue"),
        },
        {
          path: "/Quotation",
          name: "QuotationLanding",
          meta: {
            auth: true,
            iscommontoshow: true,
            isnoadmintoshow: true,
            screen_code: "SCN_FMS",
            page_code: "SCN_FMS_QUOTATION",
            page_type: "landing",
          },
          component: () => import("../views/FMS/Quotation/quotation_landing/quotation.vue"),
        },
        {
          path: "/Quotation/pdf/:id",
          name: "quotation_pdf",
          meta: {
            auth: true,
            iscommontoshow: true,
            isnoadmintoshow: true,
            screen_code: "SCN_FMS",
            page_code: "SCN_FMS_QUOTATION",
            page_type: "landing",
          },
          component: () => import("../views/FMS/Quotation/quotation_pdf.vue"),
        },
        {
          path: "/Quotation/pdf/two/:id",
          name: "quotation_pdf_two",
          meta: {
            auth: true,
            iscommontoshow: true,
            isnoadmintoshow: true,
            screen_code: "SCN_FMS",
            page_code: "SCN_FMS_QUOTATION",
            page_type: "landing",
          },
          component: () =>
            import("../views/FMS/Quotation/quotation_pdf_two.vue"),
        },
        {
          path: "/Quotation/Add",
          name: "QuotationAdd",
          meta: {
            auth: true,
            iscommontoshow: true,
            isnoadmintoshow: true,
            screen_code: "SCN_FMS",
            page_code: "SCN_FMS_QUOTATION",
            page_type: "landing",
          },
          component: () => import("../views/FMS/Quotation/QuotationAddNew.vue"),
        },
        {
          path: "/Quotation/Edit/:id",
          name: "QuotationEdit",
          meta: {
            auth: true,
            iscommontoshow: true,
            isnoadmintoshow: true,
            screen_code: "SCN_FMS",
            page_code: "SCN_FMS_QUOTATION",
            page_type: "landing",
          },
          component: () => import("../views/FMS/Quotation/quotationEdit.vue"),
        },
        {
          path: "/Quotation/View/:id",
          name: "QuotationView",
          meta: {
            auth: true,
            iscommontoshow: true,
            isnoadmintoshow: true,
            screen_code: "SCN_FMS",
            page_code: "SCN_FMS_QUOTATION",
            page_type: "landing",
          },
          component: () => import("../views/FMS/Quotation/quotationView.vue"),
        },
        {
          path: "/Quotation/Cancellation/:id",
          name: "QuotationCancel",
          meta: {
            auth: true,
            iscommontoshow: true,
            isnoadmintoshow: true,
            screen_code: "SCN_FMS",
            page_code: "SCN_FMS_QUOTATION",
            page_type: "landing",
          },
          component: () =>
            import("../views/FMS/Quotation/quotation_cancellation.vue"),
        },
        {
          path: "/amc_cancellation/:id",
          name: "AmcCancellation",
          meta: {
            auth: true,
            iscommontoshow: true,
            isnoadmintoshow: true,
            screen_code: "SCN_FMS",
            page_code: "SCN_FMS_QUOTATION",
            page_type: "landing",
          },
          component: () =>
            import("../views/FMS/Quotation/amc_cancellation.vue"),
        },
        {
          path: "/lift_merging/:ids/:customer_id",
          name: "LiftMerging",
          meta: {
            auth: true,
            iscommontoshow: true,
            isnoadmintoshow: true,
            screen_code: "SCN_FMS",
            page_code: "SCN_FMS",
            page_type: "landing",
          },
          component: () => import("../views/FMS/lift_merger/liftMerging.vue"),
        },
        {
          path: "/lift_renewal/:ids",
          name: "LiftRenewal",
          meta: {
            auth: true,
            iscommontoshow: true,
            isnoadmintoshow: true,
            screen_code: "SCN_FMS",
            page_code: "SCN_FMS",
            page_type: "landing",
          },
          component: () => import("../views/FMS/lift_renewal/liftRenewal.vue"),
        },
        {
          path: "/contract/demerge/:id",
          name: "Demerger",
          meta: {
            auth: true,
            iscommontoshow: true,
            isnoadmintoshow: true,
            screen_code: "SCN_FMS",
            page_code: "SCN_FMS",
            page_type: "landing",
          },
          component: () => import("../views/FMS/demerger/lift_demerger.vue"),
        },
        {
          path: "/contract_review/demerge/:id",
          name: "review",
          meta: {
            auth: true,
            iscommontoshow: true,
            isnoadmintoshow: true,
            screen_code: "SCN_FMS",
            page_code: "SCN_FMS",
            page_type: "landing",
          },
          component: () => import("../views/FMS/demerger/demerger_review.vue"),
        },
        {
          path: "/contract_review/merge/:id",
          name: "review",
          meta: {
            auth: true,
            iscommontoshow: true,
            isnoadmintoshow: true,
            screen_code: "SCN_FMS",
            page_code: "SCN_FMS",
            page_type: "landing",
          },
          component: () => import("../views/FMS/lift_merger/merger_review.vue"),
        },
        {
          path: "/merger_with_demerged_lift/:lift_id/:customer_id",
          name: "LiftMerging",
          meta: {
            auth: true,
            iscommontoshow: true,
            isnoadmintoshow: true,
            screen_code: "SCN_FMS",
            page_code: "SCN_FMS",
            page_type: "landing",
          },
          component: () =>
            import("../views/FMS/lift_merger/merger_with_demerged_lift.vue"),
        },
        {
          path: "/Contract",
          name: "ContractLanding",
          meta: {
            auth: true,
            iscommontoshow: true,
            isnoadmintoshow: true,
            screen_code: "SCN_FMS",
            page_code: "SCN_FMS_CONTRACT",
            page_type: "landing",
          },
          component: () => import("../views/FMS/contract/contract_landing/contract.vue"),
        },

        // {
        //   path: "/Contract/pdf",
        //   name: "ContractLanding",
        //   meta: {
        //     auth: true,
        //     iscommontoshow: true,
        //     isnoadmintoshow: true,
        //     screen_code: "SCN_FMS",
        //     page_code: "SCN_FMS",
        //     page_type: "landing",
        //   },
        //   component: () => import("../views/FMS/contract/contract_pdf.vue"),
        // },
        {
          path: "/Contract/pdf/:id",
          name: "contract_pdf",
          meta: {
            auth: true,
            iscommontoshow: true,
            isnoadmintoshow: true,
            screen_code: "SCN_FMS",
            page_code: "SCN_FMS",
            page_type: "landing",
          },
          component: () => import("../views/FMS/contract/contract_pdf.vue"),
        },
        {
          path: "/Contract/pdf/two/:id",
          name: "contract_pdf_two",
          meta: {
            auth: true,
            iscommontoshow: true,
            isnoadmintoshow: true,
            screen_code: "SCN_FMS",
            page_code: "SCN_FMS",
            page_type: "landing",
          },
          component: () => import("../views/FMS/contract/contract_pdf_two.vue"),
        },
        {
          path: "/Contract/Add",
          name: "ContractAdd",
          meta: {
            auth: true,
            iscommontoshow: true,
            isnoadmintoshow: true,
            screen_code: "SCN_FMS",
            page_code: "SCN_FMS_CONTRACT",
            page_type: "landing",
          },
          component: () => import("../views/FMS/contract/contractAdd.vue"),
        },
        {
          path: "/Contract/Edit/New/:id",
          name: "ContractEdit",
          meta: {
            auth: true,
            iscommontoshow: true,
            isnoadmintoshow: true,
            screen_code: "SCN_FMS",
            page_code: "SCN_FMS_CONTRACT",
            page_type: "landing",
          },
          component: () => import("../views/FMS/contract/contractEdit.vue"),
        },
        {
          path: "/Contract/View/:id",
          name: "ContractView",
          meta: {
            auth: true,
            iscommontoshow: true,
            isnoadmintoshow: true,
            screen_code: "SCN_FMS",
            page_code: "SCN_FMS_CONTRACT",
            page_type: "landing",
          },
          component: () => import("../views/FMS/contract/contractView.vue"),
        },

        {
          path: "/createContract/fromQuotation/:id",
          name: "ContractCreate",
          meta: {
            auth: true,
            iscommontoshow: true,
            isnoadmintoshow: true,
            screen_code: "SCN_FMS",
            page_code: "SCN_FMS",
            page_type: "landing",
          },
          component: () =>
            import("../views/FMS/contract/contractFromQuotation.vue"),
        },
        {
          path: "/approval",
          name: "approval",
          meta: {
            auth: true,
            screen_code: "CREATE_MASTERS",
            page_code: "SCN_PERFORMANCE_RATING",
            page_type: "landing",
          },
          component: () => import("../views/FMS/approval/approval.vue"),
        },
        {
          path: "/approval_list",
          name: "approval_list",
          meta: {
            auth: true,
            screen_code: "CREATE_MASTERS",
            page_code: "SCN_FMS_APPROVAL_LIST",
            page_type: "landing",
          },
          component: () => import("../views/FMS/approval/approval_list.vue"),
        },
        {
          path: "/approval_list_add/add",
          name: "approval_list_add",
          meta: {
            auth: true,
            screen_code: "CREATE_MASTERS",
            page_code: "SCN_FMS_APPROVAL_LIST",
            page_type: "landing",
          },
          component: () =>
            import("../views/FMS/approval/approval_list_add.vue"),
        },
        {
          path: "/approval_list_edit/edit/:id",
          name: "approval_list_edit",
          meta: {
            auth: true,
            screen_code: "CREATE_MASTERS",
            page_code: "SCN_FMS_APPROVAL_LIST",
            page_type: "landing",
          },
          component: () =>
            import("../views/FMS/approval/approval_list_edit.vue"),
        },
        {
          path: "/fms_dashboard_roles",
          name: "fms_dashboard_roles",
          meta: {
            auth: true,
            screen_code: "CREATE_MASTERS",
            page_code: "SCN_FMS_DASHBOARD_ROLES",
            page_type: "landing",
          },
          component: () => import("../views/FMS/dashboard/fms_dashboard_roles.vue"),
        },
        {
          path: "/fms_dashboard_roles_add",
          name: "fms_dashboard_roles_add",
          meta: {
            auth: true,
            screen_code: "CREATE_MASTERS",
            page_code: "SCN_FMS_DASHBOARD_ROLES",
            page_type: "add_screen",
          },
          component: () => import("../views/FMS/dashboard/fms_dashboard_roles_add.vue"),
        },
        {
          path: "/fms_dashboard_roles_edit/:id",
          name: "fms_dashboard_roles_edit",
          meta: {
            auth: true,
            screen_code: "CREATE_MASTERS",
            page_code: "SCN_FMS_DASHBOARD_ROLES",
            page_type: "edit_screen",
          },
          component: () => import("../views/FMS/dashboard/fms_dashboard_roles_edit.vue"),
        },
        {
          path: "/fms_dashboard",
          name: "fms_dashboard",
          meta: {
            auth: true,
            screen_code: "CREATE_MASTERS",
            page_code: "SCN_FMS_DASHBOARD",
            page_type: "landing",
          },
          component: () => import("../views/FMS/dashboard/fms_dashboard.vue"),
        },
      
        {
          path: "/approval_process",
          name: "approval_process",
          meta: {
            auth: true,
            screen_code: "CREATE_MASTERS",
            page_code: "SCN_FMS_APPROVAL_LIST",
            page_type: "landing",
          },
          component: () => import("../views/FMS/approval/approval_process.vue"),
        },

        {
          path: "/appraisal_landing",
          name: "appraisal_landing",
          meta: {
            auth: true,
            screen_code: "CREATE_MASTERS",
            page_code: "SCN_APPRAISAL_VIEW_APPRAISAL",
            isnoadmintoshow: true,
          },
          component: () => import("../views/masters/appraisal_landing.vue"),
        },

        {
          path: "/my_scorecard",
          name: "my_scorecard",
          meta: {
            auth: true,
            screen_code: "CREATE_MASTERS",
            page_code: "SCN_APPRAISAL_VIEW_APPRAISAL",
            isnoadmintoshow: true,
          },
          component: () =>
            import("../views/kpilist/appraisal/scorecard_landing.vue"),
        },
        {
          path: "/terms_and_conditions",
          name: "terms_and_conditions",
          meta: {
            auth: true,
            iscommontoshow: true,
            isnoadmintoshow: true,
            screen_code: "SCN_FMS",
            page_code: "SCN_FMS",
            page_type: "landing",
          },
          component: () =>
            import(
              "../views/FMS/terms_and_conditions/terms_and_conditions.vue"
            ),
        },
        {
          path: "/terms_and_conditions_add",
          name: "terms_and_conditions_add",
          meta: {
            auth: true,
            screen_code: "CREATE_MASTERS",
            page_code: "SCN_PERFORMANCE_RATING",
            page_type: "landing",
          },
          component: () =>
            import(
              "../views/FMS/terms_and_conditions/terms_and_conditions_add.vue"
            ),
        },

        {
          path: "/terms_and_conditions_edit/:id",
          name: "terms_and_conditions_edit",
          meta: {
            auth: true,
            screen_code: "CREATE_MASTERS",
            page_code: "SCN_PERFORMANCE_RATING",
            page_type: "landing",
          },
          component: () =>
            import(
              "../views/FMS/terms_and_conditions/terms_and_conditions_edit.vue"
            ),
        },
        {
          path: "/reason",
          name: "reasons",
          meta: {
            auth: true,
            iscommontoshow: true,
            isnoadmintoshow: true,
            screen_code: "SCN_FMS",
            page_code: "SCN_FMS",
            page_type: "landing",
          },
          component: () => import("../views/FMS/reason/reasons.vue"),
        },
        {
          path: "/reason_add",
          name: "reasons_add",
          meta: {
            auth: true,
            iscommontoshow: true,
            isnoadmintoshow: true,
            screen_code: "SCN_FMS",
            page_code: "SCN_FMS",
            page_type: "add_screen",
          },
          component: () => import("../views/FMS/reason/reasons_add.vue"),
        },
        {
          path: "/reason_edit/:id",
          name: "reasons_edit",
          meta: {
            auth: true,
            iscommontoshow: true,
            isnoadmintoshow: true,
            screen_code: "SCN_FMS",
            page_code: "SCN_FMS",
            page_type: "add_screen",
          },
          component: () => import("../views/FMS/reason/reasons_edit.vue"),
        },
        {
          path: "/customer_designation",
          name: "customer_designation",
          meta: {
            auth: true,
            screen_code: "CREATE_MASTERS",
            page_code: "SCN_PERFORMANCE_RATING",
            page_type: "landing",
          },
          component: () =>
            import("../views/FMS/FmsMaster/CustomerDesignation.vue"),
        },
        {
          path: "/terms_group",
          name: "terms_group",
          meta: {
            auth: true,
            screen_code: "CREATE_MASTERS",
            page_code: "SCN_PERFORMANCE_RATING",
            page_type: "landing",
          },
          component: () =>
            import("../views/FMS/terms_and_conditions/TermsGroup.vue"),
        },
        {
          path: "/receipt",
          name: "receipt",
          meta: {
            auth: true,
            isnoadmintoshow: true,
            page_type: "add_screen",
          },
          component: () => import("../views/FMS/receipt/receipt_landing/receipt.vue"),
        },
        // {
        //     path: '/receipt/add',
        //     name: 'receipt_add',
        //     meta: {
        //         auth: true,
        //         isnoadmintoshow: true,
        //         page_type: 'add_screen',
        //     },
        //     component: () =>
        //         import ('../views/FMS/receipt_add.vue')
        // },
        {
          path: "/receipt_add",
          name: "receipt_add_new",
          meta: {
            auth: true,
            isnoadmintoshow: true,
            page_type: "add_screen",
          },
          component: () => import("../views/FMS/receipt_add_new.vue"),
        },
        {
          path: "/fms_home",
          name: "fms_home",
          meta: {
            auth: true,
            isnoadmintoshow: true,
            page_type: "landing",
          },
          component: () => import("../views/FMS/fms_home.vue"),
        },
        {
          path: "/receipt_edit/:id",
          name: "receipt_edit",
          meta: {
            auth: true,
            isnoadmintoshow: true,
            page_type: "edit_screen",
          },
          component: () => import("../views/FMS/receipt/receipt_edit.vue"),
        },
        {
          path: "/billing",
          name: "billing",
          meta: {
            auth: true,
            isnoadmintoshow: true,
            page_type: "add_screen",
          },
          component: () => import("../views/FMS/billing/billing.vue"),
        },
        {
          path: "/billingPDF/:id",
          name: "billing_pdf",
          meta: {
            auth: true,
            isnoadmintoshow: true,
            page_type: "edit_screen",
          },
          component: () => import("../views/FMS/receipt/billing_pdf.vue"),
        },
        {
          path: "/receipt_approval/:id",
          name: "receipt_approval",
          meta: {
            auth: true,
            isnoadmintoshow: true,
            page_type: "approval_screen",
          },
          component: () =>
            import("../views/FMS/receipt_approval/receipt_approval.vue"),
        },
        {
          path: "/visit-log-reports",
          name: "visit-log-reports",
          meta: {
            auth: true,
            isnoadmintoshow: true,
          },
          component: () => import("../views/visit-log-reports.vue"),
        },
        {
          path: "/receipt_view/:id",
          name: "receipt_view",
          meta: {
            auth: true,
            isnoadmintoshow: true,
            page_type: "view_screen",
          },
          component: () => import("../views/FMS/receipt/receipt_view.vue"),
        },
        {
          path: "/receipt_narrations",
          name: "receipt_narrations",
          meta: {
            auth: true,
            isnoadmintoshow: true,
            page_type: "landing",
          },
          component: () =>
            import("../views/FMS/FmsMaster/receipt_narrations.vue"),
        },
        {
          path: "/receipt_narrations_add",
          name: "receipt_narrations_add",
          meta: {
            auth: true,
            isnoadmintoshow: true,
            page_type: "add_screen",
          },
          component: () =>
            import("../views/FMS/FmsMaster/receipt_narrations_add.vue"),
        },
        {
          path: "/receipt_narrations_edit/:id",
          name: "receipt_narrations_edit",
          meta: {
            auth: true,
            isnoadmintoshow: true,
            page_type: "edit_screen",
          },
          component: () =>
            import("../views/FMS/FmsMaster/receipt_narrations_edit.vue"),
        },
        {
          path: "/reports/technical_chart",
          name: "technical_chart",
          meta: {
            auth: true,
            isnoadmintoshow: true,
            screen_code: "CREATE_MASTERS",
            page_code: "SCN_FMS_TECHNICAL_CHART",
            page_type: "landing",
          },
          component: () => import("../views/FMS/reports/technical_chart.vue"),
        },
        {
          path: "/reports/outstanding",
          name: "outstanding",
          meta: {
            auth: true,
            isnoadmintoshow: true,
            screen_code: "CREATE_MASTERS",
            page_code: "SCN_FMS_OUTSTANDING_REPORT",
            page_type: "landing",
          },
          component: () => import("../views/FMS/reports/outstanding.vue"),
        },
        {
          path: "/reports/lift_reports",
          name: "lift_reports",
          meta: {
            auth: true,
            isnoadmintoshow: true,
            page_type: "landing",
            screen_code: "CREATE_MASTERS",
            page_code: "SCN_FMS_LIFT_REPORTS",
          },
          component: () => import("../views/FMS/reports/lift_reports.vue"),
        },
        {
          path: "/reports/building_ticket_reports",
          name: "building_ticket_reports",
          meta: {
            auth: true,
            isnoadmintoshow: true,
            page_type: "landing",
            screen_code: "CREATE_MASTERS",
            page_code: "SCN_FMS_BUILDING_TICKET_REPORTS",
          },
          component: () => import("../views/FMS/reports/building_ticket_reports.vue"),
        },
        {
          path: "/reports/breakdown_pm_reports",
          name: "breakdown_pm_reports",
          meta: {
            auth: true,
            isnoadmintoshow: true,
            page_type: "landing",
            screen_code: "CREATE_MASTERS",
            page_code: "SCN_FMS_BUILDING_TICKET_REPORTS",
          },
          component: () => import("../views/FMS/reports/breakdown_pm_reports.vue"),
        },
        {
          path: "/reports/attendance_reports",
          name: "attendance_reports",
          meta: {
            auth: true,
            isnoadmintoshow: true,
            page_type: "landing",
            screen_code: "CREATE_MASTERS",
            page_code: "SCN_FMS_ATTENDANCE_REPORTS",
          },
          component: () => import("../views/FMS/reports/attendance_reports.vue"),
        },
        {
          path: "/reports/daily_attendance_reports",
          name: "daily_attendance_reports",
          meta: {
            auth: true,
            isnoadmintoshow: true,
            page_type: "landing",
            screen_code: "CREATE_MASTERS",
            page_code: "SCN_FMS_DAILY_ATTENDANCE_REPORTS",
          },
          component: () => import("../views/FMS/reports/daily_attendance_reports.vue"),
        },
        {
          path: "/reports/amc_not_renewed_reports",
          name: "Not Renewed List",
          meta: {
            auth: true,
            isnoadmintoshow: true,
            page_type: "landing",
            screen_code: "CREATE_MASTERS",
            page_code: "SCN_FMS_AMC_NOT_RENEWED_REPORTS",
          },
          component: () => import("../views/FMS/reports/amc_not_renewed_reports.vue"),
        },
        {
          path: "/reports/current_month_amc_renewal_reports",
          name: "Current Month Renewal List",
          meta: {
            auth: true,
            isnoadmintoshow: true,
            page_type: "landing",
            screen_code: "CREATE_MASTERS",
            page_code: "SCN_FMS_CURRENT_MONTH_AMC_RENEWAL_REPORTS",
          },
          component: () => import("../views/FMS/reports/current_month_amc_renewal_reports.vue"),
        },
        {
          path: "/reports/advance_amc_renewal_reports",
          name: "Advance Renewal Reports",
          meta: {
            auth: true,
            isnoadmintoshow: true,
            page_type: "landing",
            screen_code: "CREATE_MASTERS",
            page_code: "SCN_FMS_ADVANCE_AMC_RENEWAL_REPORTS",
          },
          component: () => import("../views/FMS/reports/advance_amc_renewal_reports.vue"),
        },
        {
          path: "/reports/preventive_maintenance_reports",
          name: "Preventive Maintenance Reports",
          meta: {
            auth: true,
            isnoadmintoshow: true,
            page_type: "landing",
            screen_code: "CREATE_MASTERS",
            page_code: "SCN_FMS_PREVENTIVE_MAINTENANCE_REPORTS",
          },
          component: () => import("../views/FMS/reports/preventive_maintenance_reports.vue"),
        },
        {
          path: "/reports/receipt_booking_status_reports",
          name: "Receipt Booking Status",
          meta: {
            auth: true,
            isnoadmintoshow: true,
            page_type: "landing",
            screen_code: "CREATE_MASTERS",
            page_code: "SCN_FMS_RECEIPT_BOOKING_STATUS_REPORTS",
          },
          component: () => import("../views/FMS/reports/receipt_booking_status_reports.vue"),
        },
        {
          path: "/reports/cancelled_contract_reports",
          name: "Cancelled Contract Report",
          meta: {
            auth: true,
            isnoadmintoshow: true,
            page_type: "landing",
            screen_code: "CREATE_MASTERS",
            page_code: "SCN_FMS_CANCELLED_CONTRACT_REPORTS",
          },
          component: () => import("../views/FMS/reports/cancelled_contract_reports.vue"),
        },
        {
          path: "/payment_received_mstr",
          name: "payment_received_mstr",
          meta: {
            auth: true,
            isnoadmintoshow: true,
            page_type: "landing",
          },
          component: () =>
            import("../views/FMS/FmsMaster/payment_received_mstr.vue"),
        },
        {
          path: "/payment_received_mstr_add",
          name: "payment_received_mstr_add",
          meta: {
            auth: true,
            isnoadmintoshow: true,
            page_type: "add_screen",
          },
          component: () =>
            import("../views/FMS/FmsMaster/payment_received_mstradd.vue"),
        },
        {
          path: "/payment_received_mstr_edit/:id",
          name: "payment_received_mstr_edit",
          meta: {
            auth: true,
            isnoadmintoshow: true,
            page_type: "edit_screen",
          },
          component: () =>
            import("../views/FMS/FmsMaster/payment_received_mstredit.vue"),
        },
        {
          path: "/weight_range/add",
          name: "weight_range_add",
          meta: {
            auth: true,
            isnoadmintoshow: true,
            page_type: "edit_screen",
          },
          component: () => import("../views/masters/weight_range_add.vue"),
        },
        {
          path: "/weight_range",
          name: "weight_range",
          meta: {
            auth: true,
            isnoadmintoshow: true,
            page_type: "edit_screen",
          },
          component: () => import("../views/masters/weight_range.vue"),
        },
        {
          path: "/weight_range/edit/:id",
          name: "weight_range",
          meta: {
            auth: true,
            isnoadmintoshow: true,
            page_type: "edit_screen",
          },
          component: () => import("../views/masters/weight_range_edit.vue"),
        },
        {
          path: "/jewellery_dashboard_alagar",
          name: "Jewellery",
          meta: {
            auth: true,
            isnoadmintoshow: true,
            screen_code: "SCN_JEWELLERY_DASHBOARD",
            page_code: "SCN_JEWELLERY_DASHBOARD",
          },
          component: () => import("../views/jewellery_dashboard.vue"),
        },
        {
          path: "/jewellery_dashboard_vbj",
          name: "jewellery_dashboard vbj",
          meta: {
            auth: true,
            isnoadmintoshow: true,
            screen_code: "SCN_JEWELLERY_DASHBOARD",
            page_code: "SCN_JEWELLERY_DASHBOARD",
          },
          component: () => import("../views/jewellery_dashboard_vbj.vue"),
        },
        {
          path: "/design/add",
          name: "design_add",
          meta: {
            auth: true,
            isnoadmintoshow: true,
            page_type: "edit_screen",
          },
          component: () => import("../views/masters/design_add.vue"),
        },
        {
          path: "/design/edit/:id",
          name: "design",
          meta: {
            auth: true,
            isnoadmintoshow: true,
            page_type: "edit_screen",
          },
          component: () => import("../views/masters/design_edit.vue"),
        },
        {
          path: "/design",
          name: "design",
          meta: {
            auth: true,
            isnoadmintoshow: true,
            page_type: "edit_screen",
          },
          component: () => import("../views/masters/design.vue"),
        },
        {
          path: "/oro_to_postgres",
          name: "oro_to_postgres",
          meta: {
            auth: true,
            isnoadmintoshow: true,
            page_type: "edit_screen",
          },
          component: () => import("../views/oro/oro_to_postgres.vue"),
        },
        {
          path: "/alagar_dashboard_sync",
          name: "alagar_dashboard_sync",
          meta: {
            auth: true,
            isnoadmintoshow: true,
            page_type: "edit_screen",
          },
          component: () => import("../views/alagar/alagar_dashboard_sync.vue"),
        },
        {
          path: "/forms_master",
          name: "Forms",
          meta: {
            auth: true,
            screen_code: "CREATE_CHK",
            page_code: "SCN_CHCK_MY_CHECKLIST",
            page_type: "landing",
          },
          component: () => import("../views/masters/forms/form_master.vue"),
        },
        {
          path: "/Delegation",
          name: "Delegation",
          meta: {
            auth: true,
            screen_code: "CREATE_MASTERS",
            page_code: "SCN_DELEGATION",
            page_type: "landing",
          },
          component: () => import("../views/masters/Delegation.vue"),
        },
        {
          path: "/Delegation_mstr/add",
          name: "Delegation_add",
          meta: {
            auth: true,
            screen_code: "CREATE_MASTERS",
            page_code: "SCN_DELEGATION",
            page_type: "add_screen",
          },
          component: () => import("../views/masters/Delegation_add.vue"),
        },
        {
          path: "/Delegation_mstr/edit/:id",
          name: "Delegation_edit",
          meta: {
            auth: true,
            screen_code: "CREATE_MASTERS",
            page_code: "SCN_DELEGATION",
            page_type: "edit_screen",
          },
          component: () => import("../views/masters/Delegation_edit.vue"),
        },
        {
          path: "/employee_grade",
          name: "employee_grade",
          meta: {
            auth: true,
            screen_code: "CREATE_MASTERS",
            page_code: "SCN_EMP_GRADE",
            page_type: "landing",
          },
          component: () => import("../views/masters/employee_grade.vue"),
        },
        {
          path: "/employee_grade_mstr_add",
          name: "employee_grade_add",
          meta: {
            auth: true,
            screen_code: "CREATE_MASTERS",
            page_code: "SCN_EMP_GRADE",
            page_type: "add_screen",
          },
          component: () => import("../views/masters/employee_grade_add.vue"),
        },
        {
          path: "/employee_grade_mstr_edit/:id",
          name: "employee_grade_edit",
          meta: {
            auth: true,
            screen_code: "CREATE_MASTERS",
            page_code: "SCN_EMP_GRADE",
            page_type: "edit_screen",
          },
          component: () => import("../views/masters/employee_grade_edit.vue"),
        },
        // {
        //     path: '/opening',
        //     name: 'opening',
        //     meta: {
        //         auth: true,
        //         isnoadmintoshow: true,
        //         page_type: 'edit_screen',
        //     },
        //     component: () =>
        //         import ('../views/masters/opening_balance_add.vue')
        // }

        {
          path: "/blog",
          name: "blog",
          meta: {
            auth: true,
            iscommontoshow: true,
          },
          component: () => import("../views/blog.vue"),
        },
        {
          path: "/blog_sect_categ",
          name: "blog_sect_categ",
          meta: {
            auth: true,
            iscommontoshow: true,
          },
          component: () => import("../views/blog_sect_categ.vue"),
        },

        {
          path: "/contacts",
          name: "contacts",
          meta: {
            auth: true,
            iscommontoshow: true,
          },
          component: () => import("../views/contacts.vue"),
        },
      ],
    },
  ],
});
