import axios from "axios";
import Vue from "vue";
import Toast from "vue-toastification";

const getApiEndPoint = (mode) => {
  // console.log("mode", mode);
  mode = "" + mode.toLowerCase().trim();
  const links = {
    'production': "https://compliance-api.tectalik.com",
    'development': "https://compliance-api-staging.tectalik.com",
    'lan': "http://192.168.0.120:4001",
    'lan_secure': "https://192.168.0.120:4001",
  };

  return links[mode] ?? "http://localhost:4001";
};

const api_end_point = getApiEndPoint(process.env.NODE_ENV);
// console.log("api_end_point", api_end_point);
const uri = `${api_end_point}/tapi`;
const public_uri = `${api_end_point}/api`;

const api = axios.create({
  baseURL: `${uri}`,
  method: "POST",
  headers: {
    Accept: "application/json",
  },
});

const public_api = axios.create({
  baseURL: `${public_uri}`,
  method: "POST",
  headers: {
    Accept: "application/json",
  },
});

const addHeaders = (config) => {
  let csrf_token = document
    .querySelector('meta[name="csrf-token"]')
    .getAttribute("content");
  if (csrf_token) {
    config.headers["X-CSRF-TOKEN"] = csrf_token;
  }

  let auth_token = localStorage.getItem("TOKEN");
  if (auth_token) {
    config.headers.Authorization = "Bearer " + auth_token;
  }
  const user_id = localStorage.getItem("USER_ID");
  if (user_id) {
    config.transformRequest = [
      (data) => {
        if (data) {
          if (!data.from_user) {
            data.from_user = parseInt(user_id);
          }
        }
        return data;
      },
      ...axios.defaults.transformRequest,
    ];
  }
  return config;
};


Vue.use(Toast, {
  transition: "Vue-Toastification__slideBlurred",
  maxToasts: 4,
  newestOnTop: true,
});

const global_push_toast = function() {
  Vue.prototype.$toast.error("You are offline!", {
    timeout: 5000,
  });
}.bind(this);

api.interceptors.request.use(addHeaders);
public_api.interceptors.request.use(addHeaders);

// Add event listener to check online/offline status before making API calls
axios.interceptors.request.use((config) => {
  const online = navigator.onLine;
  if (online) {
    console.log("You are online!");
    // Proceed with the request
    return config;
  } else {
    console.log("You are offline!");
    api.interceptors.request.use(global_push_toast);
    public_api.interceptors.request.use(global_push_toast);
    // Optionally handle offline case, such as queuing requests for later processing
    // For now, just return a rejected promise
    return Promise.reject(new Error("You are offline!"));
  }
});

export { api, public_api };
