<template>
  <div>
    <v-overlay :value="overlay_load" z-index="1000">
      <v-progress-circular indeterminate size="64"></v-progress-circular>
    </v-overlay>
  </div>
</template>

<script>
export default {
  name: "OverlayLoader",
  props: {
    overlay_load: {
      type: Boolean,
      default: false,
    },
  },
};
</script>