import Vue from 'vue';
import XLSX from 'xlsx';

 Vue.mixin({
  methods: {
    global_removeByAttr(arrmain, attr, value){
      const arr = [...arrmain];
      var i = arr.length;
      while(i--){
       if( arr[i] 
           && arr[i].hasOwnProperty(attr) 
           && (arguments.length > 2 && arr[i][attr] === value ) ){ 

           arr.splice(i,1);

       }
      }
      return arr;
    },
    to_checkBy_null_conditions(val) {
      if (!val || val == NaN || val == Infinity || val == 'Infinity' || val == -Infinity) {
        return Number.parseFloat(0);
      } else {
        return Number.parseFloat(val);
      }
    },
    fixtwo_Values_amt(val) {
      var currencyFormatter = new Intl.NumberFormat('en-IN', {
        currency: 'INR',
        maximumFractionDigits: 2,
        minimumFractionDigits: 2
      })
      if (!val) {
        return Number.parseFloat(0).toFixed(2);
      } else {
        let n = parseFloat(val).toFixed(2);
        return currencyFormatter.format(n);
      }
    },
    fixtwo_Values(val) {
      if (!val) {
        return Number.parseFloat(0).toFixed(2);
      } else {
        return Number.parseFloat(val).toFixed(2);
      }
    },
    round_fixtwo_Values(val,flag) {
     if (flag == false) {
       if (!val) {
        return Number.parseFloat(0).toFixed(2);
       } else {
        return Number.parseFloat(val).toFixed(2);
       }
      }else{
        if (!val) {
        return Number.parseFloat(0).toFixed(2);
       } else {
        return Number.parseFloat(val).toFixed(0);
       }
      }

    },
    common_fn_distinct_array(dataset = [], distinct_by = []) {
      var seen = Object.create(null),
        result = [];
      result = dataset.filter((o) => {
        var key = distinct_by.map((k) => o[k]).join("|");
        if (!seen[key]) {
          seen[key] = true;
          return true;
        }
      });
      return result || [];
    },
    sumFieldAll(vrname,key) {
      let val = this[vrname].reduce(
        (a, b) => a + (b[key] * 1 || 0),
        0
      );
      return val;
    },
    gettingValueArr(arrmain,column){
      const arr = [...arrmain];
      let retArr = [];
      retArr = arr.map(a => a.[column]);
      retArr = retArr.filter(function (el) {return el!== '' && el!= null});
      retArr = retArr.map(v => v.trim().toLowerCase());
      return retArr;
    },
    global_totalCalculator(key,dataset){
      let value = dataset.reduce((a, b) => Number(a) + Number((b[key] || 0)), 0);
      value = value.toFixed(2);
      value = Number(value);
      return value
    },
    
    addserialnumber(array, key = 'sno', max = 0) {
      let result = array.map(o => Object.assign(
          {},
          o,
          { [key]: ++max }
    ));

    return result
    },
    isNumber : function(evt) {
      evt = (evt) ? evt : window.event;
      var charCode = (evt.which) ? evt.which : evt.keyCode;
      if (charCode == 46) {
          return true;
      }
      if (charCode > 31 && (charCode < 48 || charCode > 57)) {
          return evt.preventDefault();
      }

      return true;
    },
    isNumber_allow_negative : function(evt) {
      evt = (evt) ? evt : window.event;
      var charCode = (evt.which) ? evt.which : evt.keyCode;
      if (charCode == 46 || charCode == 45) {
          return true;
      }
      if (charCode > 31 && (charCode < 48 || charCode > 57)) {
          return evt.preventDefault();
      }

      return true;
    },
    isDecimal(evt) {
      let charCode = (evt.which) ? evt.which : evt.keyCode;
      if (charCode != 46 && charCode > 31 && (charCode < 48 || charCode > 57))
         return evt.preventDefault();

      return true;
    },
    isLatitude(val) {
      let lat = parseFloat(val);
      return isFinite(lat) && Math.abs(lat) <= 90;
    },
    isLongitude(val) {
      let lng = parseFloat(val);
      return isFinite(lng) && Math.abs(lng) <= 180;
    },
    str_like(source, search) {
      if (typeof search !== 'string' || source === null) {return false; }
      // Remove special chars
      search = search.replace(new RegExp("([\\.\\\\\\+\\*\\?\\[\\^\\]\\$\\(\\)\\{\\}\\=\\!\\<\\>\\|\\:\\-])", "g"), "\\$1");
      // Replace % and _ with equivalent regex
      search = search.replace(/%/g, '.*').replace(/_/g, '.');
      // Check matches
      return RegExp('^' + search + '$', 'gi').test(source);
    },
    is_Authorization_need(transaction_type = null){
      var lvl_dt = JSON.parse(localStorage.getItem("APP_AUTHORIZATION_LVL")); 
      let tempArr = lvl_dt ? lvl_dt.filter(function (e) {return e.transaction_type == transaction_type }) : [];
      return tempArr.length ? (Number(tempArr[0].records_count) > 0 ? true : false) : false;
    },
    GetCodeForName(stringvalue, codecol){
      this[codecol] = stringvalue.match(/(^\S\S?|\s\S)?/g).map(v=>v.trim()).join("").match(/(^\S|\S$)?/g).join("").toLocaleUpperCase();
    },
    screen_auth(page_code= '', page_type=''){ 
      var screen_right_dataset = JSON.parse(localStorage.getItem("SCREEN_RIGHT_DATASET")); 
      var page_flg = false;

      if( screen_right_dataset != null && screen_right_dataset != "" && screen_right_dataset != undefined && screen_right_dataset.length != 0){
        screen_right_dataset = screen_right_dataset.filter(function (e) {return e.screen_code == page_code });
        for(let i=0; i<screen_right_dataset.length;i++){ 
          if(screen_right_dataset[i].screen_code == page_code){ 
            page_flg = screen_right_dataset[i][page_type];
            if(page_flg){
              break;
            }
          }
        } 
      }
      return page_flg;
    },
    common_screen_auth(page_code= '', page_type=''){ 
      var screen_right_dataset = JSON.parse(localStorage.getItem("COMMON_SCREEN_RIGHT_DATASET")); 
      var page_flg = false;

      if( screen_right_dataset != null && screen_right_dataset != "" && screen_right_dataset != undefined && screen_right_dataset.length != 0){
        screen_right_dataset = screen_right_dataset.filter(function (e) {return e.screen_code == page_code });
        for(let i=0; i<screen_right_dataset.length;i++){ 
          if(screen_right_dataset[i].screen_code == page_code){ 
            page_flg = screen_right_dataset[i][page_type];
            if(page_flg){
              break;
            }
          }
        } 
      }
      return page_flg;
    },
    module_auth(page_code= '', page_type=''){ 
      var module_right_dataset = JSON.parse(localStorage.getItem("MODULE_RIGHTS_DATASET")); 
      var page_flg = false;
      if( module_right_dataset != null && module_right_dataset != "" && module_right_dataset != undefined && module_right_dataset.length != 0){
        module_right_dataset = module_right_dataset.filter(function (e) {return e.module_code == page_code });
        for(let i=0; i<module_right_dataset.length;i++){ 
          if(module_right_dataset[i].module_code == page_code){ 
            page_flg = module_right_dataset[i].active;
            if(page_flg){
              break;
            }
          }
        } 
      }
      return page_flg;
    },

  global_getNowTime(prefixValue) {
      const generateRandomString = (length = 10) => Math.random().toString(20).substr(2, length)
      const today = new Date();
      const date = today.getFullYear()+'_'+(today.getMonth()+1)+'_'+today.getDate();
      const time = today.getHours() + "_" + today.getMinutes() + "_" + today.getSeconds()+ "_" + today.getMilliseconds();
      const dateTime = prefixValue+'_'+date +'_'+ time+'__'+generateRandomString();
      return  dateTime;
    },  

    getFilterQuery(defs) {
      let condition = "";
      Object.keys(defs).forEach((col_name) => {
        let col = defs[col_name],
          data_type = col.type;
        // console.log("col_name", col_name);
        // console.log("col", col);
        if (data_type == "String") {
          condition += this.getStringQuery(col.original_column, col.filter);
        } else if (data_type == "Number") {
          condition += this.getNumberQuery(col.original_column, col.filter);
        } else if (data_type == "Date") {
          condition += this.getDateQuery(col.original_column, col.filter);
        } else {
          condition += this.getStringQuery(col.original_column, col.filter);
        }
      });
      // console.log(condition);
      return condition;
    },
    getDateQuery(column, filter) {
      let str = "";
      str += filter.from ? `AND DATE(${column}) >= DATE(${filter.from}) ` : "";
      str += filter.to ? `AND DATE(${column}) <= DATE(${filter.to}) ` : "";
      return str;
    },
    getNumberQuery(column, arr = []) {
      let str = "";
      if (arr.length > 0) {
        str += "AND " + column + " in(" + arr.join(",") + ") ";
      }
      return str;
    },
    getStringQuery(column, arr = []) {
      let str = "";
      if (arr.length > 0) {
        str += "AND " + column + " in('" + arr.join("','") + "') ";
      }
      return str;
    },

    getSaarcCountries() {
      return [
        {
          name: "India",
          dial_code: "+91",
          code: "IN",
        },
        {
          name: "Afghanistan",
          dial_code: "+93",
          code: "AF",
        },
        {
          name: "Bangladesh",
          dial_code: "+880",
          code: "BD",
        },
        {
          name: "Bhutan",
          dial_code: "+975",
          code: "BT",
        },
        {
          name: "Maldives",
          dial_code: "+960",
          code: "MV",
        },
        {
          name: "Nepal",
          dial_code: "+977",
          code: "NP",
        },
        {
          name: "Pakistan",
          dial_code: "+92",
          code: "PK",
        },
        {
          name: "Sri Lanka",
          dial_code: "+94",
          code: "LK",
        },
      ];
    },
    getCountryCode(country_name) {
      let countries = this.getSaarcCountries();
      let result = alasql(
        "select TOP 1 code from ? where name = '" + country_name + "'",
        [countries]
      );
      if (result.length > 0) {
        return result[0].code;
      }
      return "";
    },

     getStates(countryObj, item) {
      this.overlay_load = true;
      console.log("state get", countryObj, item);
      item.country = countryObj.code;
      item.country_name = countryObj.name;
 
      let reqURI = `https://staging.tectalik.com/global/api/country/list?flag=state&country_code=${item.country}`

      var req = new Request(reqURI);
      fetch(req).then((response) => {
        let strl = response.json();
        Promise.resolve(strl).then((result) => {
          item.state_list = result.data;
          this.overlay_load = false;
        });
      });
    },
    getCities(stateObj, item) {
      this.overlay_load = true;
      console.log("city get", { stateObj, item });
      item.state = stateObj.code;
      item.state_name = stateObj.name;
     
      let reqURI =`https://staging.tectalik.com/global/api/country/list?flag=city&country_code=${item.country}&state_code=${item.state}`

      var req = new Request(reqURI);
      fetch(req).then((response) => {
        let strl = response.json();
        Promise.resolve(strl).then((result) => {
          item.city_list = result.data;
          this.overlay_load = false;
        });
      });
    },

    setCityName(cityObj, item) {
      // console.log("city set", { cityObj, item });
      item.city = cityObj.code;
      item.city_name = cityObj.name;
    },
    alphaNuemericSpace(e, allowComma = false) {
      var keyCode = e.keyCode || e.which;
      var pattern = allowComma ? /^[a-z\d\-_,\s]+$/i : /^[a-z\d\-_\s]+$/i;
      var isValid = pattern.test(String.fromCharCode(keyCode));
      if (!isValid) {
        e.preventDefault();
      }
      return isValid;
    },
    global_push_toast(alertType, content) {
      this.$toast.clear();
      this.$toast[alertType](content, {
        position: "top-right",
        timeout: 5000,
        closeOnClick: true,
        pauseOnFocusLoss: true,
        pauseOnHover: true,
        draggable: true,
        draggablePercent: 0.6,
        showCloseButtonOnHover: false,
        hideProgressBar: true,
        closeButton: "button",
        icon: true,
        rtl: false,
      });
    },
    global_push_toast1(alertType, content) {
      // this.$toast.clear();
      this.$toast[alertType](content, {
        position: "top-right",
        timeout: 5000,
        closeOnClick: true,
        pauseOnFocusLoss: true,
        pauseOnHover: true,
        draggable: true,
        draggablePercent: 0.6,
        showCloseButtonOnHover: false,
        hideProgressBar: true,
        closeButton: "button",
        icon: true,
        rtl: false,
      });
    },
    get_initial_pagination() {
      return {
        count: 0,
        first: 0,
        has_next: false,
        has_prev: false,
        last: 0,
        next_page: 0,
        page: 1,
        prev_page: 0,
        total_pages: 0,
        more: false,
      };
    },
    isEmptyObject(obj) {
      return (
        obj &&
        Object.keys(obj).length === 0 &&
        Object.getPrototypeOf(obj) === Object.prototype
      );
    },
    isNotEmptyObject(obj) {
      return !this.isEmptyObject(obj);
    },
    jsonToExcel(data, file_name = "export", ws_name = "Sheet1") {
      let fileName = file_name + "." + "xlsx";
      let wb = XLSX.utils.book_new(), ws = XLSX.utils.json_to_sheet(data);
      XLSX.utils.book_append_sheet(wb, ws, ws_name);
      XLSX.writeFile(wb, fileName);
    },
    handleAxiosError(error) {
      if (error.response) {
        // The request was made and the server responded with a status code that falls out of the range of 2xx
        // console.log(error.response.data);
        this.handleErrorCodes(error.response.status);
        // console.log(error.response.headers);
      } else if (error.request) {
        // The request was made but no response was received
        // `error.request` is an instance of XMLHttpRequest in the browser and an instance of http.ClientRequest in node.js
        // console.log(error.request);
        this.global_push_toast("error", "Unable to contact server")
      } else {
        // Something happened in setting up the request that triggered an Error
        console.log('Error', error.message);
      }
    },
    handleErrorCodes(errCode) {
      if(errCode === 500) this.global_push_toast("error", "Server Error");
    },
    trimUnWantedSpaces(val) {
      if (typeof val === 'string' || val instanceof String) {
        val = val.trim().replace(/\s\s+/g, ' ');
      }
      return val;
    },
    getReportingTo(usersData, logged_user_id) {
      let Report_userId = [];
      let i = 1;
      let ArrReportTemp = usersData.filter(el => el.reporting_to_id == logged_user_id || el.id == logged_user_id);
      Report_userId = ArrReportTemp.map((a) => a.id);

      while (i > 0) {
        var filtered = usersData.filter((item) => {
          return (
            Report_userId.indexOf(item.reporting_to_id) !== -1 &&
            Report_userId.indexOf(item.id) == -1
          );
        });
        let temp = filtered.map((a) => a.id);
        Report_userId = Report_userId.concat(temp);
        i = filtered.length;
      }
      return Report_userId;
    },
    removeCommas(event) {
      event.preventDefault();
      const pastedText = (event.clipboardData || window.clipboardData).getData(
        "text"
      );
      const textWithoutCommas = pastedText.replace(/,/g, "");
      document.execCommand("insertText", false, textWithoutCommas);
    },
  },
  isEmptyObject_Global(obj) {
    return (
      obj &&
      Object.keys(obj).length === 0 &&
      Object.getPrototypeOf(obj) === Object.prototype
    );
  },
})