<template>
  <v-dialog v-model="dialog" persistent :max-width="options.width">
    <v-card
      :class="{
        'warning-card': type == 'warning',
        'error-card': type == 'error',
        'success-card': type == 'success',
      }"
    >
      <v-card-title
        class="headline"
        style="font-size: 21px !important; display: flex; flex-wrap: nowrap"
      >
        <v-icon
          :dense="true"
          title="Search"
          style="font-size: 25px; margin-right: 10px"
          :class="{
            'warning-icon': type == 'warning',
            'error-icon': type == 'error',
            'success-icon': type == 'success',
          }"
        >
          {{ `mdi-${icon}` }}
        </v-icon>
        {{ message }}
      </v-card-title>
      <v-card-actions class="d-flex">
        <v-spacer></v-spacer>
        <v-btn color="primary" text @click.native="agree"> ok </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
export default {
  data: () => ({
    dialog: false,
    resolve: null,
    reject: null,
    message: null,
    type: "error",
    options: {
      color: "primary",
      width: 500,
      zIndex: 200,
    },
  }),
  computed: {
    icon() {
      if (this.type == "error") return "alert-outline";
      else if (this.type == "success") return "check-circle-outline";
      else return "alert-octagon-outline";
    },
  },
  methods: {
    open(message, type = "error", options) {
      this.dialog = true;
      this.message = message;
      this.type = type;
      this.options = Object.assign(this.options, options);
      return new Promise((resolve, reject) => {
        this.resolve = resolve;
        this.reject = reject;
      });
    },
    agree() {
      this.resolve(true);
      this.dialog = false;
    },
    cancel() {
      this.resolve(false);
      this.dialog = false;
    },
  },
};
</script>

<style scoped>
.warning-card {
  border-top: 6px solid #fb8c00;
}
.warning-icon {
  color: #fb8c00;
}
.error-card {
  border-top: 6px solid #ff5252;
}
.error-icon {
  color: #ff5252;
}
.success-card {
  border-top: 6px solid #4caf50;
}
.success-icon {
  color: #4caf50;
}
</style>