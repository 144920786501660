var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-dialog',{attrs:{"persistent":"","max-width":_vm.options.width},model:{value:(_vm.dialog),callback:function ($$v) {_vm.dialog=$$v},expression:"dialog"}},[_c('v-card',{class:{
      'warning-card': _vm.type == 'warning',
      'error-card': _vm.type == 'error',
      'success-card': _vm.type == 'success',
    }},[_c('v-card-title',{staticClass:"headline",staticStyle:{"font-size":"21px !important","display":"flex","flex-wrap":"nowrap"}},[_c('v-icon',{class:{
          'warning-icon': _vm.type == 'warning',
          'error-icon': _vm.type == 'error',
          'success-icon': _vm.type == 'success',
        },staticStyle:{"font-size":"25px","margin-right":"10px"},attrs:{"dense":true,"title":"Search"}},[_vm._v(" "+_vm._s(("mdi-" + _vm.icon))+" ")]),_vm._v(" "+_vm._s(_vm.message)+" ")],1),_c('v-card-actions',{staticClass:"d-flex"},[_c('v-spacer'),_c('v-btn',{attrs:{"color":"primary","text":""},nativeOn:{"click":function($event){return _vm.agree.apply(null, arguments)}}},[_vm._v(" ok ")])],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }