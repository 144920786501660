import { initializeApp } from "firebase/app";
import { getMessaging } from "firebase/messaging/sw";

const firebaseConfig = {
  apiKey: "AIzaSyATl0wdhpguvooplpwnkzdSpzQ2U0zQM94",
  authDomain: "escon-8adfd.firebaseapp.com",
  projectId: "escon-8adfd",
  storageBucket: "escon-8adfd.appspot.com",
  messagingSenderId: "978788403740",
  appId: "1:978788403740:web:6ebcd472c9b412b697edd9",
  measurementId: "G-LD0L98J1S8",
};

export const app = initializeApp(firebaseConfig);
export const messaging = getMessaging(app);
