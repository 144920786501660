const dashboardModule ={
  namespaced: true,
  state: () => ({
      branch_dataset: [],
      selected_params: {},
  } ), 

  getters: {
    getBranch_id: state => state.branch_dataset,
    getSelectedParams: state => state.selected_params,
  },
  actions: {
    setBranch({commit},branch_dataset ){
        commit('setBranch', branch_dataset);

    },
    setSelected({commit},selected_params ){
        commit('setSelected', selected_params);
    }
  },
  mutations: {
    setBranch(state, branch_dataset){
        state.branch_dataset = branch_dataset;
    }, 
    setSelected(state, selected_params){
      console.log("---on commit--",selected_params)
      state.selected_params = selected_params;
    }
  }
};
export default dashboardModule;