import moment from 'moment';
export default {
  numberWithCommas: (value) => {
    return value.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
  },
  capitalize: (value) => {
    if (!value) return '';
    value = value.toString();
    return value.charAt(0).toUpperCase() + value.slice(1);
  },
  formatTimeStamp: (dt) => {
    let date = moment(dt, 'YYYY-MM-DDTHH:mm:ss');
    return date.isValid() ? moment(date, 'YYYY-MM-DDTHH:mm:ss').format('DD-MM-YYYY hh:mm A') : "";
  },
  formatCardView: (dt) => {
    let date = moment(dt, 'YYYY-MM-DDTHH:mm:ss');
    return date.isValid() ? moment(date, 'YYYY-MM-DDTHH:mm:ss').format('DD MMM YYYY') : "";
  },
  timestamp_to_date: (dt) => {
    let date = moment(dt, 'YYYY-MM-DDTHH:mm:ss');
    return date.isValid() ? moment(date, 'YYYY-MM-DDTHH:mm:ss').format('DD-MM-YYYY') : "";
  },
  dashIfNull: (v) => v ?? '-',
};